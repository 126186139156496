.parlay-workstation-wrapper {
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.parlay-workstation-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 32px;
}

.parlay-workstation-header-segment-control-desktop {
    height: 100%;
    display: initial;
}

.parlay-workstation-header-segment-control-mobile {
    display: none;
}

.parlay-workstation-content {
    margin-top: 16px;
}

.parlay-workstation-content-parlay-column {
    width: 24px;
    height: 24px;
}

.parlay-workstation-content-parlay-column[data-hit='true'] {
	filter: var(--color-fig-positive-filter);
}

.parlay-workstation-content-parlay-column[data-hit='false'] {
	filter: var(--color-fig-negative-filter);
}

@media only screen and (max-width: 1024px) {

    .parlay-workstation-wrapper {
        padding: 32px 0px;
    }

    .parlay-workstation-header {
        padding: 0px 16px;
    }

}

@media only screen and (max-width: 768px) {

    .parlay-workstation-header {
        flex-wrap: wrap;
        margin-bottom: 48px;
    }

    .parlay-workstation-header > p {
        width: 100%;
    }

    .parlay-workstation-header-segment-control-desktop {
        display: none;
    }

    .parlay-workstation-header-segment-control-mobile {
        width: 100%;
        height: 32px;
        margin: 16px 0px;
        display: initial;
    }

}