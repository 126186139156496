.dialog-overlay {
    // This was initially fixed but changed to absolute to set the z-index to cover the navbar too
    position: absolute;
    // Higher than navbar
    z-index: 10001;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.dialog-container {
    width: 480px;
    // Full screen height minus 16px padding on top and bottom
    max-height: calc(100% - 32px);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    background-color: var(--color-surface-default);
    border: 1px solid var(--color-border-default);
    border-radius: 12px;
}

.dialog-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 16px 24px 16px 32px;
    border-bottom: 1px solid var(--color-border-default);
}

.dialog-container-header > p {
    color: var(--color-fig-default);
}

.dialog-container-header > img {
    cursor: pointer;
    filter: var(--color-fig-subtle-filter);
}

.dialog-container-content {
    padding: 24px 32px;
    overflow: auto;
}

.dialog-container-footer {
    padding: 16px 32px;
    border-top: 1px solid var(--color-border-default);
}

@media only screen and (max-width: 1439px) {

    .dialog-container {
        width: calc(100% - 32px);
        max-height: calc(100% - 16px);
    }

    .dialog-container-content {
        padding: 16px;
        overflow: auto;
    }

    .trends-page-list-filters-dialog-content-checkbox-list-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

}