.trends-page-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: var(--color-surface-default);
}

.trends-page-list {
    width: 432px;
    border-right: 1px solid var(--color-border-default);
}

.trends-page-list-filters {
    height: 64px;
    padding: 0 32px;
    border-top: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
}

.trends-page-list-title {
    height: 64px;
    padding: 0 32px;
    border-bottom: none;
}

.trends-page-list-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

// Workaround instead of having to re-structure the divs for the annotation
.trends-page-list-title[data-annotated="true"] > p:last-of-type {
    flex-grow: 1;
    text-align: end;
}

.trends-page-list-title > img {
    height: 24px;
    width: 24px;
    filter: var(--color-fig-subtle-filter);
    margin: 8px;
    cursor: pointer;
}

.trends-page-list-title > p:first-of-type {
    color: var(--color-fig-default);
}

.trends-page-list-title > p:nth-of-type(2) {
    color: var(--color-fig-subtle);
    margin-left: 8px;
}

.trends-page-list-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.trends-page-list-filters-button-wrapper {
    height: 32px;
    width: 89px;
}

.trends-page-list-filters-dialog-content > p {
    color: var(--color-fig-default);
    margin-top: 24px;
}

.trends-page-list-filters-dialog-content > p:first-of-type {
    margin-top: 0;
}

.trends-page-list-filters-dialog-content-checkbox-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    row-gap: 12px;
    margin-top: 8px;
}

.trends-page-list-filters-dialog-content-checkbox-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    cursor: pointer;
    border-radius: 0;
    padding-left: 0;

    // Needed for when it's a checkbox. Styling is a bit different
    background-color: inherit;
    border: none;
}

.trends-page-list-filters-dialog-content-checkbox-list-item:hover {
    background-color: var(--color-surface-hover);
    border-radius: 4px;
}

.trends-page-list-filters-dialog-content-checkbox-list-item:focus {
    outline: none;
}

.trends-page-list-filters-dialog-content-checkbox-list-item-text,
.trends-page-list-filters-dialog-content-checkbox-list-item-text-selected {
    color: var(--color-fig-default);
    margin-left: 8px;
}

.trends-page-list-filters-dialog-content-checkbox-list-item-selection-check {
    height: 16px;
    width: 16px;
    filter: var(--color-fig-accent-filter);
}

.trends-page-list-filters-dialog-content-checkbox-list-item-checkbox-indicator {
    display: flex;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid var(--color-default-button-border-default);
}

.trends-page-list-filters-dialog-content-checkbox-list-item-checkbox-indicator > img {
    height: 16px;
    width: 16px;
    filter: var(--color-fig-accent-filter);
}

.trends-page-list-filters-dialog-content-chip-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 8px;
}

.trends-page-list-filters-dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.trends-page-list-filters-dialog-footer > div {
    height: 32px;
}

.trends-page-list-filters-dialog-footer > div:first-of-type {
    width: 119px;
}

.trends-page-list-filters-dialog-footer > div:nth-of-type(2) {
    width: 63px;
}

.trends-page-list-parlay-showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    // Originally 120px but reduced so that when you have no content on the right and the view height is small it doesn't get pushed down too far
    // Ideally we would center when it's smaller and use the padding when bigger but that adds complexity and I can't be bothered
    padding-top: 100px;
    height: 100%;
    background-color: var(--color-surface-subtle);
}

.trends-page-list-parlay-showcase > img {
    max-width: 200px;
    aspect-ratio: 1;
}

.trends-page-list-parlay-showcase-text {
    max-width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.trends-page-list-parlay-showcase-button {
    height: 40px;
    width: 143px;
}

.trends-page-list-items {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 64px - 64px);
}

.trends-page-list-items-item,
.trends-page-list-items-item-selected {
    padding: 20px 32px;
    border-top: 1px solid var(--color-border-default);
    cursor: pointer;
}

.trends-page-list-items-item-selected {
    background-color: var(--color-surface-pressed);
}

.trends-page-list-items-item:first-of-type,
.trends-page-list-items-item-selected:first-of-type {
    border-top: none;
}

.trends-page-list-items-item:hover {
    background-color: var(--color-surface-hover);
}

.trends-page-list-items-item:active {
    background-color: var(--color-surface-pressed);
}

div.trends-page-list-items-item[data-freePreview="true"] {
    background-color: var(--color-surface-subtle);
}

.trends-page-list-items-item-free-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.trends-page-list-items-item-free-preview-left {
    padding: 4px 6px;
    background-color: var(--color-badge-bg);
    border-radius: 4px;
    border: 1px solid var(--color-badge-border)
}

.trends-page-list-items-item-free-preview-left > p {
    color: var(--color-badge-fig);
}

.trends-page-list-items-item-free-preview-right {
    display: flex;
    flex-direction: row;
    padding: 4px 6px;
    background-color: var(--color-badge-bg);
    border-radius: 4px;
    border: 1px solid var(--color-badge-border)
}

.trends-page-list-items-item-free-preview-right > div {
    display: flex;
    background-color: var(--color-badge-subtle);
    margin-right: 4px;
    padding: 2px;
    border-radius: 2px;
}

.trends-page-list-items-item-free-preview-right > div > img {
    height: 12px;
    width: 12px;
    filter: var(--color-badge-fig-filter);
}

.trends-page-list-items-item-free-preview-right > p {
    color: var(--color-badge-fig);
}

.trends-page-details {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: column;
    min-width: 592px;
    max-width: 1200px;
    border-right: 1px solid var(--color-border-default);
}

.trends-page-details-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 40px;
    overflow: hidden;
    border-bottom: 1px solid var(--color-border-default);
}

.trends-page-details-title-mobile {
    display: none;
}

.trends-page-details-title-desktop {
    display: flex;
}

.trends-page-details-title > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.trends-page-details-title > div > p:first-of-type {
    color: var(--color-fig-default);
}

.trends-page-details-title > div > p:nth-child(2) {
    color: var(--color-fig-subtle);
}

.trends-page-details-title > img {
    height: 240px;
    width: 240px;
}

.trends-page-list-filters-picker-wrapper {
    flex-grow: 1;
    margin-right: 24px;
    height: 32px;
}

.trends-page-details-trending-plays {
    background-color: var(--color-surface-subtle);
    padding: 32px;
    border-top: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
}

.trends-page-details-trending-plays-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.trends-page-details-trending-plays-title-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.trends-page-details-trending-plays-title-left > p {
    color: var(--color-fig-default);
}

.trends-page-details-trending-plays-title-left-linemate-plus {
    display: flex;
    flex-direction: row;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--color-badge-border);
}

.trends-page-details-trending-plays-title-left-linemate-plus > p {
    color: var(--color-badge-fig);
}

.trends-page-details-trending-plays-title-left-linemate-plus > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    // TODO: background color to be added in styles
}

.trends-page-details-trending-plays-title-left-linemate-plus > div > img {
    height: 12px;
    width: 12px;
    filter: var(--color-badge-fig-filter);
}

.trends-page-details-trending-plays-title-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.trends-page-details-trending-plays-title-right > p {
    color: var(--color-fig-subtle);
}

.trends-page-details-trending-plays-title-right-button {
    height: 32px;
    width: 32px;
}

.trends-page-details-trending-plays-title-right-button:first-of-type {
    margin-left: 16px;
    margin-right: 12px;
}

.trends-page-details-trending-plays-cards {
    display: flex;
    gap: 16px;
}

.trends-page-details-trending-plays-cards-mobile {
    display: none;
}

.trends-page-details-trending-plays-cards-desktop {
    display: flex;
}

.trends-page-details-trending-plays-cards-item {
    position: relative;
    margin-top: 16px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    // To match the card heights from the same row in case there are variations in the number of insights
    flex-grow: 1;
    width: calc(50% - 8px);
}

.trends-page-details-filters {
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border-default);
    border-top: 1px solid var(--color-border-default);
    background-color: var(--color-surface-default);
}

.trends-page-details-filters-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px 32px;
}

.trends-page-details-filters-wrapper-filter {
    width: calc(100% / var(--filters-count));
}

.trends-page-details-filters-wrapper-filter-input-selection {
    height: 32px;
}

.trends-page-details-filters-wrapper-filter-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.trends-page-details-filters-wrapper-filter-header > p {
    color: var(--color-fig-default);
    margin-bottom: 4px;
}

.trends-page-details-filters-wrapper-filter-header > img {
    filter: var(--color-fig-subtle-filter);
}

.trends-page-details-hit-rate {
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.trends-page-details-hit-rate > p {
    color: var(--color-fig-default);
}

.trends-page-details-hit-rate-wrapper {
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    background-color: var(--color-surface-subtle);
    margin-top: 16px;
    width: 100%;
}

.trends-page-details-hit-rate-wrapper > div:first-of-type {
    width: 100%;
    height: 326px;
}

.hit-rate-chart-info-details {
    height: 106px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    margin: 24px;
    padding: 16px;
    background-color: var(--color-surface-default);
}

.hit-rate-chart-info-details > div {
    height: 100%;
}

.hit-rate-chart-info-details-line,
.hit-rate-chart-info-details-over-under {
    width: calc((100% - 24px - 24px) / 3);
}

.hit-rate-chart-info-details-cover {
    width: calc(2 * (100% - 24px - 24px) / 3);
}

.hit-rate-chart-info-details-line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hit-rate-chart-info-details-line > p {
    color: var(--color-fig-default);
    text-align: center;
}

.hit-rate-chart-info-details-line > div {
    height: 32px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-border-default);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hit-rate-chart-info-details-line-decrease,
.hit-rate-chart-info-details-line-increase {
    height: 100%;
    width: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hit-rate-chart-info-details-line-decrease > img,
.hit-rate-chart-info-details-line-increase > img {
    height: 16px;
    width: 16px;
    filter: var(--color-fig-subtle-filter);
}

.hit-rate-chart-info-details-line-decrease {
    border-right: 1px solid var(--color-border-default);
}

.hit-rate-chart-info-details-line-increase {
    border-left: 1px solid var(--color-border-default);
}

.hit-rate-chart-info-details-line-value {
    flex-grow: 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.hit-rate-chart-info-details-line-value > input {
    text-align: center;
    background-color: var(--color-surface-default);
    color: var(--color-fig-default);
}

.hit-rate-chart-info-details-line-value > input:focus {
    outline: none;
}

.hit-rate-chart-info-details-line-value > input::-webkit-outer-spin-button, 
.hit-rate-chart-info-details-line-value > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hit-rate-chart-info-details-over-under,
.hit-rate-chart-info-details-cover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hit-rate-chart-info-details-over-under > p,
.hit-rate-chart-info-details-cover > p {
    color: var(--color-fig-default);
    text-align: center;
}

.hit-rate-chart-info-details-over-under > div,
.hit-rate-chart-info-details-cover > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.hit-rate-chart-info-details-over-under > div > p,
.hit-rate-chart-info-details-cover > div > p {
    color: var(--color-fig-default);
}

.hit-rate-chart-info-details-fraction {
    border: 1px solid var(--color-border-default);
}

.hit-rate-chart-info-details-fraction,
.hit-rate-chart-info-details-fraction {
    background-color: var(--color-surface-subtle);
    border-radius: 6px;
    padding: 4px 8px;
}

.hit-rate-chart-info-details-fraction > p,
.hit-rate-chart-info-details-fraction > p {
    color: var(--color-fig-subtle);
}


.trends-page-game-log {
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.trends-page-game-log > p {
    color: var(--color-fig-default);
}

.trends-page-game-log-table {
    margin: 16px 0px;
}

.trends-page-game-log-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.trends-page-game-log-pagination-button {
    height: 32px;
    width: 32px;
}

.trends-page-game-log-pagination-button:first-of-type {
    margin-left: 16px;
    margin-right: 12px;
}

.trends-page-details-no-plays {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.trends-page-details-no-plays > div {
    margin-bottom: 24px;
    height: 88px;
    width: 88px;
}

.trends-page-details-no-plays > div > img {
    position: absolute;
}

.theme-dark .trends-page-details-no-plays > div > img:first-of-type {
    // Loss 0.0
    filter: invert(22%) sepia(57%) saturate(1273%) hue-rotate(201deg) brightness(90%) contrast(89%);
}

.theme-light .trends-page-details-no-plays > div > img:first-of-type {
    // Loss 0.0
    filter: invert(78%) sepia(7%) saturate(1244%) hue-rotate(190deg) brightness(103%) contrast(103%);
}

.trends-page-details-no-plays > div > img:nth-of-type(2) {
    filter: var(--color-fig-disabled-filter);
}

.trends-page-details-no-plays > p {
    margin-bottom: 8px;
}

// The 432 on the left plus 432 (symmetrical on the right) plus the 1200 middle
@media only screen and (min-width: 2064px) {

    .trends-page-details {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-left: 1px solid var(--color-border-default);
    }

}

@media only screen and (max-width: 1439px) {

    .trends-page-details-trending-plays {
        padding: 16px 0px;
    }

    .trends-page-details-trending-plays > div {
        padding: 0px 32px;
    }

    .trends-page-details-trending-plays-title-left > .text-style-h-3-medium,
    .trends-page-details-trending-plays-title-right > .text-style-body-medium {
        //change to h-4-medium
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.25999999046325684px;
    }

    .trends-page-details-trending-plays-title-right-button {
        display: none;
    }

    .trends-page-details-trending-plays-cards-mobile {
        display: flex;
    }

    .trends-page-details-trending-plays-cards-desktop {
        display: none;
    }

    .trends-page-details-trending-plays-cards {
        overflow-x: scroll;
    }

    .trends-page-details-trending-plays-cards-item {
        width: 100%;
        flex-shrink: 0;
    }

    .trends-page-details-filters {
        height: unset;
    }

    .trends-page-details-filters-wrapper {
        flex-direction: column;
    }

    .trends-page-details-filters-wrapper-filter {
        width: 100%;
    }

    .hit-rate-chart-info-details {
        height: 210px;
        flex-wrap: wrap;
    }

    .hit-rate-chart-info-details-line {
        width: 100%;
        height: unset !important;
    }

    .hit-rate-chart-info-details-over-under {
        width: calc(50% - 12px);
        height: unset !important;
    }

    .hit-rate-chart-info-details-cover {
        width: 100%;
        height: unset !important;
    }

}

@media only screen and (max-width: 1023px) {

    .trends-page-details-trending-plays {
        padding: 16px 0px;
    }

    .trends-page-details-trending-plays > div {
        padding: 0px 16px;
    }

    .trends-page-details-title-mobile {
        display: flex;
        padding: 8px 16px;
        justify-content: flex-start;
    }
    
    .trends-page-details-title-desktop {
        display: none;
    }

    .trends-page-details-title > img:first-of-type {
        height: 24px;
        width: 24px;
        filter: var(--color-fig-subtle-filter);
        margin: 8px;
        cursor: pointer;
    }

    .trends-page-details-title > img:nth-of-type(2) {
        height: 32px;
        width: 32px;
        margin: 4px;
    }

    .trends-page-container {
        display: flex;
    }

    .trends-page-list {
        display: none;
        width: 100%;
        height: 100%;
    }

    .trends-page-list-mobile-active {
        display: initial;
    }

    .trends-page-details {
        display: none;
    }

    .trends-page-details-mobile-active {
        display: flex;
        width: 100%;
        min-width: auto;
    }

    .trends-page-details-filters-wrapper {
        flex-direction: column;
        padding: 16px;
    }

    .trends-page-list-items-item,
    .trends-page-list-items-item-selected {
        padding: 16px;
    }

    .trends-page-list-items-item-selected[data-initialSelection="true"] {
        background-color: initial;
    }

    .trends-page-details-hit-rate {
        padding: 24px 16px;
    }

    .trends-page-game-log {
        padding: 24px 0px;
    }

    .trends-page-game-log > p {
        margin-left: 16px;
    }

    .trends-page-game-log-table-mobile {
        margin-left: 16px;
        margin-right: 16px;
    }

    .trends-page-game-log-pagination {
        margin-right: 16px;
    }
    
}