.pricing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing-page-intro {
    padding: 112px 0px 64px 0px;
    background-color: var(--color-surface-default);
}

.pricing-page-intro-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1080px;
    margin-bottom: 64px;
}

.pricing-page-intro-header > p {
    text-align: center;
    max-width: 654px;
}

.pricing-page-intro-header > p:nth-of-type(2) {
    font-family: 'Inter';
    font-size: 48px;
    font-weight: 700;
    line-height: 58.09px;
    letter-spacing: -1.0700000524520874px;
    text-align: center;

    margin: 12px 0 24px 0;
}

.pricing-page-intro-plans {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.pricing-page-intro-plans-plan {
    display: flex;
    flex-direction: column;
    width: calc(100% / var(--plan-options-count));
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    background-color: var(--color-surface-default);
}

.pricing-page-intro-plans-plan[data-highlighted="true"] {
    border: 1px solid var(--color-input-border-focus);
    background-color: var(--color-surface-sorted);
}

.pricing-page-intro-plans-plan-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pricing-page-intro-plans-plan-title > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.pricing-page-intro-plans-plan-pricing {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 12px 0px 0px 0px;
}

.pricing-page-intro-plans-plan-pricing[data-discounted=false] {
    margin-bottom: 32px;
}

.pricing-page-intro-plans-plan-pricing-discounted {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.pricing-page-intro-plans-plan-pricing-discounted > div {
    display: flex;
    flex-direction: row;
}

.pricing-page-intro-plans-plan-pricing-discounted > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.18000000715255737px;
    text-align: left;
}

.pricing-page-intro-plans-plan-pricing-discounted-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
    background-color: var(--color-chip-fig);
    border-radius: 4px;
    margin-left: 8px;
}

.pricing-page-intro-plans-plan-pricing-discounted-badge > p {
    color: var(--color-chip-bg);
}

.pricing-page-intro-plans-plan-divider {
    height: 1px;
    width: 100%;
    background-color: var(--color-border-default);
    margin: 24px 0px;
}

.pricing-page-intro-plans-plan-features {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    margin-bottom: 48px;
}

.pricing-page-intro-plans-plan-features-feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.pricing-page-intro-plans-plan-features-feature > img {
    height: 24px;
    width: 24px;
    filter: var(--color-fig-accent-filter);
}

.pricing-page-intro-plans-plan-button-wrapper {
    width: 100%;
    height: 48px;
}

.pricing-page-intro > p {
    text-align: center;
    margin-top: 16px;
}

.pricing-page-upgrade-showcase {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0;
    background-color: var(--color-surface-subtle);
    border-top: 1px solid var(--color-border-default);
}

.pricing-page-upgrade-showcase > p {
    text-align: center;
    max-width: 696px;
}

.pricing-page-upgrade-showcase > p:first-of-type {
    margin-bottom: 16px;
}

.pricing-page-upgrade-showcase-features {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 64px;
}

.pricing-page-upgrade-showcase-feature {
    display: flex;
    flex-direction: column;
    height: 308px;
    width: 322px;
    background-color: var(--color-surface-default);
    border: 1px solid var(--color-border-default);
    border-radius: 16px;
}

.pricing-page-upgrade-showcase-feature-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pricing-page-upgrade-showcase-feature-image > img {
    width: 200px;
    height: 200px;
}

.pricing-page-upgrade-showcase-feature-text {
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    gap: 4px;
    justify-content: center;
    height: 100%;
}

@media only screen and (max-width: 1200px) {

    .pricing-page-intro-plans {
        flex-wrap: wrap;
        padding-left: 16px;
        padding-right: 16px;
    }

    .pricing-page-intro-plans-plan {
        width: calc(50% - 12px);
    }

    .pricing-page-upgrade-showcase {
        padding: 64px 16px;
    }

    .pricing-page-upgrade-showcase-features {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .pricing-page-upgrade-showcase-feature {
        width: calc(50% - 12px);
    }

}

@media only screen and (max-width: 1080px) {

    .pricing-page-intro-header {
        width: 100%;
        padding: 0px 16px;
    }

}

@media only screen and (max-width: 768px) {

    .pricing-page-intro-plans-plan {
        width: 100%;
    }

    .pricing-page-upgrade-showcase {
        padding: 64px 16px;
    }

    .pricing-page-upgrade-showcase-feature {
        width: 100%;
    }
}