.no-results-container {
    position: relative;
    display: block;
    width: 100%;
    height: 216px;

    border: 1px solid #D1D5DB;
    box-sizing: border-box;
    border-radius: 8px;
}

.no-results-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.no-results-text {
    display: block;
    width: 100%;
    max-width: 360px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}