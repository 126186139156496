.chip-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-surface-default);
    border-radius: 4px;
    border: 1px solid var(--color-border-default);
    cursor: pointer;
}

.chip-wrapper > p,
.chip-wrapper:hover > p {
    color: var(--color-fig-default);
}

.chip-wrapper:hover {
    background-color: var(--color-surface-hover);
}

.chip-wrapper-sm {
    padding: 4px 8px;
}

.chip-wrapper-lg {
    padding: 6px 12px;
}

.chip-wrapper-selected {
    background-color: var(--color-chip-bg);
    border: 1px solid var(--color-chip-fig);
}

.chip-wrapper-selected > p {
    color: var(--color-chip-fig);
}