.input-selection-trigger,
.input-selection-trigger-disabled,
.input-selection-trigger-default {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 12px;
    background-color: var(--color-input-bg-default);
    border: 1px solid var(--color-input-border-default);
    border-radius: 4px;
}

.input-selection-trigger[data-state='closed'], 
.input-selection-trigger-disabled[data-state='closed'],
.input-selection-trigger-default[data-state='closed'] {
    outline: none;
}

.input-selection-trigger[data-state='open'],
.input-selection-trigger-default[data-state='open'] {
    border: 2px solid var(--color-input-border-focus);
    // Reduced padding by 1 to accomodate the bigger border
    padding: 0 11px;
}

.input-selection-trigger:hover,
.input-selection-trigger-default:hover {
    background-color: var(--color-input-bg-hover);
} 

.input-selection-trigger:hover > span,
.input-selection-trigger-default:hover > span {
    color: var(--color-fig-subtle);
} 

.input-selection-trigger > span:first-of-type {
    color: var(--color-fig-default);
    flex-grow: 1;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.input-selection-trigger-icon {
    filter: var(--color-fig-subtle-filter);
}

.input-selection-trigger-default > span {
    color: var(--color-fig-subtle);
}

.input-selection-trigger-disabled {
    background-color: var(--color-input-bg-default);
    border: 1px solid var(--color-input-border-disabled);
}

.input-selection-trigger-disabled > span {
    color: var(--color-fig-disabled);
}

.input-selection-trigger-disabled .input-selection-trigger-icon {
    filter: var(--color-fig-disabled-filter);
}

// Width of dropdown should be min-width of trigger width, and then with padding and all can grow based on length of text
.input-selection-dropdown {
    overflow: hidden;
    background-color: var(--color-surface-default);
    border: 1px solid var(--color-input-border-default);
    border-radius: 4px;
    box-shadow: var(--shadow-large);
    z-index: 10003;
    min-width: var(--radix-select-trigger-width);
    padding: 5px;

    // https://stackoverflow.com/questions/74906906/radix-ui-select-primitive-overflows-screen
    max-height: var(--radix-select-content-available-height);
}

.input-selection-dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 32px;
    cursor: pointer;
    border-radius: 0;
    padding: 6px 8px;

    // Needed for when it's a checkbox. Styling is a bit different
    background-color: inherit;
    border: none;
}

.input-selection-dropdown-item:hover {
    background-color: var(--color-surface-hover);
    border-radius: 4px;
}

.input-selection-dropdown-item:focus {
    outline: none;
}

.input-selection-dropdown-item-text,
.input-selection-dropdown-item-text-selected {
    color: var(--color-fig-default);
    margin-left: 24px;
}

.input-selection-dropdown-item-selection-check {
    height: 16px;
    width: 16px;
    filter: var(--color-fig-accent-filter);
}

.input-selection-dropdown-item-text-selected {
    margin-left: 8px;
}

.input-selection-dropdown-item-checkbox-indicator {
    display: flex;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid var(--color-default-button-border-default);
}

.input-selection-dropdown-item-checkbox-indicator > img {
    height: 16px;
    width: 16px;
    filter: var(--color-fig-accent-filter);
}

.input-selection-dropdown-button-group {
    display: flex;
    flex-direction: column;
    padding: 9px 8px;
    gap: 12px;
    border-top: 1px solid var(--color-border-default);
}

.input-selection-dropdown-button {
    height: 28px;
}