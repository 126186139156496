.segmented-control-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    background-color: var(--color-segmented-control-default);
    padding: 2px;
    border-radius: 6px;
}

// TODO: hover/click states
.segmented-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.segmented-control-selection-separated {
    border-left: 1px solid var(--color-border-default);
}

.segmented-control-selection {
    background-color: var(--color-segmented-control-pressed);
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
}