.btn-action-primary {
    height: 36px;
    background-color: #047857;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #FFFFFF;
    border: 1px solid #047857;
    padding: 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
}

.btn-action-primary:hover {
    background-color: #059669;
    border: 1px solid #059669;
}

.btn-action-primary:focus {
    background-color: #047857;
    border: 1px solid #047857;
}

.btn-action-critical {
    height: 36px;
    background-color: #DC2626;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #FFFFFF;
    border: 1px solid #DC2626;
    padding: 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
}

.btn-action-marketing {
    background-color: transparent;
    color: #047857;
    border: 2px solid #047857;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px 24px;
    cursor: pointer;
}

.btn-action-marketing:hover {
    background-color: #047857;
    border: 2px solid #047857;
    color: #FFFFFF;
}

.btn-action-marketing:focus {
    background-color: transparent;
    border: 2px solid #047857;
    color: #047857;
}

.btn-action-critical:hover {
    background-color: #B91C1C;
    border: 1px solid #B91C1C;
}

.btn-action-critical:focus {
    background-color: #991B1B;
    border: 1px solid #991B1B;
}

.btn-action-primary:disabled {
    background-color: #E5E7EB;
    border: 1px solid #E5E7EB;
    color: #9CA3AF;
}

.btn-action-secondary {
    height: 36px;
    border: 1px solid #D1D5DB;
    background-color: #FFFFFF;
    color: #111827;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 8px 24px;
}

.btn-action-secondary:hover {
    background-color: #F9FAFB;
    color: #111827;
}

.btn-action-secondary:focus {
    background-color: #E5E7EB;
    color:#111827;
}

.btn-action-secondary:disabled {
    color: #111827;
}

.btn-action-plain {
    border: none;
    background: transparent;
    color: #047857;
    text-decoration: none;
    padding: 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
}

.btn-action-plain:hover {
    color: #065F46;
    text-decoration: underline #065F46;
}

.btn-action-plain:focus {
    color: #064E3B;
    text-decoration: underline #064E3B;
}

@media (min-width: 360px) and (max-width:767px) {
    .btn-action-primary {
        height: 44px;
    }

    .btn-action-secondary{
        height: 44px;
    }

    .btn-action-critical {
        height: 44px;
    }
}