.radio-group-root {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.radio-group-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.radio-group-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    // There's something odd about the width, it won't let it go below 14 so even if it says 12 above it's actually 14 and need to use padding to push it in to be smaller
    padding: 0px 1px;
    outline: none;

    background-color: var(--color-default-button-bg-default);
    border-radius: 100%;
    border: 1px solid var(--color-border-default);
}

.radio-group-indicator:focus {
    outline: none;
    border: 1px solid var(--color-border-default);
}

.radio-group-indicator[data-state="checked"] {
    background-color: var(--color-primary-button-bg);
}

.radio-group-indicator[data-state="checked"] > div {
    height: 4px;
    width: 4px;
    border-radius: 100%;
    background-color: var(--color-fig-on-bg);
}

.radio-group-item > p:first-of-type {
    color: var(--color-fig-default);
    margin-left: 12px;
}

.radio-group-item > p:nth-of-type(2) {
    color: var(--color-fig-subtle);
}