.navigation-bar, .navigation-bar-expansion {
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
    padding: 0 32px;
    position: fixed;
    z-index: 10000;
    top: 0;
}

.navigation-bar-extra-z-index {
    z-index: 10002 !important;
}

.navigation-bar-placeholder {
    height: var(--navbar-height);
    width: 100%;
}

.navigation-bar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    // Needed to allow for the middle section to be centered relative to the screen
    flex-grow: 1;
    flex-basis: 0;
}

.navigation-bar-left > a {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigation-bar-wordmark {
    filter: var(--color-fig-default-filter);
    margin-right: 24px;
}

.navigation-bar-left-dropdown-trigger {
    height: 28px;
    width: 120px;
}

.navigation-bar-left-search {
    height: 28px;
    width: 28px;
    margin-left: 8px;
}

.navigation-bar-middle {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navigation-bar-tab,
.navigation-bar-tab-selected {
    padding: 8px 2px;
}

.navigation-bar-tab > a,
.navigation-bar-tab-selected > a {
    padding: 4px 12px;
}

.navigation-bar-tab > a {
    color: var(--color-fig-subtle)
}

.navigation-bar-tab-selected > a {
    color: var(--color-fig-default)
}

.navigation-bar-tab > a:hover,
.navigation-bar-tab-selected > a:hover {
    background-color: var(--color-surface-hover);
    border-radius: 4px;
}

.navigation-bar-right {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // Needed to allow for the middle section to be centered relative to the screen
    flex-grow: 1;
    flex-basis: 0;
}

.navigation-bar-right-lm-plus-button-wrapper {
    height: 28px;
    width: 101px;
}

.navigation-bar-right-login-button-wrapper {
    height: 28px;
    width: 51px;
}

.navigation-bar-right-signup-button-wrapper {
    height: 28px;
    width: 96px;
    margin-left: 16px;
}

.navigation-bar-right-theme-button-wrapper,
.navigation-bar-right-moneybag-button-wrapper {
    height: 28px;
    width: 28px;
    margin-left: 16px;
}

.navigation-bar-right-avatar-wrapper {
    height: 28px;
    width: 28px;
    margin-left: 16px;
}

.navigation-bar-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    background-color: var(--color-banner-surface);
    position: fixed;
    top: 48px;
    width: 100%;
    z-index: 10000;
}

.navigation-bar-banner > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    filter: var(--color-banner-fig-filter);
}

.navigation-bar-banner > p {
    color: var(--color-banner-fig);
}

div.navigation-bar-affiliates-drawer {
    width: 360px;
    height: calc(100vh - var(--navbar-height));

    position: fixed;
    top: var(--navbar-height);
    right: 0;
    left: unset;
    transform: none;

    display: flex;
    flex-direction: column;
    background-color: var(--color-surface-default);
    border: none;
    border-left: 1px solid var(--color-border-default);
    border-radius: 0;
}

div.navigation-bar-affiliates-drawer > div.dialog-container-content {
    height: 100%;
    padding: 0;
}

.navigation-bar-search {
    position: absolute;
    top: var(--navbar-height);
    height: calc(100vh - var(--navbar-height));
    width: 100%;
    z-index: 10001;
    background-color: var(--color-surface-default);
}

@media only screen and (min-width: 769px) {
    .navigation-bar-expansion {
        display: none;
    }

    .navigation-bar-right-mobile-menu-button-wrapper {
        display: none;
    }
}

// Mobile threshold for the nav
@media only screen and (max-width: 768px) {
    .navigation-bar {
        padding: 0 8px;
    }

    .navigation-bar-left > a > img {
        height: 18px;
    }

    .navigation-bar-expansion {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        height: 100%;
        top: var(--navbar-height);
        padding: 0 16px;
        z-index: 10002 !important;
    }

    .navigation-bar:has(+.navigation-bar-expansion) {
        border: none;
    }

    .navigation-bar-left {
        margin-left: 8px;
    }

    .navigation-bar-right {
        gap: 8px;
    }

    .navigation-bar-right-login-button-wrapper {
        display: none;
    }

    .navigation-bar-expansion-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 64px;
        gap: 16px;
    }

    .navigation-bar .navigation-bar-left-dropdown-trigger {
        display: none;
    }

    .navigation-bar-expansion-controls .navigation-bar-left-dropdown-trigger {
        display: flex;
        width: auto;
        flex-grow: 2;
        height: 40px;
    }

    .navigation-bar-expansion-controls .navigation-bar-left-search {
        height: 40px;
        width: 40px;
        margin: 0;
    }
    
    .navigation-bar .navigation-bar-left-search {
        display: none;
    }

    .navigation-bar-middle {
        display: none;
    }

    .navigation-bar-right-avatar-wrapper {
        display: none;
    }

    .navigation-bar-right-theme-button-wrapper {
        display: none;
    }

    .navigation-bar-expansion-theme-button-wrapper {
        height: 40px;
        width: 40px;
    }

    .navigation-bar-expansion-theme-button-wrapper img {
        height: 24px;
        width: 24px;
    }

    .navigation-bar-right-moneybag-button-wrapper {
        height: 40px;
        width: 40px;
        margin: 0;
    }

    .navigation-bar-right-moneybag-button-wrapper img {
        height: 24px;
        width: 24px;
    }

    .navigation-bar-right-mobile-menu-button-wrapper {
        height: 24px;
        width: 24px;
    }

    .navigation-bar-expansion-tabs {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .navigation-bar-expansion-tabs > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 44px;
        width: 100%;
        color: var(--color-fig-default)
    }

    .navigation-bar-expansion-account {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
    }

    .navigation-bar-expansion-account-username,
    .navigation-bar-expansion-account-profile,
    .navigation-bar-expansion-account-signout {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 44px;
    }

    .navigation-bar-expansion-account-username > p{
        color: var(--color-fig-subtle);
    }

    .navigation-bar-expansion-account-profile > a,
    .navigation-bar-expansion-account-signout > p {
        color: var(--color-fig-default);
    }

    .navigation-bar-expansion-account-profile,
    .navigation-bar-expansion-account-signout {
        margin-left: 4px;
        padding: 0 16px;
        border-left: 1px solid var(--color-border-default);
    }

    div.navigation-bar-affiliates-drawer {
        width: 100%;
    }

    .navigation-bar-tab > a,
    .navigation-bar-tab-selected > a {
        padding: 4px 0px;
    }

    .navigation-bar-expansion-login-button-wrapper {
        height: 40px;
        width: 100%;
        margin: 24px 0px 12px 0px;
    }
}