.game-preview-banner-all-games {
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    /* height: 100%; */
    /* width: 100px; */
}

.game-preview-banner-all-games > img {
    height: 80px;
    width: 80px;
    margin-right: 12px;
}

.game-preview-banner-all-games > div {
    display: inline-block;
    vertical-align: middle;
}

.game-preview-banner-all-games > div > p {
    display: block;
    margin: 4px;
}

.game-preview-banner-all-games > div > p:first-of-type {
    color: var(--color-fig-default);
}

.game-preview-banner-all-games > div > p:nth-of-type(2) {
    color: var(--color-fig-subtle);
}

@media only screen and (max-width: 1200px) {
    .game-preview-banner-all-games > img {
        height: 48px;
        width: 48px;
        margin-right: 6px;
    }

    .game-preview-banner-all-games > div > p {
        margin: 2px;
    }

    .game-preview-banner-all-games > div p:first-of-type {
        font-size: 16px;
        line-height: 24px;
    }

    .game-preview-banner-all-games > div p:nth-of-type(2) {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
}

