.glossary-header, .glossary-content {
    position: relative;
    display: block;
    margin: auto;
    max-width: 800px;
}

/* Used to add an offset to the href */
.glossary-league-marker {
    padding-top: 64px;
    margin-top: -64px;
    position: absolute;
    display: inline-block;
}

.glossary-league-marker > img {
    padding-bottom: 12px;
    width: 50px;
    height: 50px;
}

.glossary-category-stats {
    display: inline-block;
    position: relative;
    width: 50%;
}

.glossary-team-heading {
    margin-bottom: 20px;
}

.glossary-player-heading {
    margin-bottom: 20px;
    margin-top: 40px;
}

.glossary-margin-separator {
    margin-top: 40px;
}

@media only screen and (max-width: 768px) {
    .glossary-category-stats {
      width: 100%
    }
}

@media only screen and (max-width: 1140px) {
    .glossary-league-marker {
        position: relative;
        display: block;
        margin: auto;
        max-width: 800px;
        /* Used to add an offset to the href */
        padding-top: 64px;
        margin-top: -64px;

        margin-bottom: 16px;
    }

    .glossary-league-marker > img {
        padding-bottom: 4px;
        width: 24px;
        height: 24px;
    }

    .glossary-league-marker > p, .glossary-content > p {
        font-size: 20px;
        line-height: 28px;
    }

    .glossary-margin-separator {
        margin-top: 32px;
    }

    .glossary-player-heading {
        margin-bottom: 12px;
        margin-top: 24px;
    }
}