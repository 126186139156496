.landing-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-page-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-surface-default);
}

.landing-page-headline {
    width: 632px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 112px 0px 96px 0px;
    margin: 112px 0px 48px 0px;
}

.landing-page-headline-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.landing-page-headline-title > p {
    font-family: 'Inter';
    font-size: 60px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: -1.340000033378601px;
    text-align: left;
}

.theme-dark .landing-page-headline-title > p:nth-of-type(2){
    -webkit-text-fill-color: rgba(17, 19, 31, 1);
    -webkit-text-stroke-color: var(--color-fig-accent);
    -webkit-text-stroke-width: 1px; 
}

.theme-light .landing-page-headline-title > p:nth-of-type(2){
    -webkit-text-fill-color: rgba(247, 249, 255, 1);
    -webkit-text-stroke-color: var(--color-fig-accent);
    -webkit-text-stroke-width: 1px; 
}

.landing-page-headline > p {
    margin: 32px 0;
    text-align: center;
}

.landing-page-headline-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.landing-page-headline-get-started-button-wrapper {
    height: 48px;
    width: 128px;
}

.landing-page-headline-download-app-button-wrapper {
    height: 48px;
    width: 153px;
}

.landing-page-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 1016px;

    width: 1363px;
    padding-top: 48px;
}

.theme-dark .landing-page-features {
    background: radial-gradient(50% 100% at 50% 100%, #3A4F97 0%, rgba(37, 57, 116, 0) 100%);
}

.theme-light .landing-page-features {
    background: radial-gradient(50% 100% at 50% 100%, #C1D0FF 0%, rgba(193, 208, 255, 0) 100%);
}

.landing-page-features > p {
    max-width: 375px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 48px;
}

.landing-page-features > img {
    max-height: 506px;
}

.landing-page-features-toggles {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.landing-page-features-toggles-toggle {
    cursor: pointer;
    padding: 6px 12px;
}

.landing-page-features-toggles-toggle > p {
    color: var(--color-fig-default);
}

.landing-page-features-toggles-toggle:hover {
    // Reduce padding by 1 to offset the borders
    padding: 5px 11px;
    border: 1px solid var(--color-border-default);
    border-radius: 24px;
    background-color: var(--color-surface-hover);
}

.landing-page-features-toggles-toggle[data-selected="true"] {
    // Reduce padding by 1 to offset the borders
    padding: 5px 11px;
    border: 1px solid var(--color-chip-fig);
    border-radius: 24px;
    background-color: var(--color-chip-bg);
}

.landing-page-leagues {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 112px 0px;
    border-top: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
    background-color: var(--color-surface-default);
}

.landing-page-leagues > p {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.8899999856948853px;
    text-align: center;
}

.landing-page-leagues-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;
}

.landing-page-showcase {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 112px 32px;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.landing-page-showcase-headline {
    width: 1016px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.landing-page-showcase-headline > p {
    width: 696px;
    text-align: center;
}

.landing-page-showcase-headline-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.landing-page-showcase-headline-title > p {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.8899999856948853px;
    text-align: left;
}

.theme-dark .landing-page-showcase-headline-title > p:nth-of-type(2){
    -webkit-text-fill-color: rgba(17, 19, 31, 1);
    -webkit-text-stroke-color: var(--color-fig-accent);
    -webkit-text-stroke-width: 1px; 
}

.theme-light .landing-page-showcase-headline-title > p:nth-of-type(2){
    -webkit-text-fill-color: rgba(247, 249, 255, 1);
    -webkit-text-stroke-color: var(--color-fig-accent);
    -webkit-text-stroke-width: 1px; 
}

.landing-page-showcase-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.landing-page-showcase-headline-get-started-button-wrapper {
    height: 48px;
    width: 148px;
}

.landing-page-showcase-headline-download-app-button-wrapper {
    height: 48px;
    width: 136px;
}

.landing-page-showcase-subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    width: 100%;
}

.landing-page-showcase-subscription-trends {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    white-space: nowrap;
    width: 100%;
    overflow-x: scroll;
}

.landing-page-showcase-subscription-trends-trend {
    width: 424px;
    background-color: var(--color-surface-default);
    padding: 12px;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    
    flex-shrink: 0;
}

.landing-page-showcase-subscription-trends-trend-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.landing-page-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 112px 0;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.landing-page-footer-text > p {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1.0700000524520874px;
    text-align: left;    
}

.landing-page-footer-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

@media only screen and (max-width: 1363px) {

    .landing-page-features {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .landing-page-features img {
        width: 100%;
    }

    .landing-page-leagues-icons {
        padding-left: 16px;
        padding-right: 16px;
    }

    .landing-page-showcase {
        padding: 112px 16px;
    }

}

@media only screen and (max-width: 1016px) {
    
    .landing-page-showcase-headline {
        width: 100%;
    }

    .landing-page-showcase-headline > p {
        width: 100%;
    }

}

@media only screen and (max-width: 768px) {

    .landing-page-headline {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .landing-page-leagues-icons {
        width: 480px;
    }

    .landing-page-showcase {
        padding: 64px 16px;
    }

    .landing-page-headline-buttons,
    .landing-page-footer-buttons {
        width: 100%;
    }

    .landing-page-headline-buttons > div,
    .landing-page-footer-buttons > div {
        width: 50%;
    }

    .landing-page-showcase-headline {
        padding: 0px 16px;
    }

    .landing-page-leagues {
        padding: 64px 32px;
    }

    .landing-page-leagues img {
        width: 56px;
        height: 56px;
    }

    .landing-page-showcase-headline-title {
        justify-content: center;
        flex-wrap: wrap;
    }

    .landing-page-showcase-headline-title > p {
        //styleName: h1/semibold;
        font-family: Inter;
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.6399999856948853px;
        text-align: center;
    }

    .landing-page-footer {
        padding: 64px 16px;
        gap: 48px;
    }

    .landing-page-footer {
        justify-content: space-between;
    }

    .landing-page-footer-text > p {
        //styleName: h1/semibold;
        font-family: Inter;
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.6399999856948853px;
        text-align: left;
    }

}

@media only screen and (max-width: 480px) {

    .landing-page-leagues-icons {
        width: 100%;
        padding: 0;
    }

}