
.affiliates-sidebar {
    // Probably need to set this to 100% and dictate the width from the parent
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--color-surface-subtle);
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.affiliates-sidebar-linemate-plus-ad {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 124px;
    border-left: 1px solid var(--color-border-default);
    border-right: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
    border-radius: 12px;
    margin-bottom: 32px;
    background-color: var(--color-surface-default);
}

.affiliates-sidebar-linemate-plus-ad > img {
    height: 40px;
    width: 100%;
    border-top: 1px solid var(--color-border-default);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
}

.affiliates-sidebar-linemate-plus-ad-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 0px 16px;
    width: 100%;
    height: 84px;
    border-top: 1px solid var(--color-border-default);
    border-radius: 8px;
}

.affiliates-sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.affiliates-sidebar-header > img {
    height: 20px;
    width: 20px;
    filter: var(--color-fig-subtle-filter);
    cursor: pointer;
}

.affiliates-sidebar-book {
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
}

.affiliates-sidebar-book-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.affiliates-sidebar-book-heading > img {
    height: 18px;
    width: 18px;
    margin: 3px;
}

.affiliates-sidebar-book-heading > p {
    margin-left: 4px;
}

.affiliates-sidebar-book-action-button {
    width: 100%;
    height: 28px;
    margin-top: 12px;
    margin-bottom: 8px;
}

.affiliates-sidebar-book-clickable-disclaimer {
    text-decoration: underline;
}

.affiliates-sidebar-book-dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.affiliates-sidebar-book-dialog-footer > div {
    height: 32px;
    width: 62px;
}