.download-page-container {
    width: 100%;
    height: 100%;
    background-color: var(--color-surface-subtle);
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.download-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.download-page-content > p:first-of-type {
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.8899999856948853px;
    text-align: center;
    margin-bottom: 24px;
}

.download-page-content > p:nth-of-type(2) {
    text-align: center;
}

.download-page-content > img {
    height: 144px;
    width: 144px;
    border-radius: 24px;
    margin-bottom: 48px;
}

.download-page-content-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 32px;
}

.download-page-content-ios-button-wrapper {
    height: 48px;
    width: 178px;
}

.download-page-content-android-button-wrapper {
    height: 48px;
    width: 215px;
}

@media only screen and (max-width: 430px) {

    .download-page-content {
        padding: 114px 16px;
    }

    .download-page-content-buttons > div {
        width: 100%;
    }

}