.initials-image-container {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    background: #E5E7EB;
    border-radius: 50%;
}

.initials-image-container > p {
    width: 100%;
    text-align: center;
    display: inline-block;
    position:absolute;
    top:50%;
    transform:translateY(-50%);
}