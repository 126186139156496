.matchup-card-container {
    display: flex;
    flex-direction: column;
    // A bit crappy way to do it, must be a better way but works for now
    width: calc((100%/3) - ((16px * 2)/3));
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
}

.matchup-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    width: 100%;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 8px 12px;
}

.matchup-card-header p {
    color: var(--color-fig-subtle);
}

.matchup-card-left-column {
    width: 39%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.matchup-card-left-column > img {
    width: 48px;
    height: 48px;
    padding: 4px;
    margin-right: 8px;
}

.matchup-card-team-info-stack > p:first-child {
    color: var(--color-fig-default);
}

.matchup-card-team-info-stack > p:nth-child(2) {
    color: var(--color-fig-subtle);
}

.matchup-card-right-column {
    width: 61%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.matchup-card-right-column > div {
    width: calc(100%/3);
    text-align: center;
}

.matchup-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.matchup-card-content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    width: 100%;
    padding: 4px 12px 4px 8px;
}

.matchup-card-money-column > p {
    color: var(--color-fig-default);
}

.matchup-card-spread-column > p:first-child,
.matchup-card-total-column > p:first-child {
    color: var(--color-fig-subtle);
}

.matchup-card-spread-column > p:nth-child(2),
.matchup-card-total-column > p:nth-child(2) {
    color: var(--color-fig-default);
}

.matchup-card-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    width: 100%;
    background-color: var(--color-surface-subtle);
    border-top: 1px solid var(--color-border-default);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 12px;
}

.matchup-card-actions-button {
    height: 100%;
    width: calc((100%/2) - 12px);
}

@media only screen and (max-width: 1024px) {
    .matchup-card-container {
        // A bit crappy way to do it, must be a better way but works for now
        width: calc((100%/2) - ((16px * 1)/2));
    }
}

@media only screen and (max-width: 768px) {
    .matchup-card-container {
        width: 100%;
    }
}