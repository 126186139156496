.legend-modal {
  content: " ";
  z-index: 55;
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.legend, .legend-sticky {
  float: right;
  position: absolute;
  vertical-align: middle;
  top: 50%;
  left: 100%;
  transform: translate(-100%,-50%);
}

.leaderboard-tab-toggle {
  height: 44px;
  border-bottom: 1px solid var(--color-border-default);
  margin: 32px 0px;
}

.team-player-toggle {
  width: 100%;
  height: 52px;
  margin-top: 1%;
  position: relative;
  border-bottom: 2px solid var(--color-border-default);
}

.table-toggle {
  position: relative;
  display: inline-block;
  height: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 12.5px;
  margin-top: 2.5px;
}

.selected-table-toggle {
  border-bottom: 5px solid #047857;
}

.leaderboard-filters {
  width: 100%;
  position: relative;
}

.leaderboard-filters-sticky {
  z-index: 50;
  width: 100%;
  height: 60px;
  margin-left: -12.5%;
  padding-left: 12.5%;
  padding-top: 20px;
  padding-bottom: 45px;
  top: 60px;
  /* background: white; */
  position: fixed;
  border-bottom: 1px solid var(--color-border-default);
}

.filter-button {
  white-space: nowrap;
  position: relative;
  background: #FFFFFF;
  border: 1px solid var(--color-border-default);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0.75%;
  padding-right: 0.75%;
  margin-right: 1%;
}

.filter-type-dropdown {
  display: block;
  position: absolute;
  /* Might need to have this set as the size of the filter button + some margin */
  top: 33px;
  /* Will also need to set the width to match the filter button */
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  white-space: nowrap;
  padding: 5px;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.leaderboard-table-content {
  margin-top: 1%;
  margin-bottom: 18px;
  overflow-x: auto;
  cursor: grab;
}

.leaderboard-table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  margin-top: 2%;
  /* margin-bottom: 2%; */

  /* New for the mobile-style */
  width: 100%;
  white-space: nowrap;
  /* min-width: 100%; */
}

/* Padding for the second column which should be the team column */
.leaderboard-table th:nth-child(2) {
  padding-left: 20px;
}
.leaderboard-table td:first-child {
  padding-left: 5px;
  padding-right: 10px;
  background-color: var(--color-surface-default);
}
.leaderboard-table td:nth-child(2) {
  padding-left: 5px;
  padding-right: 10px;
  background-color: var(--color-surface-default);
}

.leaderboard-table tr:nth-child(odd) td {
  background-color: var(--color-surface-default);
}

.leaderboard-table tr:nth-child(even) td {
  background-color: var(--color-surface-subtle);
}

.leaderboard-table thead tr th {
  border-bottom: 2px solid var(--color-border-default);
  color: var(--color-fig-subtle);
}

.leaderboard-table tr:last-child td {
  border-bottom: 2px solid var(--color-border-default);
}

.leaderboard-table th {
  background: var(--color-surface-subtle);
  border-top: 1px solid var(--color-border-default);
  padding-top: 1%;
  padding-bottom: 1%;
  position: -webkit-sticky;
  position: sticky;
  /* top: 125px; */
}

.leaderboard-table-group-tr > th {
/* Removing for new mobile-like styling */
/*   top: 125px; */
}

.leaderboard-table tr:nth-child(2) > th {
  /* Removing for new mobile-like styling */
/* top: 170px; */
}

/* Start mobile-like styling */
.leaderboard-table th:first-child, .leaderboard-table td:first-child {
  position: sticky;
  z-index: 2;
  left: 0;
}

.leaderboard-table th:nth-child(2), .leaderboard-table td:nth-child(2) {
  position: sticky;
  z-index: 2;
  padding-left: 7.5px;
  padding-right: 2.5px;
}
.leaderboard-table th:nth-child(2) {
  padding-left: 12.5px;
}

.leaderboard-table-sticky-column {
  position: sticky;
  z-index: 2;
}

 /*
  The reason for having th twice is to have different styling after first-child
  If this is removed, the first and second cols will overlap each other
 */
.leaderboard-table th, .leaderboard-table td {
  left: 0;
  z-index: 1;
  color: var(--color-fig-default);
}
/* End mobile-like styling */

.thead-tooltip-wrapper {
  position: relative;
}

.thead-tooltip-wrapper span {
  display: none;
}

.thead-tooltip-wrapper:hover span {
  display: block;
  position: absolute;
  bottom: -40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  white-space: nowrap;
  padding: 5px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  /* Removing the inheriting text properties from the th */
  font-feature-settings: normal;
  letter-spacing: normal;
  text-transform: none;
}

.thead-tooltip-wrapper img {
  filter: var(--color-fig-accent-filter);
}

.leaderboard-table tr td{
  height: 56px;
}

.leaderboard-table tr th:first-child,
.leaderboard-table tr td:first-child {
  border-left: 1px solid var(--color-border-default);
}

.leaderboard-table tr th:last-child,
.leaderboard-table tr td:last-child {
  border-right: 1px solid var(--color-border-default);
}

/* top-left border-radius */
.leaderboard-table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
.leaderboard-table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
.leaderboard-table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
.leaderboard-table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.leaderboard-table td {
  /* padding-top: 14px;
  padding-bottom: 14px; */
  /* background: white; */
}

.full {
  display: inline-block;
}

.leaderboard-table-content .mobile {
  display: none;
}

.section-heading > p {
  color: var(--color-fig-default);
}

.section-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.leaderboard-standings-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 32px;
}

.leaderboard-standings-wrapper > div {
  width: calc(50% - 16px);
}

.leaderboard-standings-wrapper > div > p {
  color: var(--color-fig-default);
  margin-bottom: 16px;
}

.leaderboard-filters-modal > .betting-leaderboard-filter-wrapper {
  margin-top: 16px;
}

@media only screen and (max-width: 576px) {

  .section-heading > .playbook-picker {
    display: none;
  }

  .leaderboard-standings-wrapper > div {
    width: 100%;
  }

  .team-player-toggle{
    margin-bottom: 5%;
  }

  .table-toggle {
    width: 50%;
    text-align: center;
  }

  .filter-button {
    display: block;
    width: 100%;
    margin-bottom: 2.5%;
    margin-right: 0;


    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 2%;
    padding-right: 1%;
  }

  .filter-button > img {
    float: right;
    margin-top: 4px;
  }

  /* REVIEW: might need a set amount here */
  .leaderboard-table-content {
    /* margin-top: 1%; */
    overflow-x: auto;
  }

  .leaderboard-filters-sticky {
    width: 100%;
    margin-top: 1.5%;
    position: relative;

    /* Resetting the filters-sticky for mobile to look like the normal filters */
    z-index: 0;
    height: auto;
    margin-left: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    top: 0;
    background: transparent;
    border-bottom: none;
  }

  .legend-sticky {
    display: none;
  }

  .leaderboard-table {
    white-space: nowrap;
    table-layout: fixed;
    width: 100%;
  }

  .leaderboard-table th, .leaderboard-table td {
    top: 0;
    left: 0;
    z-index: 1;
  }

  .leaderboard-table th {
    /* padding-top: 2%;
    padding-bottom: 2%; */
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 1%;
    padding-right: 1%;
    top: 0px;
    z-index: 1;
  }

  .leaderboard-table td {
    /* padding-left: 2%;
    padding-right: 2%; */
    position: relative;
  }

  .leaderboard-table th:first-child, .leaderboard-table td:first-child {
    position: sticky;
    z-index: 2;
    left: 0;
  }

  .leaderboard-table th:nth-child(2), .leaderboard-table td:nth-child(2) {
    position: sticky;
    z-index: 2;
    padding-left: 7.5px;
    padding-right: 2.5px;
  }
  .leaderboard-table th:nth-child(2) {
    padding-left: 12.5px;
  }

   /*
    The reason for having th twice is to have different styling after first-child
    If this is removed, the first and second cols will overlap each other
   */
  .leaderboard-table th, .leaderboard-table td {
    left: 0;
    z-index: 1;
  }


  .full {
    display: none;
  }

  .mobile {
    display: inline-block;
  }

  .thead-tooltip-wrapper:hover span {
    display: none;
  }
}

@media only screen and (min-width: 576px) {

  .filter-button {
    display: block;
    width: 100%;
    margin-bottom: 1%;
    margin-right: 0;


    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 2%;
    padding-right: 1%;
  }

  .filter-button > img {
    float: right;
    margin-top: 4px;
  }

  .leaderboard-filters-sticky {
    width: 100%;
    margin-top: 0.5%;
    position: relative;

    /* Resetting the filters-sticky for mobile to look like the normal filters */
    z-index: 0;
    height: auto;
    margin-left: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    top: 0;
    background: transparent;
    border-bottom: none;
  }

  .legend-sticky {
    display: none;
  }

  .leaderboard-table th {
    background-color: var(--color-surface-subtle);
    border-top: 1px solid var(--color-border-default);
    padding-top: 1%;
    padding-bottom: 1%;
    position: -webkit-sticky;
    position: sticky;
    /* top: 125px; */
  }

  .leaderboard-table-group-tr > th {
  /* Removing for new mobile-like styling */
/*   top: 125px; */
  }

  .leaderboard-table tr:nth-child(2) > th {
    /* Removing for new mobile-like styling */
/* top: 170px; */
  }

}

@media only screen and (min-width: 1200px) {
  /* Resetting all the stuff that the previous breakpoints changed back to normal */

  .filter-button {
    display: inline;
    position: relative;
    background: #FFFFFF;
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0.75%;
    padding-right: 0.75%;
    margin-right: 1%;
  }

  .filter-button > img {
    float: none;
    margin-top: 0px;
  }

  .leaderboard-filters-sticky {
    z-index: 50;
    width: 100%;
    height: 60px;
    margin-top: 0%;
    margin-left: -12.5%;
    padding-left: 12.5%;
    padding-top: 20px;
    padding-bottom: 45px;
    top: 60px;
    /* background: white; */
    position: fixed;
    border-bottom: 1px solid var(--color-border-default);
  }

  .legend, .legend-sticky {
    display: block;
    float: right;
    position: absolute;
    vertical-align: middle;
    top: 50%;
    left: 100%;
    transform: translate(-100%,-50%);
  }

  .leaderboard-table th {
    background: var(--color-surface-subtle);
    border-top: 1px solid var(--color-border-default);
    padding-top: 1%;
    padding-bottom: 1%;
    position: -webkit-sticky;
    position: sticky;
    /* top: 125px; */
  }

  .leaderboard-table-group-tr > th {
  /* Removing for new mobile-like styling */
/*   top: 125px; */
  }

  .leaderboard-table tr:nth-child(2) > th {
    /* Removing for new mobile-like styling */
/* top: 170px; */
  }

  .full {
    display: inline-block;
  }

  .leaderboard-table-content .mobile {
    display: none;
  }

}
