.login-page-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-surface-subtle);
}

.login-page-container > a {
    height: 32px;
    width: 44px;
    margin-top: 32px;
    margin-bottom: 144px;
    filter: var(--color-fig-default-filter);
}

.login-page-container > p {
    color: var(--color-fig-default);
}

.login-page-title {
    margin-bottom: 12px;
}

.login-page-no-account-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 40px;
}

.login-page-no-account-signup > p {
    color: var(--color-fig-subtle);
}

.login-page-no-account-signup > a {
    color: var(--color-fig-accent);
}

.login-page-form {
    display: flex;
    flex-direction: column;
    width: 320px;
}

.login-page-form > p {
    margin-bottom: 4px;
    color: var(--color-fig-default);
}

p.login-page-error {
    color: var(--color-fig-negative);
    margin-bottom: 16px;
}

.login-page-form > input {
    margin-bottom: 16px;
    background-color: var(--color-input-bg-default);
    color: var(--color-fig-default);
    border: 1px solid var(--color-input-border-default);
    border-radius: 6px;
    height: 40px;
    padding: 8px 12px;
}

.login-page-form > a {
    color: var(--color-fig-accent);
    margin-bottom: 24px;
}

.login-page-login-button-wrapper {
    height: 40px;
    margin-bottom: 80px;
}

@media only screen and (max-width: 375px) {

    .login-page-container > a {
        margin-top: 16px;
        margin-bottom: 40px;
    }

    .login-page-form {
        width: 100%;
        padding: 0px 16px;
    }

}