.avatar-trigger {
    // Remove the default styling
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: none;

    // Fill the container's space
    height: 100%;
    width: 100%;
}

.avatar-trigger[data-state='closed'] {
    outline: none;
}

.avatar-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 4px;

    // Prevent text selection
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.avatar-container-clickable:hover, .avatar-container-clickable:active {
    cursor: pointer;
}

.avatar-container-clickable:hover {
    background-color: var(--color-default-button-bg-hover);
}

.avatar-container-clickable:active {
    background-color: var(--color-default-button-bg-pressed);
}

.avatar-container-clickable[data-open='true'] {
    padding: 0;
}

.avatar-container-circle {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-avatar-06);
}

.avatar-trigger[data-state='open'] > .avatar-container-clickable > .avatar-container-circle {
    border: 2px solid var(--color-input-border-focus);
}

.avatar-container-circle > p {
    color: var(--color-fig-on-bg);
}

.avatar-dropdown {
    // Statically setting this for now, not sure if that's the right design
    width: 160px;
}

.avatar-dropdown-item {
    outline: none !important;
}

.avatar-dropdown-item-text {
    margin-left: 0 !important;
}