.supporting-stats-container {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 16px;
}

.supporting-stats-sections {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 16px;
}

.supporing-stats-table {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    width: 50%;
}

.supporing-stats-table-subject {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--color-surface-subtle);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--color-border-default);
}

.supporting-stats-table-segmented-control-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 8px 24px;
    border-bottom: 1px solid var(--color-border-default);
}

.supporting-stats-table-headers {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.supporting-stats-table-header {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100%/var(--columns));
}

.supporting-stats-table-header[data-bordered=true] {
    border-left: 1px solid var(--color-border-default);
}

.supporting-stats-table-data {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-surface-default);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.supporting-stats-table-data-entry {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100%/var(--columns));
}

.supporting-stats-table-data-entry[data-bordered=true] {
    border-left: 1px solid var(--color-border-default);
}


@media only screen and (max-width: 1299px) {

    .supporting-stats-sections {
        flex-wrap: wrap;
    }

    .supporing-stats-table {
        width: 100%;
    }
  
}