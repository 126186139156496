.trend-details-profile-banner {
    height: 48px;
    width: 100%;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 32px;
    gap: 4px;
    cursor: pointer;
}

.trend-details-profile-banner > img {
    filter: var(--color-fig-subtle-filter);
}

.trend-details-tab-toggle {
    height: 52px;
    width: 100%;
    background-color: var(--color-surface-default);
    border-bottom: 1px solid var(--color-border-default);
    padding: 0px 32px;
}