.not-found-image {
    margin: auto;
    display: block;
    margin-top: 242px;
}

.not-found-links > a {
    display: inline;
}

/* smallest screens (mobiles) */
@media only screen and (max-width: 768px) {

    .not-found-image {
        margin-top: 86px;
    }

    .not-found-links > a {
        display: block;
    }
  
}