.betting-content-card {
    position: relative;
    padding: 24px 32px;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    margin-top: 16px;
}

.betting-content-card > p:first-of-type {
    color: var(--color-fig-default);
}

.betting-legend-button {
    position: absolute;
    right: 0;
    /* Corresponds to the combined value of the top margin and padding of the team/player text */
    top: 15px;
}

.betting-snapshot-title-column {
    width: 26%;
    background-color: var(--color-surface-subtle);
    color: var(--color-fig-subtle);
}

.betting-content-card-snapshot-body table:first-of-type thead .betting-snapshot-title-column {
    background-color: var(--color-surface-default);
}

.betting-snapshot-away-column, 
.betting-snapshot-home-column {
    width: 37%;
}

.betting-snapshot-combined-column {
    width: 74%;
}

.betting-snapshot-heading-team-left, 
.betting-snapshot-heading-team-right {
    height: 56px;
    /* display: inline-block; */
    border: none;
    padding: 12px 0px;
}

.betting-snapshot-heading-team-left {
    border-radius: 8px 0px 0px 0px;
}

.betting-snapshot-heading-team-right {
    border-radius: 0px 8px 0px 0px;
}

.betting-snapshot-title-group {
    /* Centering the text and image */
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    /* align-items: flex-start; */
    justify-content: center;
}

.betting-snapshot-title-group > img {
    margin-left: 8px;
}

.betting-content-card-snapshot-body table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.betting-content-card-snapshot-body th:nth-of-type(2) {
    border-left: 1px solid var(--color-border-default);
}

.betting-content-card-snapshot-body th:nth-of-type(2),
.betting-content-card-snapshot-body th:nth-of-type(3) {
    background-color: var(--color-surface-subtle);
    border-top: 1px solid var(--color-border-default);
    border-right: 1px solid var(--color-border-default);
    color: var(--color-fig-subtle);
}

.betting-content-card-snapshot-body td {
    border-left: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
    height: 88px;
    padding: 0px 16px;
}

/* Centering the tags */
.betting-snapshot-intro-table .betting-snapshot-away-column, 
.betting-snapshot-intro-table .betting-snapshot-home-column {
    text-align: center;
}

.betting-content-card-snapshot-body tr td:last-child {
    border-right: 1px solid var(--color-border-default);
}

.betting-content-card-snapshot-body tr:first-child td {
    border-top: 1px solid var(--color-border-default);
}

.betting-snapshot-intro-table tr:first-child td:first-child,
.betting-snapshot-data-table tr:first-child td:first-child {
    border-top-left-radius: 8px;
}

.betting-snapshot-intro-table tr:last-child td:first-child,
.betting-snapshot-data-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.betting-snapshot-intro-table tr:last-child td:last-child,
.betting-snapshot-data-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

/* For the intro table the top right doesn't get a radius since it has something above it */
.betting-snapshot-data-table tr:first-child td:last-child {
    border-top-right-radius: 8px;
}

/* Even row different background color */
.betting-snapshot-data-table tr:nth-child(even),
.betting-snapshot-intro-table tr:nth-child(even) {
    /* background: #F9FAFB; */
}

.betting-snapshot-data-table .initials-image-container {
    /* Setting fixed size which is otherwise normally at 100% */
    width: 48px;
    height: 48px;
    /* Calculating half the width minus half the element's size (48/2) */
    left: calc(50% - 24px);
    color: var(--color-fig-default);
}

.betting-content-card-snapshot-body table {
    margin-bottom: 24px;
}

.betting-snapshot-data-table .odd-graph,
.mobile-betting-snapshot-container .odd-graph {
    height: auto;
    margin-top: 8px;
}

.betting-snapshot-data-table .win-percentage-bar,
.mobile-betting-snapshot-container .win-percentage-bar {
    height: 8px;
    display: inline;
    float: left;
    position: relative;
    background: var(--color-border-default);
    border-radius: 8px;
}

.betting-snapshot-data-table .win-percentage-bar:first-child,
.mobile-betting-snapshot-container .win-percentage-bar:first-child {
    margin-right: 2%;
}

.mobile-betting-snapshot-container {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    margin-bottom: 24px;
}

.mobile-betting-snapshot-container-header {
    height: 40px;
    width: 100%;
}

.mobile-betting-snapshot-container-header > div {
    display: inline;
    float: left;
    height: 100%;
    width: 50%;
}

.mobile-betting-snapshot-container-header .betting-snapshot-title-group {
    height: 100%;
    background-color: var(--color-surface-subtle);
}

.mobile-betting-snapshot-container-header > div:first-child > .betting-snapshot-title-group {
    border-top-left-radius: 8px;
}

.mobile-betting-snapshot-container-header > div:nth-child(2) > .betting-snapshot-title-group {
    border-top-right-radius: 8px;
}

.betting-snapshot-title-group > p {
    color: var(--color-fig-subtle);
}

.mobile-betting-snapshot-container-section-heading {
    height: 36px;
    background: var(--color-surface-subtle);
    padding: 8px 16px;
    border-top: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
}

.mobile-betting-snapshot-container-section-heading > p {
    color: var(--color-fig-subtle);
}

.mobile-betting-snapshot-container .initials-image-container {
    color: var(--color-fig-default);
}

.mobile-betting-snapshot-tags-container {
    width: 50%;
    position: relative;
    display: inline;
    float: left;
    padding: 16px;
    text-align: center;
}

.betting-leaderboard-filter-wrapper {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.betting-leaderboard-filter-wrapper-filter {
    width: calc(100% / var(--filters-count));
}

.betting-leaderboard-filter-wrapper-filter > div {
    height: 32px;
}

.betting-leaderboard-filter-wrapper-filter > p {
    color: var(--color-fig-default);
    margin-bottom: 4px;
}

.betting-content-card-leaderboard-wrapper {
    overflow-x: auto;
}

.betting-content-card-leaderboard-wrapper thead th {
    background-color: var(--color-surface-subtle);
}

.betting-content-card-leaderboard {
    text-align: center;
    margin-top: 0;
}

.betting-content-card-leaderboard td {
    /* padding-top: 8px; */
    /* padding-bottom: 8px; */
    vertical-align: middle;
}

.betting-content-card-leaderboard tr:nth-child(even) td {
    background-color: var(--color-surface-subtle);
}

.betting-content-card-leaderboard tr:nth-child(odd) td {
    background-color: var(--color-surface-default);
}

.betting-content-card-leaderboard th[colspan] {
    border-bottom: none;
}

.betting-content-card-leaderboard th[colspan]:last-of-type,
.betting-content-card-leaderboard th[colspan]:nth-last-of-type(2) {
    border-left: 1px solid var(--color-border-default);
}

.betting-content-card-leaderboard tr:nth-child(2) th {
    border-top: none;
}

.betting-content-card-leaderboard .rank-column {
    width: 64px;
    left: 0;
    position: sticky;
    z-index: 2;
}

.betting-content-card-leaderboard .player-rank-column {
    width: 63px;
    left: 0;
    position: sticky;
    z-index: 2;
}

.betting-content-card-leaderboard+.mobile .player-rank-column,
.betting-content-card-leaderboard+.mobile .rank-column {
    width: 8%;
    position: unset;
    z-index: unset;
}

.betting-content-card-leaderboard .team-name-column {
    /* Removing it for now, it seems it wouldn't be necessary since we have a different view on mobile */
    /* left: 67px; */
    position: sticky;
    z-index: 2;
}

.betting-content-card-leaderboard td p {
    vertical-align: middle;
}

.betting-content-card-leaderboard .player-name-column {
    /* Removing it for now, it seems it wouldn't be necessary since we have a different view on mobile */
    /* left: 63px; */
    position: sticky;
    z-index: 2;

    width: 156px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
}

td.player-name-column,
td.team-name-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
}

.betting-content-card-leaderboard+.mobile .player-name-column,
.betting-content-card-leaderboard+.mobile .team-name-column {
    left: unset;
    position: unset;
    z-index: unset;

    width: 43%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
}

.betting-content-card-leaderboard .team-name-column {
    width: 181px;
    text-align: left;
}

.betting-content-card-leaderboard th.team-name-column,
.betting-content-card-leaderboard th.player-name-column {
    text-align: left;
    padding-left: 16px;
}

.betting-content-card-leaderboard .team-name-column > img,
.betting-content-card-leaderboard .player-name-column > img {
    margin-right: 8px;
}

.betting-content-card-leaderboard .standard-column {
    width: 104px;
}

.betting-content-card-leaderboard .line-column,
.betting-content-card-leaderboard .hit-percentage-column,
.betting-content-card-leaderboard .hit-percentage-column-highlighted {
    width: 104px;
}

.betting-content-card-leaderboard .vegas-movement-column {
    width: 131px;
}

.betting-content-card-leaderboard .player-bet-type-column,
.betting-content-card-leaderboard .player-line-column,
.betting-content-card-leaderboard .player-odds-column,
.betting-content-card-leaderboard .player-hit-rate-column,
.betting-content-card-leaderboard .player-hit-percentage-column,
.betting-content-card-leaderboard .player-opponent-team-name-column,
.betting-content-card-leaderboard .player-opponent-rank {
    width: 114.5px;
}

.betting-content-card-leaderboard+.mobile .player-line-column, 
.betting-content-card-leaderboard+.mobile .player-hit-percentage-column,
.betting-content-card-leaderboard+.mobile .line-column, 
.betting-content-card-leaderboard+.mobile .hit-percentage-column {
    width: 18.75%;
}

.betting-content-card-leaderboard+.mobile .action-column {
    width: 11.5%;
}

.betting-content-card-leaderboard .position-column {
    width: 86px;
}

.betting-content-card-leaderboard tr .border-left {
    border-left: 1px solid var(--color-border-default);
}

.betting-content-card-leaderboard .hit-percentage-column-highlighted {
    background: var(--color-surface-pressed) !important;
}

.betting-content-card-leaderboard tr:not(.betting-leaderboard-expanded-section-row):hover td {
    background: var(--color-surface-pressed) !important;
}

.betting-leaderboard-expanded-section-row {
    transition: transform .35s ease-in-out;
}

.betting-leaderboard-expanded-section-row:hover {
    background: unset;
}

.betting-leaderboard-expanded-section-row > td {
    padding: 14px 16px;
    text-align: left;
}

.betting-leaderboard-expanded-section-row > td > p:first-of-type {
    position: relative;
    display: inline-block;
    width: 50%;
}

.betting-leaderboard-expanded-section-row > td > p:nth-of-type(2) {
    position: relative;
    display: inline-block;
    width: 50%;
    text-align: right;
}

.mobile-betting-snapshot-container .initials-image-container {
    /* Setting fixed size which is otherwise normally at 100% */
    width: 48px;
    height: 48px;
    /* Calculating half the width minus half the element's size (48/2) */
    left: calc(50% - 24px);
    margin-top: 16px;
}

.mobile-betting-snapshot-container .initials-image-container p {
    /* In the default fonts css the 24px one is reduced, in this case however we want to keep it to 24 */
    font-size: 24px;
    line-height: 32px;
}

.betting-leaderbaord-pagination-row {
    display: block;
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 16px;
    margin-bottom: 32px;
}

.betting-leaderbaord-pagination-buttons-container {
    display: block;
    position: absolute;
    right: 0;
    height: 100%;
}

.betting-content-insights {
    width: 100%;
    height: auto;
    position: relative;

    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;

    /* 
        Needed to allow the inline <ol> with float: left to take up the space (either that or setting float:left here as well). 
        This will prevent the children set as float: left to go outside the container 
    */
    overflow: hidden;

    
    color: var(--color-fig-default);

    margin-bottom: 24px;
}

.betting-content-insights > p {
    display: inline-block;
    color: var(--color-fig-subtle);
}

.betting-content-insights > p:nth-of-type(1) {
    width: calc(100% - 30px);
}

.betting-content-insights > p:nth-of-type(2) {
    width: calc(50% - 32px);
}

.betting-content-insights > img {
    padding-bottom: 2px;
    margin-right: 8px;
}

.betting-content-insights > .betting-content-insights-away,
.betting-content-insights > .betting-content-insights-home {
    width: calc(50% - 8px);
    float: left;

    /* Margin 0 and padding 0 is to reset all the out of the box values for <ol> to then replace specific ones we want */
    /* The padding left is to make it align properly with the icon */
    margin: 16px 0px 0px 0px;
    padding: 0px 0px 0px 22px;
}

.betting-content-insights > .betting-content-insights-away {
    margin-right: 16px;
}

.betting-content-card-trends-header {
    width: 100%;
    /* height: 96px; */
    position: relative;
    display: inline-block;
}

.betting-content-card-trends-header > p {
    color: var(--color-fig-default);
}

.betting-content-card-picker-wrapper {
    position: absolute;
    right: 0;
    top: 0;
}

.betting-player-tab-team-picker-wrapper {
    position: absolute;
    right: 0;
    top: 0;
}

.betting-team-player-toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    border-bottom: 1px solid var(--color-border-default);
    margin: 32px;
}

.betting-team-player-toggle-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 100%;
}

.betting-team-player-toggle-left > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    /* To compensate for the border shifting content */
    padding-bottom: 1px;
}

.betting-team-player-toggle-left p {
    padding: 4px 8px;
    color: var(--color-fig-subtle);
    cursor: pointer;
}

.betting-team-player-toggle-left-selected {
    border-bottom: 1px solid var(--color-fig-accent);
}

.betting-team-player-toggle-left-selected > p {
    color: var(--color-fig-accent);
}

.betting-team-player-toggle-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.betting-team-player-toggle-right p {
    color: var(--color-ghost-button-fig);
}

.betting-leaderboard-pagination-button {
    display: inline-block;
    position: relative;
    height: 32px;
    width: 32px;
}

.betting-leaderboard-pagination-button:last-of-type {
    margin-left: 12px;
}

.betting-player-filter-wrapper {
    width: 250px;
}

.betting-player-filter-wrapper .filter-button {
    display: flex !important;
}

.betting-player-filter-wrapper .filter-button img {
    position: absolute;
    right: 12px;
}

.betting-content-card-filters-wrapper {
    /* Old styling */
    /* display: inline;
    vertical-align: text-bottom;
    position: absolute;
    top: 8px;
    right: 250px;
    white-space: nowrap; */
    /* display: block;
    position: relative; */
    /* 24 px margin plus 8 px from the padding of the filters */
    display: flex !important;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

.betting-content-card-filters-wrapper-filter {
    width: calc(100% / var(--filters-count));
}

.betting-content-card-filters-wrapper-filter > div {
    height: 32px;
}

.betting-content-card-filters-wrapper-filter > p {
    color: var(--color-fig-default);
    margin-bottom: 4px;
}

.betting-container {
    margin-top: 0 !important;
    border-left: 1px solid var(--color-border-default);
    border-right: 1px solid var(--color-border-default);
}

.betting-container .filter-button {
    padding: 8px 12px;
    margin-right: 16px;
}

.betting-content-card-trend-averages {
    position: relative;
    margin-top: 32px;
}

.betting-content-card-trend-averages > p {
    color: var(--color-fig-default);
}

.trend-averages-wrapper {
    overflow-x: scroll;
    white-space: nowrap;
    /* There's an unknown padding being added at the bottom, this is to counteract it */
    margin-bottom: -16px;
}

.trend-averages-wrapper::-webkit-scrollbar {
    /* Remove scrollbar space */
    width: 0;
    /* Optional: just make scrollbar invisible */
    background: transparent;
}

.trend-averages-left-button,
.trend-averages-right-button {
    position: absolute;
    display: block;
    height: 84px;
    width: 32px;

    background: #FFFFFF;
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

    top: 40px;
}

.trend-averages-left-button {
    border-radius: 6px 0px 0px 6px;
    left: 0;
}

.trend-averages-right-button {
    border-radius: 0px 6px 6px 0px;
    right: 0;
}

.trend-averages-left-button > img,
.trend-averages-right-button > img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.betting-content-card-trends-body {
    width: 100%;
    position: relative;
}

.betting-content-card-trends-body-graph-wrapper {
    width: 100%;
    height: 375px;
    position: relative;
}

.betting-content-card-trends-body-graph-wrapper > .no-results-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.betting-content-card-trends-body-wrapper {
    display: flex;
    margin-top: 20px;
}

.betting-content-card-trends-body-info-wrapper {
    /* width: 100%; */
    /* 
        This is the width for the component with the def rank on the right side for player tab 
        technically for the team one it's at 808 but to make it simpler for now we'll keep both at 848
    */
    /* width: 848px; */
    height: 136px;
    position: relative;
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    border-radius: 8px;
    /* margin-top: 20px; */
    /* padding: 12px 24px; */
    padding: 16px;

    display: flex;
    flex-grow: 1;

    background-color: var(--color-surface-default);
}

.betting-content-card-trends-body-info-wrapper.desktop.centered {
    margin: auto;
    display: block;
}

.betting-content-card-trends-body-info-wrapper.desktop > div:first-of-type {
    display: inline;
    float: left;
}

.betting-content-card-flex-img-left {
    /* Centering the text and image */
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    /* align-items: flex-start; */
    justify-content: center;
}

.betting-content-card-flex-img-left img {
    margin-right: 8px;
}

.betting-content-card-flex-img-left > p {
    color: var(--color-fig-default);
}

.betting-content-card-trends-body-info-def-rank {
    background-color: var(--color-surface-default);
}

.betting-content-card-trends-body-info-def-rank > p:first-of-type {
    color: var(--color-fig-default);
}

.betting-content-card-trends-body-info-def-rank > p:nth-of-type(2) {
    color: var(--color-fig-subtle);
}

.betting-content-card-trends-body-info-hit-rate, .betting-content-card-trends-body-info-hit-rate-over {
    width: 33%;
    height: calc(100% - 24px);
    position: relative;
    display: inline-block;
    vertical-align: bottom;
}

.betting-content-card-trends-body-info-hit-rate > p,
.betting-content-card-trends-body-info-hit-rate-over > p {
    color: var(--color-fig-default);
}

.betting-content-card-trends-body-info-hit-rate > p:nth-child(2) {
    margin-top: 16px;
}

.betting-content-card-trends-body-info-hit-rate-text-fields > p {
    color: var(--color-fig-default);
}

.betting-content-card-trends-body-info-line {
    /* Started off at 24 */
    /* width: 33%; */
    /* width is being set dynamically based on the type of line */
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;

    border-left: 1px solid var(--color-border-default);
    border-radius: 2px;
    padding-left: 24px;
}

/*  , .betting-content-card-trends-body-info-hit-rate-over > p:first-child */
.betting-content-card-trends-body-info-line > p:first-child {
    /* 16px margin plus 24px where the average heading is on the other two sections*/
    /* margin-top: 40px; */
}

.betting-content-card-trends-body-info-line > p {
    color: var(--color-fig-default);
}

.betting-content-card-trends-body-info-hit-rate > p:first-child,
.betting-content-card-trends-body-info-hit-rate-over > p:first-child {
    margin-top: 16px;
}


.betting-content-card-trends-body-info-average {
    border-left: 1px solid var(--color-border-default);
    border-radius: 2px;
}

.betting-content-card-trends-body-info-average, .betting-content-card-trends-body-info-home-average, .betting-content-card-trends-body-info-away-average {
    /* Started off at 20 */
    width: 12%;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;

    padding-left: 24px;
}

.betting-content-card-trends-body-info-average > p:nth-child(3), .betting-content-card-trends-body-info-home-average > p:nth-child(2), .betting-content-card-trends-body-info-away-average > p:nth-child(2) {
    position: absolute;
    bottom: 16px;
}

.betting-content-card-trends-body-info-average > p:nth-child(2) {
    margin-top: 16px;
}

.betting-content-card-trends-body-info-home-average > p:first-child, .betting-content-card-trends-body-info-away-average > p:first-child {
    /* 16px margin plus 24px where the average heading is on the other two sections*/
    margin-top: 40px;
}

.betting-content-card-trends-body-info-hit-rate-fractional {
    /* Height matching the line-height of the neighbouring standalone text */
    height: 32px;
    display: inline;
    padding: 4px 8px;
    /* background: #F3F4F6; */
    background-color: var(--color-surface-subtle);
    border-radius: 6px;

    margin-bottom: 3px;
    margin-left: 16px;
    vertical-align: text-bottom;
}

.betting-content-card-trends-body-info-hit-rate-fractional > p {
    color: var(--color-fig-subtle);
}

.betting-content-card-trends-body-info-hit-rate-text-fields {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

.betting-content-card-trends-body-info-line-selection {
    height: 44px;
    /* This was originall 24px but we're increasing it to better match the increased size of the hit rate section */
    /* margin-right: 36px; */
    margin-top: 36px;

    /* background: #FFFFFF; */
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    /* Needed to allow the right side to fill the space dynamically */
    display: flex;
}

.betting-content-card-trends-body-info-line-selection-text {
    /* background: #F3F4F6; */
    box-shadow: 1px 0px 0px var(--color-border-default);
    display: inline-block;
    position: relative;
    height: 100%;
    padding: 8px 10px;
    border-radius: 6px 0px 0px 6px;
}

.betting-content-card-trends-body-info-line-selection-input {
    /* display: inline-block;
    position: relative;
    height: 100%;
    padding: 8px 10px; */
    /* Needed to allow the right side to fill the space dynamically */
    position: relative;
    flex-grow: 1;
}

.betting-content-card-trends-body-info-line-selection-input > img {
    height: 36px;
    width: 36px;
    padding: 8px;
    position: absolute;
    top: 4px;
    z-index: 2;
    /* margin-top: 4px; */
}

/* .betting-content-card-trends-body-info-line-selection-input > p {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%,-0%);
} */

.betting-content-card-trends-body-info-line-selection-input > input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    text-align: center;

    /* The 48 px on each side is 12px margin between the +/- signs and the edge, and 36px as the size of the img*/
    width: calc(100% - 48px - 48px);

    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%,-0%);
    background-color: var(--color-surface-default);
    color: var(--color-fig-default);
}

.betting-content-card-trends-body-info-line-selection-input > input:focus {
    outline: none;
}

.betting-content-card-trends-body-info-line-selection-input > input::-webkit-outer-spin-button, 
.betting-content-card-trends-body-info-line-selection-input > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.betting-content-card-trends-body-info-line-selection-input > img:first-child {
    left: 12px;
}

.betting-content-card-trends-body-info-line-selection-input > img:nth-child(2) {
    right: 12px;
}

.betting-content-card-trends-body-info-def-rank {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    height: 136px;
    /* width: 240px; */
    width: calc(25% - 16px);
    margin-left: 16px;

    padding: 16px;
    /* background: #FFFFFF; */
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    border-radius: 8px;
}

.betting-content-supporting-stats-header {
    position: relative;
    display: block;
    width: 100%;
    height: 32px;
    margin-top: 24px;
}

.betting-content-supporting-stats-header > p {
    color: var(--color-fig-default);
}

.betting-content-supporting-stats-header-filters-wrapper {
    position: absolute;
    right: 0;
    top: 0px;
}

.betting-content-supporting-stats-header-filters-wrapper > span {
    vertical-align: sub;
}

.betting-content-supporting-stats-header-filters-wrapper .filter-button {
    margin-right: 0;
}

.betting-content-supporting-stats-table-container {
    position: relative;
    display: inline-block;
    width: calc(50% - 20px);
    height: auto;
    margin-top: 16px;
}

.betting-content-supporting-stats-table-container ~ .betting-content-supporting-stats-table-container {
    margin-left: 40px;
}

.betting-content-supporting-stats-top-table-header {
    position: relative;
    display: block;
    width: 100%;
    height: 56px;
    padding: 12px 24px;
    /* margin-top: 24px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    /* Centering the text and image */
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    /* align-items: flex-start; */
    justify-content: center;

    background-color: var(--color-surface-subtle);
    border: 1px solid var(--color-border-default);
}

.betting-content-supporting-stats-top-table-header > img {
    margin-right: 8px;
}

.betting-content-supporting-stats-top-table-header > p {
    color: var(--color-fig-default);
}

.betting-content-supporting-stats-top-table-column-headers {
    position: relative;
    display: block;
    width: 100%;
    height: 24px;
    background-color: var(--color-surface-subtle);
}

.betting-content-supporting-stats-picker-container {
    position: relative;
    display: block;
    width: 100%;
    height: 48px;
    padding: 8px 24px;

    box-shadow: inset 0px -1px 0px var(--color-border-default), inset 1px 0px 0px var(--color-border-default), inset -1px 0px 0px var(--color-border-default);
}

.betting-content-supporting-stats-picker-container .picker {
    height: 32px;
}

.betting-content-supporting-stats-top-table-column-data {
    position: relative;
    display: block;
    width: 100%;
    height: 56px;
}

.betting-content-supporting-stats-top-table-column-headers > div {
    position: relative;
    display: inline-block;
    width: 20%;
    padding: 4px;
    vertical-align: bottom;
    box-shadow: inset 0px -1px 0px var(--color-border-default), inset 1px 0px 0px var(--color-border-default);
    color: var(--color-fig-subtle);
}

.betting-content-supporting-stats-top-table-column-data > div {
    position: relative;
    display: inline-block;
    width: 20%;
    padding: 16px 8px;
    vertical-align: bottom;
    box-shadow: inset 0px -1px 0px var(--color-border-default), inset 1px 0px 0px var(--color-border-default);
    color: var(--color-fig-default);
}

.betting-content-supporting-stats-top-table-column-headers > div:last-child,
.betting-content-supporting-stats-top-table-column-data > div:last-child {
    /* Need to make sure the last one is NOT inset otherwise it won't be flush with the component above it */
    box-shadow: inset 0px -1px 0px var(--color-border-default), inset 1px 0px 0px var(--color-border-default), 1px 0px 0px var(--color-border-default);
}

.betting-content-supporting-stats-top-table-column-data > div:first-child {
    border-bottom-left-radius: 8px;
}

.betting-content-supporting-stats-top-table-column-data > div:last-child {
    border-bottom-right-radius: 8px;
}

.betting-content-supporting-stats-gamelog {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 24px;
}

.betting-content-supporting-stats-gamelog table {
    width: 100%;
}

.betting-content-supporting-stats-gamelog th {
    background: var(--color-surface-subtle);
    height: 40px;
    color: var(--color-fig-subtle);
}

.betting-content-supporting-stats-gamelog td {
    height: 56px;
    color: var(--color-fig-default);
}

/* Even row different background color */
.betting-content-supporting-stats-gamelog tr:nth-child(even),
.betting-content-supporting-stats-gamelog tr:nth-child(even) {
    /* background: #F9FAFB; */
}

.betting-content-supporting-stats-gamelog th,
.betting-content-supporting-stats-gamelog td {
    width: 100px;
    text-align: center;
}

.betting-content-supporting-stats-gamelog th:nth-of-type(2),
.betting-content-supporting-stats-gamelog td:nth-of-type(2) {
    width: 100px;
}

.betting-content-supporting-stats-gamelog td:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.betting-content-supporting-stats-gamelog > .betting-leaderbaord-pagination-row {
    margin-top: 16px;
}

.betting-horizontal-scroll-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 16px;
    overflow: auto;
    width:100%;
    min-height: 50px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.betting-horizontal-scroll-container::-webkit-scrollbar {
    display: none;
}

.betting-spoortsbook-container {
    flex: 1;
    max-width: 25%;
    min-width: 260px;                                            
    flex-shrink: 0;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    padding: 16px;
}

.betting-sportsbook-shadow {    
    display: none;
}

.betting-sportsbook-bottom-link {
    display: none;
}

@media only screen and (max-width: 576px) {
    .betting-container .table-toggle {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 577px) {
    .betting-container .table-toggle {
        width: 144px;
    }
    
}

@media only screen and (min-width: 1300px) {

    .betting-container .desktop {
        display: block;
    }

    .betting-content-card-trends-body-info-wrapper.desktop:not(.centered),
    .betting-content-card-trends-body-info-def-rank.desktop {
        display: inline-block;
    }

    .betting-content-card-trends-body-info-def-rank.mobile,
    .betting-content-card-trends-body-info-wrapper.mobile {
        display: none;
    }

    .betting-container .mobile {
        display: none;
    }

    .betting-container .filter-button {
        display: inline;
    }

    .betting-container .filter-button > img {
        float: none;
        margin-top: 0px;
    }
}

/* 
    This is the breakpoint for switching to the compact version
 */
@media only screen and (max-width: 1299px) {

    .betting-container .desktop,
    .betting-content-card-trends-body-info-wrapper.desktop,
    .betting-content-card-trends-body-info-def-rank.desktop,
    .betting-content-card-trends-body-info-wrapper.desktop.centered {
        display: none;
    }

    .betting-container {
        margin-left: 0;
        margin-right: 0;
    }

    .betting-container .mobile {
        display: block;
    }

    table+.mobile {
        display: table !important;
    }

    .betting-content-card {
        position: relative;
        /* To counter the 3% margin on each side */
        /* width: 106%; */
        /* Push it back 3% to the left for the same reason */
        /* margin-left: -3%; */
        border-radius: 0px;
        box-shadow: none;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 8px 32px;
    }

    .betting-team-player-toggle {
        margin: 24px 32px;
    }

    .betting-legend-button {
        /* Match the container padding */
        right: 24px;
        /* TBH eyeballed that one */
        top: 34px;
    }

    .betting-leaderboard-filter-wrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        margin-bottom: 32px;
    }

    .betting-leaderboard-filter-wrapper .filter-button {
        margin-bottom: 16px;
    }

    .betting-leaderboard-filter-wrapper-filter {
        width: 100%;
    }

    td.player-name-column,
    td.team-name-column {
        width: 100% !important;
    }

    .betting-leaderbaord-pagination-row {
        margin-bottom: 24px;
    }

    .betting-content-card-trend-averages {
        padding-bottom: 24px;
    }

    .betting-content-card-trends-header {
        height: 100px;
        margin-bottom: 24px;
        display: inline;
    }

    .betting-content-insights > p:nth-of-type(2) {
        width: calc(100% - 46px);
    }

    .betting-content-insights > .betting-content-insights-away,
    .betting-content-insights > .betting-content-insights-home {
        width: 100%;
    }

    .betting-content-insights > .betting-content-insights-away {
        margin-bottom: 16px;
    }

    .betting-content-card-filters-button {
        height: 44px;
        width: 100%;
        margin-top: 24px;

        background: #FFFFFF;
        border: 1px solid var(--color-border-default);
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        padding: 12px 0px;
    }

    .betting-content-card-filters-button > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .betting-content-card-filters-button img {
        margin-right: 4px;
    }

    .betting-content-card-filters-button > div > p {
        margin-left: 4px;
    }

    .betting-content-card-picker-wrapper {
        top: 56px;
        width: 100%;
    }

    .betting-content-card-picker-wrapper > .picker {
        width: 100%;
        margin-top: 0;
    }

    .betting-content-card-filters-wrapper {
        top: 40px;
        right: 0px;
        width: 100%;
    }

    .betting-content-card-filters-wrapper {
        flex-direction: column;
        margin-top: 16px;
    }
    
    .betting-content-card-filters-wrapper-filter {
        width: 100%;
    }

    .betting-container > .filter-button {
        margin-top: 16px;
    }

    .trend-averages-wrapper {
        /* For mobile we need to keep the bottom padding (not using negative margin against it), at least for safari -- TBD for other mobile browsers */
        margin-bottom: 0px;
    }

    .betting-content-card-trends-body-info-wrapper {
        /* height: 273px; */
        height: 260px;
        width: 100%;
        margin-top: 24px;
        flex-wrap: wrap;
    }

    .betting-content-card-flex-img-left {
        width: 100%;
    }

    .betting-content-card-trends-body-info-hit-rate, .betting-content-card-trends-body-info-hit-rate-over {
        width: 50%;
        height: 88px;
        margin-top: 0px;
        margin-bottom: 14px;
        position: relative;
        display: inline-block;
        vertical-align: bottom;
    }

    .betting-content-card-trends-body-info-hit-rate-over > p:first-child {
        /* margin-top: 0px; */
    }

    .betting-content-card-trends-body-info-hit-rate-text-fields {
        position: absolute;
        /* 24px spacing minus the 14 px margin from the parent */
        bottom: 10px;
    }

    .betting-content-card-trends-body-info-line {
        width: 100%;
        /* Originally 104px */
        height: 100px;
        /* padding-top: 14px; */
        padding-left: 0;
        border-left: none;
        border-top: 1px solid var(--color-border-default);
    }

    .betting-content-card-trends-body-info-line > p:first-child {
        margin-top: 10px;
    }

    .betting-content-card-trends-body-info-line-selection {
        margin-right: 0px;
        margin-top: 16px;
    }

    .betting-content-card-trends-body-graph-wrapper {
        height: 275px;
    }

    .betting-content-card-trends-body-info-def-rank {
        width: 100%;
        margin-left: 0;
        margin-top: 16px;
    }

    .betting-content-card-trends-body-info-hit-rate-fractional {
        /* This normally sits at 16px but because on mobile it gets reduced a bit further, 
        we're going to reduce the margin for now if we keep the 'card' background for the trends on mobile */
        margin-left: 8px;
    }

    .betting-content-card-trends-body-info-average, 
    .betting-content-card-trends-body-info-home-average, 
    .betting-content-card-trends-body-info-away-average {
        height: 88px;
        width: 33.3%;
        border: none;
        padding-left: 0px;
        padding-right: 24px;
    }

    .betting-content-card-trends-body-info-average > p:nth-child(1), 
    .betting-content-card-trends-body-info-home-average > p:nth-child(1), 
    .betting-content-card-trends-body-info-away-average > p:nth-child(1) {
        position: absolute;
        bottom: 46px;
    }

    .betting-content-card-trends-body-info-average > p:nth-child(2), 
    .betting-content-card-trends-body-info-home-average > p:nth-child(2), 
    .betting-content-card-trends-body-info-away-average > p:nth-child(2) {
        position: absolute;
        bottom: 4px;
    }

    .betting-content-supporting-stats-table-container {
        width: 100%;
        margin-left: 0 !important;
        margin-top: 24px;
    }

    .betting-content-supporting-stats-table-container .picker {
        margin-top: 0;
    }

    .betting-content-supporting-stats-gamelog {
        overflow: auto;
    }

    .betting-content-supporting-stats-gamelog table {
        white-space: nowrap;
        table-layout: fixed;
    }

    .betting-content-supporting-stats-gamelog th,
    .betting-content-supporting-stats-gamelog td {
        width: 50px;
    }

    .betting-content-supporting-stats-gamelog th:nth-of-type(1),
    .betting-content-supporting-stats-gamelog td:nth-of-type(1)  {
        width: 70px;
    }

    .betting-content-supporting-stats-gamelog th:nth-of-type(2),
    .betting-content-supporting-stats-gamelog td:nth-of-type(2)  {
        width: 100px;
    }

    .betting-content-supporting-stats-gamelog th:nth-of-type(3),
    .betting-content-supporting-stats-gamelog td:nth-of-type(3)  {
        width: 100px;
    }

    .betting-content-card-leaderboard .team-name-column > img,
    .betting-content-card-leaderboard .player-name-column > img {
        margin-right: 2px;
    }

    .betting-content-card-leaderboard tr th:last-of-type,
    .betting-content-card-leaderboard tr th:nth-last-of-type(3),
    .betting-content-card-leaderboard tr td:last-of-type,
    .betting-content-card-leaderboard tr td:nth-last-of-type(3),
    .betting-content-card-leaderboard .player-opponent-team-name-column {
        border-left: none;
    }

    .betting-content-card-leaderboard .betting-leaderboard-expanded-section-row td {
        border-left: 1px solid  var(--color-border-default) !important;
        border-right: 1px solid  var(--color-border-default) !important;
    }

    .betting-sportsbook-shadow {
        position: absolute;
        display: block;
        z-index: 4;
        height: 100%;
        width: 40px;
        background: linear-gradient(90deg, rgba(249, 250, 251, 0) 0%, #F9FAFB 100%);
        right: 0;
        top: 0px;
    }
}

@media only screen and (max-width: 1299px) {
    .betting-sportsbook-bottom-link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 26px;
    }

    .betting-sportsbook-all-link {
        display: none;
    }
}