.authentication-hero-image {
    display: inline-block;
    position: absolute;
    width: 40%;
    height: 100vh;
    object-fit: fill;
    z-index: -1;
}

.authentication-hero {
    display: inline-block;
    position: absolute;
    height: 100vh;
    width: 40%;
    position: absolute;
    z-index: 10;

    padding: 40px;
}

.authentication-full-header > a, .authentication-full-header > p {
    display: block;
}

.authentication-mobile-header {
    display: none;
}

.authentication-content {
    display: inline-block;
    position: relative;
    height: 100vh;
    width: 60%;
    left: 40%;

    padding-left: 10%;
    padding-right: 10%;
    padding-top: 7.5%;
}

.authentication-content-header {
    display: block;
    width: 100%;
    text-align: center;
}

.authentication-content-picker {
    width: 100%;
    height: 52px;
    margin-top: 5%;
    position: relative;
    border-bottom: 2px solid #D1D5DB;
}

.authentication-content-picker > span {
    width: 50%;
}

.authentication-content-picker > span > p {
    display: block;
    width: 100%;
    text-align: center;
}

.authentication-content-signup, .authentication-content-login {
    margin-top: 20px;
}

.authentication-content-signup-privacy, .authentication-content-login-forgot-password {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

.authentication-content-signup > input:first-child {
    width: 48%;
    margin-right: 2%;
}

.authentication-content-signup > input:nth-child(2) {
    width: 48%;
    margin-left: 2%;
}

@media only screen and (max-width: 768px) {

    .authentication-hero-image {
        display: block;
        position: absolute;
        width: 100%;
        max-height: 160px;
        object-fit: cover;
        z-index: -1;
    }
    
    .authentication-hero {
        display: block;
        position: absolute;
        max-height: 160px;
        width: 100%;
        position: absolute;
        z-index: 10;
    
        padding: 16px;
    }

    .authentication-full-header {
        display: none;
    }

    .authentication-mobile-header {
        margin-top: 15px;
        display: block;
    }

    .authentication-content {
        width: 100%;
        height: auto;
        left: 0;
        top: 160px;
        padding-left: 5%;
        padding-right: 5%;
    }

    .authentication-content-signup > input:first-child {
        width: 100%;
        margin-right: 0%;
    }
    
    .authentication-content-signup > input:nth-child(2) {
        width: 100%;
        margin-left: 0%;
    }

}

@media only screen and (min-width: 769px) and (max-width: 992px) {

    .authentication-hero {
        padding: 20px;
    }

    .authentication-content {
        padding-left: 5%;
        padding-right: 5%;
    }
    
    .authentication-content-signup > input:first-child {
        width: 100%;
        margin-right: 0%;
    }
    
    .authentication-content-signup > input:nth-child(2) {
        width: 100%;
        margin-left: 0%;
    }
}