
.full-width {
  width: 100%;
}

.standings-card {
  position: relative;
  display: inline-block;
  width: 98%;
  /* height: 849px; */
  background: var(--color-surface-default);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5%;
  margin: 1%;
}

.picker {
  width: 100%;
  height: 40px;
  /* top: 10px; */
  top: 0;
  padding: 2px;
  position: relative;
  display: inline-block;
  background: var(--color-surface-pressed);
  border-radius: 8px;

  display: flex;
  align-items: center;
}

.picker-active {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: var(--color-surface-default);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.picker-inactive {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.picker-active > span,
.picker-inactive > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.standings-footer {
  width: 100%;
  position: relative;
  margin-top: 3.5%;
}
