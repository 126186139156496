.stats-legend-container {
  height: 100%;
  max-height: 476px;
  width: 570px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);

  background: var(--color-surface-subtle);
  /* box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04); */
  /* border: 1px solid var(--color-border-default); */
  border-radius: 8px;
}

.stats-legend-header {
  width: 100%;
  /* height: 15%; */
  height: 64px;
  background: var(--color-surface-default);
  border-radius: 8px 8px 0px 0px;
  position: relative;
  border-bottom: 2px solid var(--color-border-default);

  display: flex;
  align-items: center;
}

.stats-legend-header > p {
  color: var(--color-fig-default);
}

.stats-legend-header > p {
  margin-left: 5%;
}

.stats-legend-header > span {
  padding-right: 5%;
}

.stats-legend-content {
  width: 100%;
  /* To get it to scroll */
  height: 100%;
  overflow: auto;
  background: var(--color-surface-subtle);
  position: relative;
  /* Block needed to wrap the content height */
  display: block;
  /* padding-left: 5%;
  padding-bottom: 5%; */
  padding: 24px;

  color: var(--color-fig-subtle);
}

.stats-legend-content > p {
  display: inline-block;
  /* TODO: need to review these */
  /* margin-top: 2.5%;
  margin-bottom: 1.5%; */
}

.stats-legend-content > span {
  width: 50%;
  position: relative;
  display: inline-block;
}

.stats-legend-content-half-container {
  /* Remove space for the margin */
  width: calc(50% - 12px);
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.stats-legend-content-half-container:nth-child(even) {
  margin-right: 12px;
}

.stats-legend-content-half-container:nth-child(odd) {
  margin-left: 12px;
}

.stats-legend-footer {
  width: 100%;
  /* height: 15%; */
  height: 64px;
  position: relative;
  background: var(--color-surface-default);
  border-radius: 0px 0px 8px 8px;
  border-top: 2px solid var(--color-border-default);
}

.stats-legend-close-button {
  width: 17.5%;
  height: 65%;
  min-width: 86px;
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(-95%,-50%);

  background: var(--color-surface-default);
  border: 1px solid var(--color-border-default);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  color: var(--color-fig-default);
}

.stats-legend-close-button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TODO: also add a media query for height if needed */
@media only screen and (max-width: 576px) {
  .stats-legend-container {
    width: 90%;
    height: 300px;
    /* This corresponds to the mobile nav bar */
    top: 130px;
    left: 50%;
    transform: translate(-50%,-0%);
  }

  .stats-legend-content {
    height: 100%;
    overflow: auto;
  }

  .stats-legend-content > span {
    width: 100%;
    position: relative;
    display: inline-block;
  }
}

@media only screen and (min-width: 576px) {
  .stats-legend-container {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .stats-legend-container {
    width: 570px;
  }
}

@media only screen and (min-width: 992px) {
  .stats-legend-container {
    width: 570px;
  }
}

@media only screen and (min-width: 1200px) {
  .stats-legend-container {
    width: 570px;
  }
}
