.trend-container {
    display: flex;
    flex-direction: column;
    width: calc(50% - 16px);
}

.trend-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.trend-header-left,
.trend-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.trend-header-left-title {
    margin-right: 8px;
    color: var(--color-fig-default);
}

.trend-header-left-subtitle {
    color: var(--color-fig-subtle);
}

.trend-header-left-title.text-style-h-3-medium,
.trend-header-left-subtitle.text-style-h-3-medium {
    display: inherit;
}

.trend-header-left-title.text-style-h-4-medium,
.trend-header-left-subtitle.text-style-h-4-medium {
    display: none;
}

.trend-header-right > a {
    margin-right: 16px;
    color: var(--color-fig-accent);
}

.trend-header-right > a.text-style-body-normal {
    display: inherit;
}

.trend-header-right > a.text-style-label-normal {
    display: none;
}

.trend-header-right-button {
    display: inherit;
    height: 32px;
    width: 32px;
}

.trend-header-right-button:last-of-type {
    margin-left: 12px;
}

.trend-card,
.trend-card-mobile {
    position: relative;
    margin-top: 16px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    box-shadow: var(--shadow-small);
    // To match the card heights from the same row in case there are variations in the number of insights
    flex-grow: 1;
}

.trend-card:hover,
.trend-card-mobile:hover {
    background-color: var(--color-surface-hover);
}

.trend-card:active,
.trend-card-mobile:active {
    background-color: var(--color-surface-pressed);
}

.trend-section-mobile {
    display: none;
}

.trend-card-no-content {
    position: relative;
    margin-top: 16px;
    padding: 12px;
    border-radius: 8px;
    height: 172px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.theme-light .trend-card-no-content {
    // Native css doesn't allow for control over the dashes of a border so we need to use a background image from svg
    // https://kovart.github.io/dashed-border-generator/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(217,217,224,1)' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.theme-dark .trend-card-no-content {
    // Native css doesn't allow for control over the dashes of a border so we need to use a background image from svg
    // https://kovart.github.io/dashed-border-generator/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(54,58,63,1)' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.trend-card-no-content-text {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.trend-card-no-content-text > img {
    height: 48px;
    width: 48px;
    filter: var(--color-fig-disabled-filter);
}

.trend-card-no-content-text p {
    color: var(--color-fig-subtle);
}

.trend-card-no-content-button-wrapper {
    width: 158px;
    height: 32px;
}

@media only screen and (max-width: 1024px) {
    .trend-header-left-title.text-style-h-3-medium,
    .trend-header-left-subtitle.text-style-h-3-medium {
        display: none;
    }
    
    .trend-header-left-title.text-style-h-4-medium,
    .trend-header-left-subtitle.text-style-h-4-medium {
        display: inherit;
    }
}

@media only screen and (max-width: 768px) {
    .trend-container {
        width: 100%;
    }

    .trend-header-right > a {
        margin-right: 0;
    }

    .trend-header-right > a.text-style-body-normal {
        display: none;
    }

    .trend-header-right > a.text-style-label-normal {
        display: inherit;
    }

    .trend-header-right-button {
        display: none;
    }

    .trend-section-mobile {
        display: flex;
        overflow-x: auto;
        gap: 16px;
    }

    .trend-card {
        display: none;
    }

    .trend-card-mobile .trend-content-header {
        // This is a really big hack but I couldn't figure out how to set the width with 100% and needed to move on
        // Full viewport minus padding from parent and padding from card and border
        width: calc(100vw - 16px - 16px - 12px - 12px - 2px);
    }

}