.checkbox-component {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-default-button-border-default);
    border-radius: 2px;
    background-color: var(--color-default-button-bg-default);
    cursor: pointer;
}

.checkbox-component-checked {
    background-color: var(--color-primary-button-bg);
}

.checkbox-component > img {
    width: 100%;
    height: 100%;
    filter: var(--color-fig-on-bg-filter);
}