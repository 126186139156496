.profile-page-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    background-color: var(--color-surface-default);
}

.profile-page-content {
    height: 100%;
    width: 1080px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 88px;

    border-left: 1px solid var(--color-border-default);
    border-right: 1px solid var(--color-border-default);
}

// Using this instead of padding on the parent to make the responsiveness easier to manage
.profile-page-content > * {
    width: 640px;
}

.profile-page-header,
.profile-page-info,
.profile-page-preferences {
    display: flex;
    flex-direction: column;
    padding: 32px 0px;
}

.profile-page-password,
.profile-page-billing,
.profile-page-delete-account {
    display: flex;
    flex-direction: column;
    margin: 32px 0px;
    border-bottom: 1px solid var(--color-border-default);
}

.profile-page-header {
    gap: 12px;
    border-bottom: 1px solid var(--color-border-default);
}

.profile-page-info > p,
.profile-page-password > p,
.profile-page-preferences > p,
.profile-page-billing > p,
.profile-page-delete-account > p {
    margin-bottom: 12px;
}

.profile-page-info-row,
.profile-page-password-row,
.profile-page-preferences-row,
.profile-page-billing-row,
.profile-page-delete-account-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border-default);
}

.profile-page-info-row {
    padding: 22px 0px;
}

.profile-page-info input,
.profile-page-change-password-dialog-content input,
.profile-page-delete-account-dialog-content input {
    background-color: var(--color-input-bg-default);
    color: var(--color-fig-default);
    border: 1px solid var(--color-input-border-default);
    border-radius: 6px;
    height: 32px;
    width: 200px;
    padding: 8px 12px;
}

.profile-page-change-password-dialog-content input,
.profile-page-delete-account-dialog-content input {
    width: 100%;
}

.profile-page-password-row,
.profile-page-preferences-row,
.profile-page-billing-row,
.profile-page-delete-account-row {
    padding: 16px 0px;
}

.profie-page-info-button,
.profile-page-preferences-button {
    height: 32px;
    width: 73px;
    margin: 24px 0px;
}

.profile-page-preferences-row-right {
    height: 32px;
    width: 200px;
}

.profile-page-password-row-button,
.profile-page-billing-row-right {
    width: 144px;
    height: 32px;
    margin-left: 32px;
    flex-shrink: 0;
}

.profile-page-delete-account-row-right {
    width: 153px;
    height: 32px;
    margin-left: 32px;
    flex-shrink: 0;
}

.profile-page-change-password-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 24px; 
}

.profile-page-change-password-dialog-content-row > p {
    margin-bottom: 4px;
}

.profile-page-change-password-dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.profile-page-change-password-dialog-footer > div {
    height: 32px;
}

.profile-page-change-password-dialog-footer > div:first-of-type {
    width: 70px;
}

.profile-page-change-password-dialog-footer > div:nth-of-type(2) {
    width: 144px;
}

.profile-page-delete-account-dialog-content > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 24px;
}

.profile-page-delete-account-dialog-content > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 24px;
}

.profile-page-delete-account-dialog-content > div:nth-of-type(3) {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-grow: 1;
}

.profile-page-delete-account-dialog-content-checkbox-wrapper {
    width: 12px;
    height: 12px;
    margin: 2px;
    flex-shrink: 0;
}

.profile-page-delete-account-dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.profile-page-delete-account-dialog-footer > div {
    height: 32px;
    width: 153px;
}

// The 640 width of the content plus 16 padding on each side
@media only screen and (max-width: 672px) {

    .profile-page-content {
        width: 100%;
        padding: 0px 16px 88px 16px;
    }
    
    // Using this instead of padding on the parent to make the responsiveness easier to manage
    .profile-page-content > * {
        width: 100%;
    }

    .profile-page-info-row {
        flex-wrap: wrap;
        gap: 4px;
    }

    .profile-page-password-row,
    .profile-page-preferences-row {
        flex-wrap: wrap;
        gap: 12px;
    }

    .profile-page-billing-row,
    .profile-page-delete-account-row {
        flex-wrap: wrap;
        gap: 16px;
    }

    .profile-page-info-row > *,
    .profile-page-password-row > *,
    .profile-page-preferences-row > *,
    .profile-page-billing-row > *,
    .profile-page-delete-account-row > * {
        width: 100% !important;
    }

    .profile-page-password-row-button,
    .profile-page-billing-row-right,
    .profile-page-delete-account-row-right {
        margin-left: 0;
    }

    .profile-page-info input,
    .profile-page-change-password-dialog-content input,
    .profile-page-delete-account-dialog-content input {
        height: 40px;
    }

    .profie-page-info-button,
    .profile-page-preferences-button {
        height: 40px;
    }

    .profile-page-preferences-row-right {
        height: 40px;
    }

    .profile-page-password-row-button,
    .profile-page-billing-row-right {
        height: 40px;
    }

    .profile-page-delete-account-row-right {
        height: 40px;
    }

    .profile-page-change-password-dialog-footer > div {
        height: 40px;
    }

    .profile-page-delete-account-dialog-footer > div {
        height: 40px;
    }

}