.theme-light {
	// https://codepen.io/sosuke/pen/Pjoqqp
	// Loss: 0.1
	--color-fig-default-filter: invert(10%) sepia(16%) saturate(506%) hue-rotate(169deg) brightness(92%) contrast(94%);
	// Loss: 0.0
	--color-fig-subtle-filter: invert(37%) sepia(5%) saturate(745%) hue-rotate(182deg) brightness(96%) contrast(80%);
	// Loss: 0.1
	--color-fig-disabled-filter: invert(86%) sepia(6%) saturate(236%) hue-rotate(196deg) brightness(99%) contrast(87%);
	// Loss: 0.1
	--color-fig-accent-filter: invert(31%) sepia(65%) saturate(2125%) hue-rotate(215deg) brightness(92%) contrast(87%);
	// Loss: 0.1
	--color-badge-fig-filter: invert(32%) sepia(55%) saturate(1690%) hue-rotate(206deg) brightness(88%) contrast(92%);
	// Loss 0.1
	--color-border-default-filter: invert(93%) sepia(10%) saturate(106%) hue-rotate(201deg) brightness(95%) contrast(87%);
	// Loss 0.0
	--color-fig-on-bg-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(221deg) brightness(108%) contrast(101%);
	// Loss 0.0
	--color-banner-fig-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(101%) contrast(104%);
	// Loss 0.0
	--color-fig-positive-filter: invert(47%) sepia(46%) saturate(579%) hue-rotate(100deg) brightness(94%) contrast(93%);
	// Loss 2.1
	--color-fig-negative-filter: invert(28%) sepia(100%) saturate(1221%) hue-rotate(330deg) brightness(106%) contrast(84%);

	// Loss 0.1
	--color-blue-primary-filter: invert(30%) sepia(42%) saturate(3250%) hue-rotate(192deg) brightness(96%) contrast(90%);
	// Loss 0.0
	--color-violet-primary-filter: invert(34%) sepia(89%) saturate(663%) hue-rotate(218deg) brightness(82%) contrast(90%);
	// Loss 0.0
	--color-teal-primary-filter: invert(41%) sepia(80%) saturate(6872%) hue-rotate(161deg) brightness(93%) contrast(101%);
	// Loss 0.3
	--color-purple-primary-filter: invert(29%) sepia(38%) saturate(2395%) hue-rotate(247deg) brightness(96%) contrast(86%);
	// Loss 0.0
	--color-amber-primary-filter: invert(36%) sepia(20%) saturate(4846%) hue-rotate(20deg) brightness(99%) contrast(102%);
	// Loss 0.0
	--color-orange-primary-filter: invert(32%) sepia(90%) saturate(1009%) hue-rotate(356deg) brightness(96%) contrast(110%);
	// Loss 0.0
	--color-crimson-primary-filter: invert(22%) sepia(48%) saturate(4129%) hue-rotate(328deg) brightness(88%) contrast(93%);
	// Loss 0.0
	--color-pink-primary-filter: invert(24%) sepia(31%) saturate(5786%) hue-rotate(301deg) brightness(91%) contrast(90%);
	// Loss 0.1
	--color-green-primary-filter: invert(37%) sepia(44%) saturate(692%) hue-rotate(102deg) brightness(99%) contrast(85%);
	// Loss 0.7
	--color-red-primary-filter: invert(23%) sepia(30%) saturate(4912%) hue-rotate(337deg) brightness(104%) contrast(98%);
	// Loss 0.1
	--color-cyan-primary-filter: invert(33%) sepia(95%) saturate(4256%) hue-rotate(173deg) brightness(89%) contrast(87%);
	// Loss 0.1
	--color-iris-primary-filter: invert(32%) sepia(95%) saturate(800%) hue-rotate(214deg) brightness(87%) contrast(94%);

    --color-surface-default: rgba(255,255,255,1);
	--color-surface-subtle: rgba(249,249,251,1);
	--color-surface-hover: rgba(240,240,243,1);
	--color-surface-pressed: rgba(232,232,236,1);
	--color-surface-positive: rgba(244,251,246,1);
	--color-surface-negative: rgba(255,247,247,1);
	--color-surface-sorted: rgba(247,249,255,1);
	--color-fig-default: rgba(28,32,36,1);
	--color-fig-subtle: rgba(96,100,108,1);
	--color-fig-disabled: rgba(205,206,214,1);
	--color-fig-on-bg: rgba(255,255,255,1);
	--color-fig-accent: rgba(62,99,221,1);
	--color-fig-positive: rgba(41,151,100,1);
	--color-fig-negative: rgba(229,72,77,1);
	--color-primary-button-bg: rgba(62,99,221,1);
	--color-primary-button-hover: rgba(51,88,212,1);
	--color-primary-button-pressed: rgba(58,91,199,1);
	--color-primary-button-disabled: rgba(240,240,243,1);
	--color-default-button-bg-default: rgba(255,255,255,1);
	--color-default-button-bg-hover: rgba(240,240,243,1);
	--color-default-button-bg-pressed: rgba(232,232,236,1);
	--color-default-button-bg-disabled: rgba(251,252,253,1);
	--color-default-button-border-default: rgba(205,206,214,1);
	--color-default-button-border-disabled: rgba(224,225,230,1);
	--color-ghost-button-hover: rgba(237,242,254,1);
	--color-ghost-button-pressed: rgba(210,222,255,1);
	--color-ghost-button-fig: rgba(62,99,221,1);
	--color-destructive-button-bg: rgba(229,72,77,1);
	--color-destructive-button-hover: rgba(220,62,66,1);
	--color-destructive-button-pressed: rgba(100,23,35,1);
	--color-destructive-button-disabled: rgba(240,240,243,1);
	--color-opposite-border-default: rgba(54,58,63,1);
	--color-border-default: rgba(217,217,224,1);
	--color-border-positive: rgba(173,221,192,1);
	--color-border-negative: rgba(253,189,190,1);
	--color-input-bg-default: rgba(255,255,255,1);
	--color-input-bg-hover: rgba(240,240,243,1);
	--color-input-border-default: rgba(205,206,214,1);
	--color-input-border-focus: rgba(62,99,221,1);
	--color-input-border-positive: rgba(41,151,100,1);
	--color-input-border-negative: rgba(229,72,77,1);
	--color-input-border-disabled: rgba(224,225,230,1);
	--color-segmented-control-default: rgba(240,240,243,1);
	--color-segmented-control-pressed: rgba(255,255,255,1);
	--color-chip-bg: rgba(237,242,254,1);
	--color-chip-fig: rgba(58,91,199,1);
	--color-badge-bg: rgba(247,249,255,1);
	--color-badge-subtle: rgba(225,233,255,1);
	--color-badge-border: rgba(171,189,249,1);
	--color-badge-fig: rgba(31,45,92,1);
	--color-tooltip-bg: rgba(17,17,19,1);
	--color-avatar-01: rgba(255,197,61,1);
	--color-avatar-02: rgba(247,107,21,1);
	--color-avatar-03: rgba(229,70,102,1);
	--color-avatar-04: rgba(142,78,198,1);
	--color-avatar-05: rgba(110,86,207,1);
	--color-avatar-06: rgba(0,144,255,1);
	--color-avatar-07: rgba(18,165,148,1);
	--color-logo-bg: rgba(17,24,39,1);
	--color-banner-surface: rgba(28, 32, 36, 1);
	--color-banner-fig: rgba(255, 255, 255, 1);
	--color-amber-primary: rgba(171,100,0,1);
	--color-amber-secondary: rgba(254,251,233,1);
	--color-blue-primary: rgba(13,116,206,1);
	--color-blue-secondary: rgba(244,250,255,1);
	--color-crimson-primary: rgba(202,36,77,1);
	--color-crimson-secondary: rgba(255,247,248,1);
	--color-green-primary: rgba(33,131,88,1);
	--color-green-secondary: rgba(244,251,246,1);
	--color-orange-primary: rgba(204,78,0,1);
	--color-orange-secondary: rgba(255,247,237,1);
	--color-pink-primary: rgba(194,41,138,1);
	--color-pink-secondary: rgba(254,247,251,1);
	--color-purple-primary: rgba(129,69,181,1);
	--color-purple-secondary: rgba(251,247,254,1);
	--color-teal-primary: rgba(0,133,115,1);
	--color-teal-secondary: rgba(243,251,249,1);
	--color-violet-primary: rgba(101,80,185,1);
	--color-violet-secondary: rgba(250,248,255,1);
	--color-red-primary: rgba(206, 44, 49, 1);
	--color-red-secondary: rgba(255, 247, 247, 1);
	--color-cyan-primary: rgba(16,125,152,1);
	--color-cyan-secondary: rgba(242,250,251,1);
	--color-iris-primary: rgba(87,83,198,1);
	--color-iris-secondary: rgba(248,248,255,1);
	--color-fig-primary: rgba(28,32,36,1);
	--color-fig-secondary: rgba(96,100,108,1);

	// Extra colors not in the main styles
	// Needed for other purposes such as coloring svgs
	--color-icon-1: rgba(141, 164, 239, 1);
	--color-icon-2: rgba(58,91,199,1);
}

.theme-dark {
	// https://codepen.io/sosuke/pen/Pjoqqp
	// Loss: 0.2
	// --color-fig-default-filter: invert(100%) sepia(9%) saturate(1324%) hue-rotate(177deg) brightness(99%) contrast(89%);
	// Loss: 0.1
	--color-fig-default-filter: invert(97%) sepia(6%) saturate(53%) hue-rotate(182deg) brightness(96%) contrast(97%);
	// Loss: 0.0
	--color-fig-subtle-filter: invert(80%) sepia(6%) saturate(241%) hue-rotate(177deg) brightness(93%) contrast(80%);
	// Loss: 0.0
	--color-fig-disabled-filter: invert(27%) sepia(7%) saturate(555%) hue-rotate(173deg) brightness(95%) contrast(94%);
	// Loss: 0.1
	--color-fig-accent-filter: invert(71%) sepia(41%) saturate(1268%) hue-rotate(192deg) brightness(100%) contrast(103%);
	// Loss: 0.0
	--color-badge-fig-filter: invert(79%) sepia(39%) saturate(175%) hue-rotate(191deg) brightness(102%) contrast(102%);
	// Loss 0.0
	--color-border-default-filter: invert(22%) sepia(7%) saturate(628%) hue-rotate(173deg) brightness(96%) contrast(94%);
	// Loss 0.0
	--color-fig-on-bg-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(221deg) brightness(108%) contrast(101%);
	// Loss 0.0
	--color-banner-fig-filter: invert(10%) sepia(31%) saturate(277%) hue-rotate(169deg) brightness(92%) contrast(93%);
	// Loss 0.0
	--color-fig-positive-filter: invert(47%) sepia(46%) saturate(579%) hue-rotate(100deg) brightness(94%) contrast(93%);
	// Loss 2.1
	--color-fig-negative-filter: invert(28%) sepia(100%) saturate(1221%) hue-rotate(330deg) brightness(106%) contrast(84%);

	// Loss 0.0
	--color-blue-primary-filter: invert(63%) sepia(24%) saturate(1160%) hue-rotate(179deg) brightness(105%) contrast(101%);
	// Loss 0.0
	--color-violet-primary-filter: invert(70%) sepia(99%) saturate(3459%) hue-rotate(212deg) brightness(121%) contrast(105%);
	// Loss 0.1
	--color-teal-primary-filter: invert(78%) sepia(29%) saturate(6640%) hue-rotate(121deg) brightness(99%) contrast(91%);
	// Loss 0.0
	--color-purple-primary-filter: invert(60%) sepia(55%) saturate(361%) hue-rotate(225deg) brightness(102%) contrast(104%);
	// Loss 0.0
	--color-amber-primary-filter: invert(81%) sepia(73%) saturate(1797%) hue-rotate(340deg) brightness(100%) contrast(105%);
	// Loss 0.0
	--color-orange-primary-filter: invert(69%) sepia(25%) saturate(2368%) hue-rotate(325deg) brightness(115%) contrast(103%);
	// Loss 0.0
	--color-crimson-primary-filter: invert(87%) sepia(15%) saturate(4638%) hue-rotate(293deg) brightness(100%) contrast(101%);
	// Loss 0.0
	--color-pink-primary-filter: invert(77%) sepia(28%) saturate(1278%) hue-rotate(286deg) brightness(101%) contrast(101%);
	// Loss 0.1
	--color-green-primary-filter: invert(66%) sepia(91%) saturate(341%) hue-rotate(95deg) brightness(94%) contrast(84%);
	// Loss 0.6
	--color-red-primary-filter: invert(91%) sepia(26%) saturate(5927%) hue-rotate(302deg) brightness(103%) contrast(102%);
	// Loss 0.1
	--color-cyan-primary-filter: invert(80%) sepia(11%) saturate(2558%) hue-rotate(152deg) brightness(91%) contrast(98%);
	// Loss 0.0
	--color-iris-primary-filter: invert(77%) sepia(49%) saturate(3830%) hue-rotate(201deg) brightness(105%) contrast(103%);
	
    --color-surface-default: rgba(17,17,19,1);
	--color-surface-subtle: rgba(24,25,27,1);
	--color-surface-hover: rgba(33,34,37,1);
	--color-surface-pressed: rgba(39,42,45,1);
	--color-surface-positive: rgba(18,27,23,1);
	--color-surface-negative: rgba(32,19,20,1);
	--color-surface-sorted: rgba(20,23,38,1);
	--color-fig-default: rgba(237,238,240,1);
	--color-fig-subtle: rgba(176,180,186,1);
	--color-fig-disabled: rgba(67,72,78,1);
	--color-fig-on-bg: rgba(255,255,255,1);
	--color-fig-accent: rgba(158,177,255,1);
	--color-fig-positive: rgba(41,151,100,1);
	--color-fig-negative: rgba(229,72,77,1);
	--color-primary-button-bg: rgba(62,99,221,1);
	--color-primary-button-hover: rgba(84,114,228,1);
	--color-primary-button-pressed: rgba(158,177,255,1);
	--color-primary-button-disabled: rgba(33,34,37,1);
	--color-default-button-bg-default: rgba(17,17,19,1);
	--color-default-button-bg-hover: rgba(33,34,37,1);
	--color-default-button-bg-pressed: rgba(39,42,45,1);
	--color-default-button-bg-disabled: rgba(25,25,25,1);
	--color-default-button-border-default: rgba(67,72,78,1);
	--color-default-button-border-disabled: rgba(46,49,53,1);
	--color-ghost-button-hover: rgba(24,36,73,1);
	--color-ghost-button-pressed: rgba(37,57,116,1);
	--color-ghost-button-fig: rgba(158,177,255,1);
	--color-destructive-button-bg: rgba(229,72,77,1);
	--color-destructive-button-hover: rgba(236,93,94,1);
	--color-destructive-button-pressed: rgba(255,149,146,1);
	--color-destructive-button-disabled: rgba(33,34,37,1);
	--color-opposite-border-default: rgba(217,217,224,1);
	--color-border-default: rgba(54,58,63,1);
	--color-border-positive: rgba(32,87,62,1);
	--color-border-negative: rgba(114,35,45,1);
	--color-input-bg-default: rgba(17,17,19,1);
	--color-input-bg-hover: rgba(33,34,37,1);
	--color-input-border-default: rgba(67,72,78,1);
	--color-input-border-focus: rgba(62,99,221,1);
	--color-input-border-positive: rgba(41,151,100,1);
	--color-input-border-negative: rgba(229,72,77,1);
	--color-input-border-disabled: rgba(46,49,53,1);
	--color-segmented-control-default: rgba(33,34,37,1);
	--color-segmented-control-pressed: rgba(17,17,19,1);
	--color-chip-bg: rgba(24,36,73,1);
	--color-chip-fig: rgba(158,177,255,1);
	--color-badge-bg: rgba(20,23,38,1);
	--color-badge-subtle: rgba(24,36,73,1);
	--color-badge-border: rgba(58,79,151,1);
	--color-badge-fig: rgba(214,225,255,1);
	--color-tooltip-bg: rgba(252,252,253,1);
	--color-avatar-01: rgba(255,197,61,1);
	--color-avatar-02: rgba(247,107,21,1);
	--color-avatar-03: rgba(229,70,102,1);
	--color-avatar-04: rgba(142,78,198,1);
	--color-avatar-05: rgba(110,86,207,1);
	--color-avatar-06: rgba(0,144,255,1);
	--color-avatar-07: rgba(18,165,148,1);
	--color-logo-bg: rgba(255,255,255,1);
	--color-banner-surface: rgba(237, 238, 240, 1);
	--color-banner-fig: rgba(28, 32, 36, 1);
	--color-amber-primary: rgba(255,202,22,1);
	--color-amber-secondary: rgba(48,32,8,1);
	--color-blue-primary: rgba(112,184,255,1);
	--color-blue-secondary: rgba(13,40,71,1);
	--color-crimson-primary: rgba(255,146,173,1);
	--color-crimson-secondary: rgba(56,21,37,1);
	--color-green-primary: rgba(61,214,140,1);
	--color-green-secondary: rgba(19,45,33,1);
	--color-orange-primary: rgba(255,160,87,1);
	--color-orange-secondary: rgba(51,30,11,1);
	--color-pink-primary: rgba(255,141,204,1);
	--color-pink-secondary: rgba(55,23,47,1);
	--color-purple-primary: rgba(209,157,255,1);
	--color-purple-secondary: rgba(48,28,59,1);
	--color-teal-primary: rgba(11,216,182,1);
	--color-teal-secondary: rgba(13,45,42,1);
	--color-violet-primary: rgba(186,167,255,1);
	--color-violet-secondary: rgba(41,31,67,1);
	--color-red-primary: rgba(255, 149, 146, 1);
	--color-red-secondary: rgba(59, 18, 25, 1);
	--color-cyan-primary: rgba(76,204,230,1);
	--color-cyan-secondary: rgba(8,44,54,1);
	--color-iris-primary: rgba(177,169,255,1);
	--color-iris-secondary: rgba(32,34,72,1);
	--color-fig-primary: rgba(237,238,240,1);
	--color-fig-secondary: rgba(204,206,210,1);
	--color-fig-highlight: rgba(255,255,255,1);

	// Extra colors not in the main styles
	// Needed for other purposes such as coloring svgs
	--color-icon-1: rgba(67, 93, 177, 1);
	--color-icon-2: rgba(158,177,255,1);
}

* {
    --color-helper-text-default: rgba(34,34,34,1);
	--shadow-small: 0px 1px 0px 0px rgba(17, 24, 28, 0.1);
	--shadow-large: 0px 4px 6px 0px rgba(17, 24, 28, 0.09);
	--color-fig-light-primary: rgba(28,32,36,1);
	--color-fig-light-secondary: rgba(96,100,108,1);
	--color-fig-dark-primary: rgba(237,238,240,1);
	--color-fig-dark-secondary: rgba(204,206,210,1);
	--color-fig-dark-highlight: rgba(255,255,255,1);
	// Copied from .theme-light above
	--color-fig-light-default-filter: invert(10%) sepia(16%) saturate(506%) hue-rotate(169deg) brightness(92%) contrast(94%);
	// COpied from .theme-dark above
	--color-fig-dark-default-filter: invert(97%) sepia(6%) saturate(53%) hue-rotate(182deg) brightness(96%) contrast(97%);
}

a, a:visited, a:hover, a:active {
	text-decoration: none !important;
	background-color: transparent;
	color: initial;
}

/**
	Some of the more common colors used for text based on the variables above
*/
.color-fig-default {
	color: var(--color-fig-default);
}

.color-fig-subtle {
	color: var(--color-fig-subtle);
}

.color-fig-negative {
	color: var(--color-fig-negative);
}

.color-fig-accent, a.color-fig-accent {
	color: var(--color-fig-accent);
}

.color-fig-light-primary {
	color: var(--color-fig-light-primary);
}

.color-fig-light-secondary {
	color: var(--color-fig-light-secondary);
}

.color-fig-dark-primary {
	color: var(--color-fig-dark-primary);
}

.color-fig-dark-secondary {
	color: var(--color-fig-dark-secondary);
}

.color-fig-dark-highlight {
	color: var(--color-fig-dark-highlight);
}

/**
	Pre-defined text styles
*/
.text-style-h-1-normal {
	font-size: 30px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 36px;
	letter-spacing: -0.64px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-1-medium {
	font-size: 30px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 36px;
	letter-spacing: -0.64px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-1-semibold {
	font-size: 30px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 36px;
	letter-spacing: -0.64px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-1-bold {
	font-size: 30px;
	font-family: Inter;
	font-weight: 700;
	font-style: normal;
	line-height: 36px;
	letter-spacing: -0.64px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-2-normal {
	font-size: 24px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 32px;
	letter-spacing: -0.47px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-2-medium {
	font-size: 24px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 32px;
	letter-spacing: -0.47px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-2-semibold {
	font-size: 24px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 32px;
	letter-spacing: -0.47px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-2-bold {
	font-size: 24px;
	font-family: Inter;
	font-weight: 700;
	font-style: normal;
	line-height: 32px;
	letter-spacing: -0.47px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-3-normal {
	font-size: 20px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 28px;
	letter-spacing: -0.33px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-3-medium {
	font-size: 20px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 28px;
	letter-spacing: -0.33px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-3-semibold {
	font-size: 20px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 28px;
	letter-spacing: -0.33px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-3-bold {
	font-size: 20px;
	font-family: Inter;
	font-weight: 700;
	font-style: normal;
	line-height: 28px;
	letter-spacing: -0.33px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-4-normal {
	font-size: 18px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-4-medium {
	font-size: 18px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-4-semibold {
	font-size: 18px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-4-bold {
	font-size: 18px;
	font-family: Inter;
	font-weight: 700;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: none;
}
.text-style-h-4-uppercase-normal {
	font-size: 18px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-h-4-uppercase-medium {
	font-size: 18px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-h-4-uppercase-semibold {
	font-size: 18px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.26px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-normal {
	font-size: 16px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-medium {
	font-size: 16px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-semibold {
	font-size: 16px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-bold {
	font-size: 16px;
	font-family: Inter;
	font-weight: 700;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-tabular-normal {
	font-size: 16px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-tabular-medium {
	font-size: 16px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-tabular-semibold {
	font-size: 16px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: none;
}
.text-style-body-underline-normal {
	font-size: 16px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-body-underline-medium {
	font-size: 16px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-body-underline-semibold {
	font-size: 16px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-body-uppercase-normal {
	font-size: 16px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-uppercase-medium {
	font-size: 16px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-uppercase-semibold {
	font-size: 16px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.18px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-label-normal {
	font-size: 14px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: none;
}
.text-style-label-medium {
	font-size: 14px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: none;
}
.text-style-label-semibold {
	font-size: 14px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: none;
}
.text-style-label-tabular-medium {
	font-size: 14px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: none;
}
.text-style-label-underline-normal {
	font-size: 14px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-label-underline-medium {
	font-size: 14px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-label-underline-semibold {
	font-size: 14px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-label-uppercase-normal {
	font-size: 14px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-label-uppercase-medium {
	font-size: 14px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-label-uppercase-semibold {
	font-size: 14px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-label-locked-normal {
	font-size: 14px;
	font-family: Flow Rounded;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	letter-spacing: -0.09px;
	text-decoration: none;
	text-transform: none;
}
.text-style-caption-normal {
	font-size: 12px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: none;
}
.text-style-caption-medium {
	font-size: 12px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: none;
}
.text-style-caption-semibold {
	font-size: 12px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: none;
}
.text-style-caption-tabular-medium {
	font-size: 12px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: none;
}
.text-style-caption-underline-normal {
	font-size: 12px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-caption-underline-medium {
	font-size: 12px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-caption-underline-semibold {
	font-size: 12px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: underline;
	text-transform: none;
}
.text-style-caption-uppercase-normal {
	font-size: 12px;
	font-family: Inter;
	font-weight: 400;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-caption-uppercase-medium {
	font-size: 12px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-caption-uppercase-semibold {
	font-size: 12px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: uppercase;
}

// Text styles for cheatsheets start
.text-style-title-01 {
	font-size: 64px;
	font-family: Inter;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-02 {
	font-size: 64px;
	font-family: Space Mono;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-03 {
	font-size: 56px;
	font-family: Roboto Slab;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-04 {
	font-size: 88px;
	font-family: Bebas Neue;
	font-weight: 700;
	font-style: normal;
	line-height: 104px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-title-05 {
	font-size: 56px;
	font-family: Fira Mono;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-title-06 {
	font-size: 64px;
	font-family: Poppins;
	font-weight: 700;
	font-style: normal;
	line-height: 72px;
	letter-spacing: -0.005em;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-07 {
	font-size: 64px;
	font-family: Satoshi;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-08 {
	font-size: 64px;
	font-family: Cabinet Grotesk;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-09 {
	font-size: 64px;
	font-family: Sentient;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-10 {
	font-size: 64px;
	font-family: Switzer;
	font-weight: 400;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-11 {
	font-size: 64px;
	font-family: Figtree;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-12 {
	font-size: 64px;
	font-family: Public Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-13 {
	font-size: 64px;
	font-family: Sofia Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-14 {
	font-size: 64px;
	font-family: Noto Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-15 {
	font-size: 64px;
	font-family: Rubik;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-16 {
	font-size: 64px;
	font-family: DM Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-17 {
	font-size: 64px;
	font-family: Geist;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-18 {
	font-size: 64px;
	font-family: IBM Plex Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-title-19 {
	font-size: 64px;
	font-family: Sora;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-20 {
	font-size: 64px;
	font-family: Open Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-21 {
	font-size: 64px;
	font-family: Domine;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-22 {
	font-size: 64px;
	font-family: Be Vietnam Pro;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-23 {
	font-size: 64px;
	font-family: Bree Serif;
	font-weight: 400;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-24 {
	font-size: 64px;
	font-family: Afacad;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-25 {
	font-size: 64px;
	font-family: Schibsted Grotesk;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-26 {
	font-size: 64px;
	font-family: Plus Jakarta Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-27 {
	font-size: 64px;
	font-family: Oswald;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-28 {
	font-size: 64px;
	font-family: Asap;
	font-weight: 500;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-29 {
	font-size: 64px;
	font-family: Onest;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-30 {
	font-size: 64px;
	font-family: Geologica;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-31 {
	font-size: 64px;
	font-family: Instrument Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-32 {
	font-size: 64px;
	font-family: Maven Pro;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-33 {
	font-size: 64px;
	font-family: Pontano Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-34 {
	font-size: 64px;
	font-family: Monda;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-35 {
	font-size: 64px;
	font-family: Radio Canada;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-36 {
	font-size: 64px;
	font-family: Reddit Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-37 {
	font-size: 64px;
	font-family: Prompt;
	font-weight: 500;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-38 {
	font-size: 64px;
	font-family: Hind;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-39 {
	font-size: 64px;
	font-family: Lato;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-41 {
	font-size: 64px;
	font-family: Libre Franklin;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-42 {
	font-size: 64px;
	font-family: Gothic A1;
	font-weight: 800;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-43 {
	font-size: 64px;
	font-family: Aleo;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-44 {
	font-size: 64px;
	font-family: Anuphan;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-45 {
	font-size: 64px;
	font-family: Archivo;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1.43px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-46 {
	font-size: 64px;
	font-family: Alexandria;
	font-weight: 500;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-47 {
	font-size: 64px;
	font-family: Anaheim;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-48 {
	font-size: 64px;
	font-family: Bitter;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-49 {
	font-size: 64px;
	font-family: Bricolage Grotesque;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-50 {
	font-size: 64px;
	font-family: Heebo;
	font-weight: 600;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-51 {
	font-size: 64px;
	font-family: Frank Ruhl Libre;
	font-weight: 700;
	font-style: normal;
	line-height: 78px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-01 {
	font-size: 32px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 12px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-02 {
	font-size: 32px;
	font-family: Space Mono;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-03 {
	font-size: 32px;
	font-family: Roboto Slab;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-04 {
	font-size: 48px;
	font-family: Bebas Neue;
	font-weight: 400;
	font-style: normal;
	line-height: 48px;
	letter-spacing: 8px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-05 {
	font-size: 40px;
	font-family: Fira Mono;
	font-weight: 400;
	font-style: normal;
	line-height: 48px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-06 {
	font-size: 40px;
	font-family: Poppins;
	font-weight: 500;
	font-style: normal;
	line-height: 48px;
	letter-spacing: -0.005em;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-07 {
	font-size: 32px;
	font-family: Satoshi;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
}
// TODO: the grotesk ones might need changing or get an online resource 
// THere are some grotesk ones from google fonts but doesn't seem like it's this
.text-style-subtitle-08 {
	font-size: 40px;
	font-family: Cabinet Grotesk;
	font-weight: 500;
	font-style: normal;
	line-height: 48px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-09 {
	font-size: 32px;
	font-family: Sentient;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
// TODO: online stylesheet
.text-style-subtitle-10 {
	font-size: 32px;
	font-family: Switzer;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 8px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-11 {
	font-size: 32px;
	font-family: Figtree;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-12 {
	font-size: 32px;
	font-family: Public Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-13 {
	font-size: 32px;
	font-family: Sofia Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-14 {
	font-size: 32px;
	font-family: Noto Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 4px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-15 {
	font-size: 32px;
	font-family: Rubik;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-16 {
	font-size: 32px;
	font-family: DM Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-17 {
	font-size: 32px;
	font-family: Geist;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-18 {
	font-size: 32px;
	font-family: IBM Plex Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-19 {
	font-size: 32px;
	font-family: Sora;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-20 {
	font-size: 32px;
	font-family: Open Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-21 {
	font-size: 32px;
	font-family: Domine;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-22 {
	font-size: 32px;
	font-family: Be Vietnam Pro;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 4px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-23 {
	font-size: 32px;
	font-family: Bree Serif;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-24 {
	font-size: 36px;
	font-family: Afacad;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-25 {
	font-size: 32px;
	font-family: Schibsted Grotesk;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-26 {
	font-size: 32px;
	font-family: Plus Jakarta Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-27 {
	font-size: 32px;
	font-family: Oswald;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-28 {
	font-size: 32px;
	font-family: Asap;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-29 {
	font-size: 32px;
	font-family: Onest;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-30 {
	font-size: 32px;
	font-family: Geologica;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-31 {
	font-size: 32px;
	font-family: Instrument Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-32 {
	font-size: 32px;
	font-family: Maven Pro;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-33 {
	font-size: 32px;
	font-family: Pontano Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-34 {
	font-size: 32px;
	font-family: Monda;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 4px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-35 {
	font-size: 32px;
	font-family: Radio Canada;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-36 {
	font-size: 32px;
	font-family: Reddit Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-37 {
	font-size: 32px;
	font-family: Prompt;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-38 {
	font-size: 32px;
	font-family: Hind;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-39 {
	font-size: 32px;
	font-family: Lato;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-41 {
	font-size: 32px;
	font-family: Libre Franklin;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-42 {
	font-size: 32px;
	font-family: Gothic A1;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-43 {
	font-size: 32px;
	font-family: Aleo;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-44 {
	font-size: 32px;
	font-family: Anuphan;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-45 {
	font-size: 32px;
	font-family: Archivo;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-46 {
	font-size: 32px;
	font-family: Alexandria;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-47 {
	font-size: 32px;
	font-family: Anaheim;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-48 {
	font-size: 32px;
	font-family: Bitter;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-subtitle-49 {
	font-size: 32px;
	font-family: Bricolage Grotesque;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-50 {
	font-size: 32px;
	font-family: Heebo;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-subtitle-51 {
	font-size: 32px;
	font-family: Frank Ruhl Libre;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-01 {
	font-size: 32px;
	font-family: Inter;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-02 {
	font-size: 32px;
	font-family: Space Grotesk;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-03 {
	font-size: 32px;
	font-family: Roboto Slab;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-04 {
	font-size: 40px;
	font-family: Bebas Neue;
	font-weight: 700;
	font-style: normal;
	line-height: 48px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-primary-05 {
	font-size: 32px;
	font-family: Fira Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-06 {
	font-size: 32px;
	font-family: Poppins;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-07 {
	font-size: 32px;
	font-family: Satoshi;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-08 {
	font-size: 32px;
	font-family: Cabinet Grotesk;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
// TODO: online css
.text-style-body-primary-09 {
	font-size: 32px;
	font-family: Sentient;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-10 {
	font-size: 32px;
	font-family: Switzer;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-11 {
	font-size: 32px;
	font-family: Figtree;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-primary-12 {
	font-size: 32px;
	font-family: Public Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-13 {
	font-size: 36px;
	font-family: Sofia Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-14 {
	font-size: 32px;
	font-family: Noto Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-15 {
	font-size: 32px;
	font-family: Rubik;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-primary-16 {
	font-size: 32px;
	font-family: DM Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-17 {
	font-size: 32px;
	font-family: Geist;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-18 {
	font-size: 32px;
	font-family: IBM Plex Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-19 {
	font-size: 32px;
	font-family: Sora;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-20 {
	font-size: 32px;
	font-family: Open Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-21 {
	font-size: 32px;
	font-family: Domine;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-22 {
	font-size: 32px;
	font-family: Be Vietnam Pro;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-23 {
	font-size: 32px;
	font-family: Bree Serif;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-24 {
	font-size: 34px;
	font-family: Afacad;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-25 {
	font-size: 32px;
	font-family: Schibsted Grotesk;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-26 {
	font-size: 28px;
	font-family: Plus Jakarta Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-27 {
	font-size: 32px;
	font-family: Oswald;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-28 {
	font-size: 32px;
	font-family: Asap;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-29 {
	font-size: 32px;
	font-family: Onest;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-30 {
	font-size: 32px;
	font-family: Geologica;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-31 {
	font-size: 28px;
	font-family: Plus Jakarta Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-32 {
	font-size: 32px;
	font-family: Maven Pro;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-33 {
	font-size: 32px;
	font-family: Pontano Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-34 {
	font-size: 32px;
	font-family: Monda;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -3px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-35 {
	font-size: 32px;
	font-family: Radio Canada;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-36 {
	font-size: 32px;
	font-family: Reddit Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-37 {
	font-size: 32px;
	font-family: Prompt;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-38 {
	font-size: 32px;
	font-family: Hind;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-39 {
	font-size: 32px;
	font-family: Lato;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-41 {
	font-size: 32px;
	font-family: Libre Franklin;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-42 {
	font-size: 32px;
	font-family: Gothic A1;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-43 {
	font-size: 32px;
	font-family: Aleo;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-44 {
	font-size: 32px;
	font-family: Anuphan;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-45 {
	font-size: 32px;
	font-family: Archivo;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-46 {
	font-size: 32px;
	font-family: Alexandria;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-47 {
	font-size: 36px;
	font-family: Anaheim;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-48 {
	font-size: 32px;
	font-family: Bitter;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-49 {
	font-size: 32px;
	font-family: Bricolage Grotesque;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-50 {
	font-size: 32px;
	font-family: Heebo;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-primary-51 {
	font-size: 32px;
	font-family: Frank Ruhl Libre;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-01 {
	font-size: 32px;
	font-family: Inter;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-02 {
	font-size: 32px;
	font-family: Space Grotesk;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-03 {
	font-size: 32px;
	font-family: Roboto Slab;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-04 {
	font-size: 40px;
	font-family: Bebas Neue;
	font-weight: 400;
	font-style: normal;
	line-height: 48px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-body-secondary-05 {
	font-size: 32px;
	font-family: Fira Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-06 {
	font-size: 32px;
	font-family: Poppins;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	text-decoration: none;
	text-transform: capitalize;
}
// TODO: online css
.text-style-body-secondary-07 {
	font-size: 32px;
	font-family: Satoshi;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-08 {
	font-size: 32px;
	font-family: Cabinet Grotesk;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-09 {
	font-size: 32px;
	font-family: Sentient;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-10 {
	font-size: 32px;
	font-family: Switzer;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-11 {
	font-size: 32px;
	font-family: Figtree;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-12 {
	font-size: 32px;
	font-family: Public Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-13 {
	font-size: 36px;
	font-family: Sofia Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-14 {
	font-size: 32px;
	font-family: Noto Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-15 {
	font-size: 32px;
	font-family: Rubik;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-16 {
	font-size: 32px;
	font-family: DM Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-17 {
	font-size: 32px;
	font-family: Geist;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-18 {
	font-size: 32px;
	font-family: IBM Plex Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-19 {
	font-size: 32px;
	font-family: Sora;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-20 {
	font-size: 32px;
	font-family: Open Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-21 {
	font-size: 32px;
	font-family: Domine;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-22 {
	font-size: 32px;
	font-family: Be Vietnam Pro;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-23 {
	font-size: 32px;
	font-family: Bree Serif;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-24 {
	font-size: 34px;
	font-family: Afacad;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-25 {
	font-size: 32px;
	font-family: Schibsted Grotesk;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-26 {
	font-size: 28px;
	font-family: Plus Jakarta Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-27 {
	font-size: 32px;
	font-family: Oswald;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-28 {
	font-size: 32px;
	font-family: Asap;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-29 {
	font-size: 32px;
	font-family: Onest;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-30 {
	font-size: 32px;
	font-family: Geologica;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-31 {
	font-size: 28px;
	font-family: Instrument Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-32 {
	font-size: 32px;
	font-family: Maven Pro;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-33 {
	font-size: 32px;
	font-family: Pontano Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-34 {
	font-size: 32px;
	font-family: Monda;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -3px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-35 {
	font-size: 32px;
	font-family: Radio Canada;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-36 {
	font-size: 32px;
	font-family: Reddit Sans;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-37 {
	font-size: 32px;
	font-family: Prompt;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-38 {
	font-size: 32px;
	font-family: Hind;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-39 {
	font-size: 32px;
	font-family: Lato;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-41 {
	font-size: 32px;
	font-family: Libre Franklin;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -2px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-42 {
	font-size: 32px;
	font-family: Gothic A1;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-43 {
	font-size: 32px;
	font-family: Aleo;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-44 {
	font-size: 32px;
	font-family: Anuphan;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-45 {
	font-size: 32px;
	font-family: Archivo;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-46 {
	font-size: 32px;
	font-family: Alexandria;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-47 {
	font-size: 36px;
	font-family: Anaheim;
	font-weight: 600;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-48 {
	font-size: 32px;
	font-family: Bitter;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-49 {
	font-size: 32px;
	font-family: Bricolage Grotesque;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-50 {
	font-size: 32px;
	font-family: Heebo;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-body-secondary-51 {
	font-size: 32px;
	font-family: Frank Ruhl Libre;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.69px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-01 {
	font-size: 56px;
	font-family: Inter;
	font-style: italic;
	font-weight: 900;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-02 {
	font-size: 56px;
	font-family: Space Grotesk;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-03 {
	font-size: 56px;
	font-family: Roboto Slab;
	font-weight: 900;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-04 {
	font-size: 96px;
	font-family: Bebas Neue;
	font-weight: 700;
	font-style: normal;
	line-height: 104px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-05 {
	font-size: 56px;
	font-family: Fira Sans;
	font-weight: 900;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-06 {
	font-size: 56px;
	font-family: Poppins;
	font-style: italic;
	font-weight: 900;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-07 {
	font-size: 56px;
	font-family: Satoshi;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-08 {
	font-size: 56px;
	font-family: Cabinet Grotesk;
	font-weight: 900;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-09 {
	font-size: 56px;
	font-family: Sentient;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-10 {
	font-size: 56px;
	font-family: Switzer;
	font-weight: 400;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-11 {
	font-size: 56px;
	font-family: Figtree;
	font-weight: 800;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-12 {
	font-size: 56px;
	font-family: Public Sans;
	font-style: italic;
	font-weight: 600;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-13 {
	font-size: 56px;
	font-family: Sofia Sans;
	font-weight: 500;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-14 {
	font-size: 56px;
	font-family: Noto Sans;
	font-weight: 900;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-15 {
	font-size: 56px;
	font-family: Rubik;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-16 {
	font-size: 56px;
	font-family: DM Sans;
	font-style: italic;
	font-weight: 900;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-17 {
	font-size: 56px;
	font-family: Geist;
	font-weight: 400;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-18 {
	font-size: 56px;
	font-family: IBM Plex Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-19 {
	font-size: 56px;
	font-family: Sora;
	font-weight: 600;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-20 {
	font-size: 56px;
	font-family: Open Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-21 {
	font-size: 56px;
	font-family: Domine;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-22 {
	font-size: 56px;
	font-family: Be Vietnam Pro;
	font-weight: 500;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-23 {
	font-size: 56px;
	font-family: Bree Serif;
	font-weight: 400;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-24 {
	font-size: 56px;
	font-family: Afacad;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-25 {
	font-size: 56px;
	font-family: Schibsted Grotesk;
	font-weight: 600;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-26 {
	font-size: 56px;
	font-family: Plus Jakarta Sans;
	font-style: italic;
	font-weight: 800;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-27 {
	font-size: 56px;
	font-family: Oswald;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-28 {
	font-size: 56px;
	font-family: Asap;
	font-style: italic;
	font-weight: 500;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-29 {
	font-size: 56px;
	font-family: Onest;
	font-weight: 500;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-30 {
	font-size: 56px;
	font-family: Geologica;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-31 {
	font-size: 56px;
	font-family: Instrument Sans;
	font-weight: 600;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-32 {
	font-size: 56px;
	font-family: Maven Pro;
	font-weight: 900;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-33 {
	font-size: 56px;
	font-family: Pontano Sans;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-34 {
	font-size: 56px;
	font-family: Monda;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-35 {
	font-size: 56px;
	font-family: Radio Canada;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-36 {
	font-size: 56px;
	font-family: Reddit Sans;
	font-style: italic;
	font-weight: 800;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-37 {
	font-size: 56px;
	font-family: Prompt;
	font-style: italic;
	font-weight: 600;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-38 {
	font-size: 56px;
	font-family: Hind;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-39 {
	font-size: 56px;
	font-family: Lato;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-41 {
	font-size: 56px;
	font-family: Libre Franklin;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-42 {
	font-size: 56px;
	font-family: Gothic A1;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-43 {
	font-size: 56px;
	font-family: Aleo;
	font-style: italic;
	font-weight: 600;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-44 {
	font-size: 56px;
	font-family: Anuphan;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-45 {
	font-size: 56px;
	font-family: Archivo;
	font-style: italic;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-46 {
	font-size: 56px;
	font-family: Alexandria;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-47 {
	font-size: 56px;
	font-family: Anaheim;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-48 {
	font-size: 56px;
	font-family: Bitter;
	font-weight: 800;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-49 {
	font-size: 56px;
	font-family: Bricolage Grotesque;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-footer-50 {
	font-size: 56px;
	font-family: Heebo;
	font-weight: 700;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: uppercase;
}
.text-style-footer-51 {
	font-size: 56px;
	font-family: Frank Ruhl Libre;
	font-weight: 600;
	font-style: normal;
	line-height: 64px;
	letter-spacing: -1.25px;
	text-decoration: none;
	text-transform: capitalize;
}
.text-style-title-40 {
    font-size: 64px;
    font-family: Libre Bodoni;
    font-weight: 600;
    font-style: normal;
    line-height: 78px;
    letter-spacing: -1.43px;
    text-decoration: none;
    text-transform: capitalize;
}
.text-style-subtitle-40 {
    font-size: 32px;
    font-family: Libre Bodoni;
    font-weight: 400;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: capitalize;
}
.text-style-body-primary-40 {
    font-size: 32px;
    font-family: Libre Bodoni;
    font-weight: 500;
    font-style: normal;
    line-height: 40px;
    letter-spacing: -0.69px;
    text-decoration: none;
    text-transform: capitalize;
}
.text-style-body-secondary-40 {
    font-size: 32px;
    font-family: Libre Bodoni;
    font-weight: 400;
    font-style: normal;
    line-height: 40px;
    letter-spacing: -0.69px;
    text-decoration: none;
    text-transform: capitalize;
}
.text-style-footer-40 {
    font-size: 56px;
    font-family: Libre Bodoni;
    font-weight: 700;
    font-style: normal;
    line-height: 64px;
    letter-spacing: -1.25px;
    text-decoration: none;
    text-transform: capitalize;
}
// Text styles for cheatsheets end

input {
	// By default remove all style of the input as we will style it ourselves
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: hidden;
}

input:focus {
	outline: 2px solid var(--color-input-border-focus);
	border: none !important;
}