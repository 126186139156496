.tab-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.tab-toggle-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 100%;
}

.tab-toggle-container-left > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    /* To compensate for the border shifting content */
    padding-bottom: 1px;
}

.tab-toggle-container-left p {
    padding: 4px 8px;
    color: var(--color-fig-subtle);
    cursor: pointer;
}

.tab-toggle-container-left-selected {
    border-bottom: 1px solid var(--color-fig-accent);
}

.tab-toggle-container-left-selected > p {
    color: var(--color-fig-accent);
}

.tab-toggle-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.tab-toggle-container-right p {
    color: var(--color-ghost-button-fig);
}

@media only screen and (max-width: 576px) {
    .tab-toggle-container-right p {
        display: none;
    }
}