.upcoming-matchups {
    position: sticky;
    top: var(--navbar-height);
    z-index: 10;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 68px;
    background-color: var(--color-surface-default);
    border-bottom: 1px solid var(--color-border-default);
}
.scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll::-webkit-scrollbar {
    display: none;
}

.matchups-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    height: 100%;
    border-right: 1px solid var(--color-border-default);
    box-sizing: border-box;
    cursor: pointer;
}

.matchups-card-selected {
    background-color: var(--color-surface-pressed);
}

.all-matchups-card {
    position: relative;
    height: 100%;
    width: 168px;
    border-right: 1px solid var(--color-border-default);
    cursor: pointer;
}

.all-matchups-card-row {
    position: relative;
    display: block;
    height: 50%;
    width: 168px;
    padding: 0 8px;
}

.all-matchups-card-row > img {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.all-matchups-card-row > p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.all-matchups-card-row:first-of-type > p {
    left: 40px;
}

.matchups-card-row {
    display: flex;
    width: 168px;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 0 8px;
}

.matchups-card-row div {
    width: 50%;
}

.matchups-card-row div:last-child {
    text-align: right;
}

.matchups-card-row > div:first-of-type,
.all-matchups-card-row:first-of-type {
    color: var(--color-fig-default);
}

.matchups-card-row > div:nth-of-type(2),
.all-matchups-card-row:nth-of-type(2) {
    color: var(--color-fig-subtle);
}

.matchups-card:hover,
.all-matchups-card:hover {
    background-color: var(--color-surface-hover);
}

.matchups-card table th {
    padding-left: 10px;
    padding-right: 10px;
}


@media only screen and (min-width: 360px) and (max-width: 767px) {

    .upcoming-matchups {
        height: 60px;
    }

    .all-matchups-card {
        width: 152px;
    }

    .matchups-card-row {
        width: 152px;
    }
}

@media only screen and (min-width:768px) and (max-width:1200px) {

    .upcoming-matchups {
        height: 60px;
    }

    .all-matchups-card {
        width: 152px;
    }

    .matchups-card-row {
        width: 152px;
    }
}