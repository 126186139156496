.search-result-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background-color: var(--color-surface-default);
}

.search-result-results-list {
    width: 432px;
    height: 100%;
    border-right: 1px solid var(--color-border-default);
}

.search-result-results-list-header {
    // If this gets changed need to change the list content height calculation as well
    height: 64px;
    border-bottom: 1px solid var(--color-border-default);
    padding: 16px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.search-result-results-list-header img {
    filter: var(--color-fig-subtle-filter);
}

.search-result-results-list-header > img {
    height: 24px;
    width: 24px;
    margin: 4px;
    cursor: pointer;
}

.search-result-results-list-header-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    padding: 6px 12px;
    height: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-border-default);
}

.search-result-results-list-header-input-wrapper > img {
    height: 16px;
    width: 16px;
    margin-right: 6px;
}

.search-result-results-list-header-input-wrapper > input {
    background-color: var(--color-input-bg-default);
    color: var(--color-fig-default);
    width: 100%;
    outline: none;
}

.search-result-results-segment-control-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 100%;
    border-bottom: 1px solid var(--color-border-default);
    padding: 16px 32px;
}

// TODO: remove un-used classes
.search-result-results-list-content-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    width: 100%;
    padding: 0px 32px;
    background-color: var(--color-surface-subtle);
    gap: 8px;
}

.search-result-results-injury-showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-top: 120px;
    height: 100%;
    background-color: var(--color-surface-subtle);
}

.search-result-results-injury-showcase > img {
    max-width: 200px;
    aspect-ratio: 1;
}

.search-result-results-injury-showcase-text {
    max-width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.search-result-results-injury-showcase-button {
    height: 40px;
    width: 143px;
}

.search-result-entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    width: 100%;
    padding: 0px 32px;
    border-bottom: 1px solid var(--color-border-default);
    cursor: pointer;
}

.search-result-entry[data-last=true] {
    border-bottom: none;
}

.search-result-entry:hover {
    background-color: var(--color-surface-hover);
}

.search-result-entry:active,
.search-result-entry[data-selected=true] {
    background-color: var(--color-surface-pressed);
}

.search-result-entry > img {
    height: 32px;
    width: 32px;
    margin-right: 8px;
    padding: 2px;
}

.search-result-results-list-content {
    width: 100%;
    // Height calculated based on the height of the header
    height: calc(100% - 64px);
    overflow-y: auto;
}

.search-result-results-list-suggestions {
    display: none;
}

.search-result-selection {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: column;
    min-width: 592px;
    max-width: 1200px;
    border-right: 1px solid var(--color-border-default);
}

.search-result-selection > img {
    display: none;
}

.search-result-selection-no-selection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-result-selection-no-selection > div {
    padding: 8px 12px;
    background-color: var(--color-surface-default);
    border-radius: 24px;
    border: 1px solid var(--color-border-default);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-injury-suggestions-section {
    padding: 32px 0px;
}

.search-injury-suggestions-section > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 24px;
}

.search-injury-suggestions-linemate-plus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
    border-radius: 4px;
    border: 1px solid var(--color-badge-border);
}

.search-injury-suggestions-linemate-plus > p {
    color: var(--color-badge-fig);
}

.search-injury-suggestions-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.search-injury-suggestions-subtitle {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 16px;
}

.search-injury-suggestions-buttons-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.search-injury-suggestions-buttons-group > div {
    height: 32px;
    width: 32px;
}

.search-injury-suggestions-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-left: 32px;
    padding-right: 32px;
}

.search-injury-suggestion {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    cursor: pointer;
    gap: 4px;
    white-space: wrap;
    // width: calc(100%/6);
    width: calc((100% / 6) - (16px * 5) / 6);
}

.search-injury-suggestion:hover {
    background-color: var(--color-surface-pressed);
}

.search-injury-suggestion > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}

.search-injury-suggestion > div > img {
    height: 24px;
    width: 24px;
    margin-right: 4px;
}

.search-injury-suggestion > div > p {
    text-overflow: ellipsis;
    overflow: hidden;
}

// The 432 on the left plus 432 (symmetrical on the right) plus the 1200 middle
@media only screen and (min-width: 2064px) {

    .search-result-selection {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-left: 1px solid var(--color-border-default);
        // width: 1200px;
    }

}

// This is content-specific changes, not the wrapper itself
@media only screen and (max-width: 1439px) {

}

@media only screen and (max-width: 1250px) {
    .search-result-selection .trend-container {
        width: 100%;
    }
}

@media only screen and (max-width: 1023px) {

    .search-result-results-list {
        display: none;
        width: 100%;
        height: 100%;
    }
    
    .search-result-results-list-mobile-active {
        display: initial;
    }

    .search-result-selection {
        display: none;
    }

    .search-result-selection-mobile-active {
        display: initial;
        width: 100%;
        min-width: auto;
    }

    .search-result-selection > img {
        display: initial;
        filter: var(--color-fig-subtle-filter);
        margin: 16px 8px 0px 24px;
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

    .search-result-results-list-suggestions {
        display: initial;
    }

}

@media only screen and (max-width: 768px) {
    .search-injury-suggestions-section > div:first-of-type {
        margin-left: 16px;
        margin-right: 16px;
    }

    .search-injury-suggestions-subtitle {
        margin-left: 16px;
        margin-right: 16px;
    }

    .search-injury-suggestions-wrapper {
        padding-left: 16px;
        padding-right: 16px;
    }
}

// All the sections below are for the injury suggestions
@media only screen and (min-width: 1440px) {
    .search-injury-suggestion {
        width: calc((100% / 6) - (16px * 5) / 6);
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .search-injury-suggestion {
        width: calc((100% / 4) - (16px * 3) / 4);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .search-injury-suggestion {
        width: calc((100% / 4) - (16px * 3) / 4);
    }
}

@media only screen and (min-width: 430px) and (max-width: 767px) {
    .search-injury-suggestion {
        width: calc((100% / 2) - (16px * 1) / 2);
    }
}

@media only screen and (max-width: 429px) {
    .search-injury-suggestion {
        width: calc((100% / 2) - (16px * 1) / 2);
    }
}
// End injury suggestions