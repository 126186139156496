.dropdown-container {
  display:block;
  position: absolute;
  z-index: 30;
  max-height: 250px;
  overflow-y: auto;

  background: #FFFFFF;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

}

.no-styling {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-list > li:first-child > span {
  border-radius: 6px 6px 0px 0px;
}

.dropdown-list > li:last-child > span {
  border-radius: 0px 0px 6px 6px ;
}

.dropdown-list > li:hover {
  background: #E4F0EC;
}

.dropdown-list .no-hover:hover {
  background-color: inherit;
}

.dropdown-list > li:first-child:hover {
  border-radius: 6px 6px 0px 0px;
}

.dropdown-list > li:last-child:hover {
  border-radius: 0px 0px 6px 6px ;
}


.dropdown-list-element {
  width: 100%;
  display: block;
  padding: 5px;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.selected-dropdown-list-element {
  background: #058C66;
}

.checkbox {
    margin-right: 10px;
    vertical-align: middle;
    appearance: none;
    background-color: #FFFFFF;
    display: inline-block;
    position: relative;
    border-radius: 3px;
    padding: 9px;
    border: 1px solid #D1D5DB;
}

.checkbox:checked {
  background-color: #047857;
  border: none;
}

.checkbox:checked:after {
  content: '';
	font-size: 14px;
	position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
