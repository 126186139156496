.button-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: var(--shadow-small);

    // Prevent text selection
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.button-container:hover, .button-container:active {
    cursor: pointer;
}

.button-container > img {
    height: 16px;
    width: 16px;
    filter: var(--color-fig-subtle-filter);
}

// Primary
.button-primary {
    background-color: var(--color-primary-button-bg);
}

.button-primary-disabled {
    background-color: var(--color-primary-button-disabled);
    box-shadow: none;
    cursor: initial !important;
}

.button-primary-disabled > p {
    color: var(--color-fig-disabled);
}

.button-primary:hover {
    background-color: var(--color-primary-button-hover);
}

.button-primary:active {
    background-color: var(--color-primary-button-pressed);
}

.button-primary > p {
    color: var(--color-fig-on-bg);
}

// Secondary
.button-secondary {
    background-color: var(--color-default-button-bg-default);
    border: 1px solid var(--color-default-button-border-default);
}

.button-secondary-disabled {
    background-color: var(--color-default-button-bg-disabled);
    border: 1px solid var(--color-default-button-border-disabled);
    box-shadow: none;
    cursor: initial !important;
}

.button-secondary:hover {
    background-color: var(--color-default-button-bg-hover);
}

.button-secondary:active {
    background-color: var(--color-default-button-bg-pressed);
}

.button-secondary > p {
    color: var(--color-fig-default);
}

.button-secondary-disabled > p {
    color: var(--color-fig-disabled);
}

.button-secondary-disabled > img {
    filter: var(--color-fig-disabled-filter);
}

// Invisible
.button-invisible, .button-invisible-disabled {
    background-color: inherit;
    box-shadow: none;
}

.button-invisible-disabled {
    cursor: initial !important;
}

.button-invisible:hover {
    background-color: var(--color-ghost-button-hover);
}

.button-invisible:active {
    background-color: var(--color-ghost-button-pressed);
}

.button-invisible > p {
    color: var(--color-ghost-button-fig);
}

.button-invisible-disabled > p {
    color: var(--color-fig-disabled);
}

.button-invisible-disabled > img {
    filter: var(--color-fig-disabled-filter);
}

// Destructive
.button-destructive {
    background-color: var(--color-destructive-button-bg);
}

.button-destructive-disabled {
    background-color: var(--color-destructive-button-disabled);
    cursor: initial !important;
}

.button-destructive-disabled > p {
    color: var(--color-fig-disabled);
}

.button-destructive:hover {
    background-color: var(--color-destructive-button-hover);
}

.button-destructive:active {
    background-color: var(--color-destructive-button-pressed);
}

.button-destructive > p {
    color: var(--color-fig-on-bg);
}