/* Generic font type */
.font {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
}

.font-underweight {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
}

/* Sizes */
.size-12 {
  font-size: 12px;
  line-height: 16px;
}

.size-14 {
  font-size: 14px;
  line-height: 20px;
}

.size-16 {
  font-size: 16px;
  line-height: 24px;
}

.size-18 {
  font-size: 18px;
  line-height: 24px;
}

.size-20 {
  font-size: 20px;
  line-height: 28px;
}

.size-24 {
  font-size: 24px;
  line-height: 32px;
}

.size-30 {
  font-size: 30px;
  line-height: 36px;
}

.size-36 {
  font-size: 36px;
  line-height: 40px;
}

.size-44 {
  font-size: 44px;
  line-height: 56px;
}

.size-64 {
  font-size: 64px;
  line-height: 72px;
}

.size-72 {
  font-size: 72px;
  line-height: 80px;
}

/* Bold -- higher weight */
.bold {
  font-weight: 600;
}

.weight-400 {
  font-weight: 400;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}

.undersized {
  font-weight: 400;
}

/* Color */
.grey {
  color: #374151;
}
.grey:visited, .grey:hover, .grey:active {
  color: #374151;
  text-decoration: none;
}

.light-grey {
  color: #D1D5DB;
}
.light-grey:visited, .light-grey:hover, .light-grey:active {
  color: #D1D5DB;
  text-decoration: none;
}

.dark {
  color: #111827;
}
.dark:visited, .dark:hover, .dark:active {
  color: #111827;
  text-decoration: none;
}

.green {
  color: #047857;
}
.green:visited, .green:hover, .green:active {
  color: #047857;
  text-decoration: none;
}

.white {
  color: #FFFFFF;
}
.white:visited, .white:hover, .white:active {
  color: #FFFFFF;
  text-decoration: none;
}

.red {
  color: #DC2626;
}
.red:visited, .red:hover, .red:active {
  color: #DC2626;
  text-decoration: none;
}

.color-placeholder {
  color: #6B7280;
}
.color-placeholder:visited, .color-placeholder:hover, .color-placeholder:active {
  color: #6B7280;
  text-decoration: none;
}

/* Alignment */
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Spacing */
.spaced {
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.spaced-negative-low {
  letter-spacing: -0.025em;
}

.uppercase, .capitalized {
  text-transform: uppercase;
}

/* Extra styling */
.underlined {
  text-decoration-line: underline;
}

/* Useful for full width paragraph tags */
.display-block {
  display: block;
}

/* TODO: Should probably move this to a 'generic' css */
body {
  background: var(--color-surface-default);
}

p {
  margin: 0;
  padding: 0;
}

.content-container {
  /* width: 100%; */
  height: 100%;
  position: relative;
  /* margin-left: 12.5%;
  margin-right: 12.5%; */
  /* max-width: 1200px; */
  width: 1200px;
  margin: 0 auto 0 auto;
  /* margin-top: 40px; */
  border-left: 1px solid var(--color-border-default);
  border-right: 1px solid var(--color-border-default);
  padding: 32px;
  flex-grow: 1;
}

.absolute {
  position: absolute;
}

@media only screen and (max-width: 1199px) {
  .content-container {
    margin-left: 3%;
    margin-right: 3%;
    width: auto;
  }
}


/* smallest screens (mobiles) */
@media only screen and (max-width: 768px) {

  .content-container {
    margin-left: 0;
    margin-right: 0;
    padding: 24px 16px;
  }

  .overflow-pointer-left {
    display: none;
  }

  .overflow-pointer-right {
    display: none;
  }

  .size-16 {
    font-size: 14px;
    line-height: 20px;
  }

  .size-18 {
    font-size: 16px;
    line-height: 24px;
  }

}

/* 
  Fonts used by Jay
  Keeping them as-is (but moving to the existing fonts stylesheet) to avoid having to change everything
 */

 .text-dark {
  color: #111827;
}

.text-subdued-dark {
  color: #374151;
}

.text-meta-subdued {
  color: #4B5563;
}

.text-subdued-light {
  color: #F9FAFB;
}

.text-light {
  color: #ffffff;
}

.text-marketing {
  color: #047857;
}

/* Normal Font Weight */
.text-xs {
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
}

.text-sm {
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;
}
.text-base {
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-lg {
  font-family: Inter;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: normal;
}

.text-xl {
  font-family: Inter;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: normal;
}

.text-2xl {
  font-family: Inter;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: normal;
}

.text-3xl {
  font-family: Inter;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: normal;
}

.text-4xl {
  font-family: Inter;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: normal;
}


/* Medium Font Weight */
.text-xs-med {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: normal;
}

.text-sm-med {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: normal;
}
.text-base-med {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: normal;
}

.text-lg-med {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: normal;
}

.text-xl-med {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: normal;
}

.text-2xl-med {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: normal;
}

.text-3xl-med {
  font-family: Inter;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: normal;
}

/* Semi-bold Font Weight */
.text-xs-semi {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: normal;
}

.text-sm-semi {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: normal;
}
.text-base-semi {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: normal;
}

.text-lg-semi {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: normal;
}

.text-xl-semi {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: normal;
}

.text-2xl-semi {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: normal;
}

.text-3xl-semi {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: normal;
}

/* Bold Font Weight */
.text-xs-bold {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: normal;
}

.text-sm-bold {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: normal;
}
.text-base-bold {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: normal;
}

.text-lg-bold {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: normal;
}

.text-xl-bold {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: normal;
}

.text-2xl-bold {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: normal;
}

.text-3xl-bold {
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: normal;
}

.text-4xl-bold {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: normal;
}

.text-5xl-bold {
  font-size: 44px;
  line-height: 56px;
  font-weight: 800;
  letter-spacing: normal;
}
/* Uppercase */
.text-xs-upper {
  font-family: Inter;
  font-size: 12px;
  letter-spacing: 5%;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 500;
}

.text-sm-upper {
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 5%;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.text-base-upper {
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 5%;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
/* Special Use */
.text-base-upper-med {
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 5%;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
}

.text-xs-upper-semi {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 5%;
  text-transform: uppercase;
  font-weight: 600;
}

.text-sm-upper-semi {
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 5%;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
}


.colour-default {
  color: #111827;
}