.signup-page-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-surface-subtle);
}

.signup-page-container > a {
    height: 32px;
    width: 44px;
    margin-top: 32px;
    margin-bottom: 144px;
    filter: var(--color-fig-default-filter);
}

.signup-page-container > p {
    color: var(--color-fig-default);
}

.signup-page-title {
    margin-bottom: 12px;
}

.signup-page-no-account-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 40px;
}

.signup-page-no-account-signup > p {
    color: var(--color-fig-subtle);
}

.signup-page-no-account-signup > a {
    color: var(--color-fig-accent);
}

.signup-page-form {
    display: flex;
    flex-direction: column;
    width: 321px;
}

.signup-page-form > p,
.signup-page-form-names p {
    margin-bottom: 4px;
    color: var(--color-fig-default);
}

p.signup-page-error {
    color: var(--color-fig-negative);
    margin-top: 4px;
}

.signup-page-form input {
    background-color: var(--color-input-bg-default);
    color: var(--color-fig-default);
    border: 1px solid var(--color-input-border-default);
    border-radius: 6px;
    height: 40px;
    padding: 8px 12px;
}

.signup-page-input-title {
    margin-top: 16px;
}

.signup-page-form-names {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.signup-page-form-names > div {
    display: flex;
    flex-direction: column;
    width: calc(50% - 8px);
}

.signup-page-form > a {
    color: var(--color-fig-accent);
    margin-bottom: 24px;
}

.signup-page-terms-and-privacy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 24px;
}

.signup-page-terms-and-privacy > p {
    color: var(--color-fig-subtle);
}

.signup-page-terms-and-privacy > a {
    color: var(--color-fig-default);
    text-decoration: underline !important;
}

.signup-page-login-button-wrapper {
    height: 40px;
    margin-bottom: 80px;
}

.signup-page-odds-preferences-container {
    width: 320px;
}

.signup-page-odds-preferences-header {
    margin-bottom: 40px;
}

.signup-page-odds-preferences-header > p {
    text-align: center;
}

.signup-page-odds-preferences-header > p:first-of-type {
    color: var(--color-fig-default);
}

.signup-page-odds-preferences-header > p:nth-of-type(2) {
    color: var(--color-fig-subtle);
}

.signup-page-odds-selection {
    margin-bottom: 24px;
}

.signup-page-odds-selection > p {
    color: var(--color-fig-default);
    margin-bottom: 8px;
}

@media only screen and (max-width: 375px) {

    .signup-page-container > a {
        margin-top: 16px;
        margin-bottom: 40px;
    }

    .signup-page-form,
    .signup-page-odds-preferences-container {
        width: 100%;
        padding: 0px 16px;
    }

}