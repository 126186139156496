.trend-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.trend-content-header-left,
.trend-content-header-left-parlay {
    display: flex;
    flex-direction: row;
}

.trend-content-header-left > img {
    height: 48px;
    width: 48px;
    padding: 4px;
    margin-right: 8px;
}

.trend-content-header-left-parlay > img {
    height: 32px;
    width: 32px;
    margin-right: 8px;
}

.trend-content-header-info-parlay {
    display: flex;
    justify-content: center;
}

.trend-content-header-info-subject,
.trend-content-header-info-market {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.trend-content-header-right > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // The +2 px is to offset the fact that the badges we use are the inner vector without the padding
    right: calc((var(--index) * 24px) + 2px);
    height: 28px;
    width: 28px;
}

.trend-content-header-right > div.trend-content-header-right-image-with-border {
    height: 32px;
    width: 32px;
    border: 2px solid var(--color-surface-default);
    border-radius: 50%;
}

.trend-content-header-right img {
    filter: var(--color-fig-default-filter);
}

.trend-parlay-legs {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
}

.trend-parlay-leg {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.trend-parlay-leg-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-left: 12px;
    margin-right: 20px;
}

// The design has the line in the middle of the dot but using half a pixel on each side since the dot is an even width
// If we keep width of line as 1 it's always going to be on either side because you can't do half pixels
// Updated to width 2 and shifted the left by 1 pixel to be able to use one pixel on either side of the middle
.trend-parlay-leg-line {
    position: absolute;
    width: 2px;
    height: 20px;
    // 12 px for the left margin of the dot
    // 3 px for half of the width of the dot (minus half the width of the line)
    left: calc(12px + 3px);
    top: calc(-50% - 2px);
}

.trend-parlay-leg-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
}

.trend-content-insights {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 4px;
}

.trend-content-insight-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.trend-content-insight-wrapper > p:first-child {
    margin-left: 8px;
    color: var(--color-fig-subtle);
}

.trend-content-insight-wrapper > p:nth-child(2) {
    color: var(--color-fig-default);
}

@media only screen and (max-width: 768px) {

    .trend-content-header-right > div {
        top: 2px;
        transform: none;
        // The +2 px is to offset the fact that the badges we use are the inner vector without the padding
        right: calc((var(--index) * 16px) + 2px);
        height: 20px;
        width: 20px;
    }
    
    .trend-content-header-right > div.trend-content-header-right-image-with-border {
        top: 0px;
        height: 24px;
        width: 24px;
    }

    .trend-content-header-right img {
        height: 20px;
        width: 20px;
        vertical-align: baseline;
    }
}