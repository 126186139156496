/* body {
    position: relative;
    font-family: Inter;
    background-color: #F9FAFB;
    padding-top: 160px;
    margin: 0;
} */

h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: var(--color-fig-default);
    margin: 0;
    margin-bottom: 2%;
}

h5 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-fig-default);
    margin: 0;
    margin-left: 2%;
    margin-bottom: 2%
}

header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.nav {
    height: 64px;
    /* surface-nav/default */
    background: #111827;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.league-dropdown-vector {
    width: 16px;
    height: 12px;
    margin-right: 10px;
}

.logos {
    display: inline-block;
}

.logo1 {
    margin-left: 145px;
    margin-right: 7px;
}

.navbar {
    display: inline-block;
}

.navbar li {
    display: inline-block;
    list-style-type: none;
    color: #D1D5DB;
    cursor: pointer;
    margin-right: 40px;
}

.navbar li:hover {
    color: #ffffff;
}

.divider {
    border-left: 1px solid var(--color-border-default);
    height: 32px;
    border-radius: 2px;
    margin-right: 28px;
}

.btn-group {
    margin-right: 144px;
}

.btn-group > .btn-sign-in {
    margin-right: 32px;
}

.btn-free-trial {
    width: 141px;
    height: 36px;
    background-color: #047857;
    border: #047857;
    border-radius: 6px;
    color: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.current-matchup {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 160px;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.away-team {
    margin-right: 86px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
}

.away-team span,
.home-team span {
    color: var(--color-fig-default);
}

.match-details {
    text-align: center;
}

.match-details {
    color: var(--color-fig-default);
}

.match-date{ 
    margin-bottom: 4px;
}

.match-start-time {
    margin-bottom: 4px;
}

.home-team {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-left: 86px;
    flex-grow: 1;
    flex-basis: 0;
}

.away-city {
    margin-right: 28px;
}
.home-city {
    margin-left: 28px;
}

.playbook-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1152px;
    margin: 24px auto 0 auto;
}

.playbook-card-group {
    width: 80%;
    position: relative;
}

.playbook-card {
    background-color: inherit;
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    padding: 24px;
    margin-bottom: 24px;
    box-sizing: border-box;
    position: relative;
    color: var(--color-fig-default);
} 

.playbook-scouting-report .picker {
    /* right: 24px; */
    /* top: 24px; */
}

.playbook-scouting-report > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.playbook-scouting-report > div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.playbook-scouting-report > div:nth-of-type(2) > div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.playbook-scouting-report .filter-button {
    /* right: 24px;
    top: 80px; */
}

.playbook-scouting-report .comparative-table-body {
    margin-bottom: 0;
    margin-top: 40px;
}

.playbook-scouting-report .comparative-table-body .desktop {
    display: flex;
}

.playbook-scouting-report .comparative-table-body .mobile {
    display: none;
}

.playbook-scouting-report > .comparative-table-body > .comparative-tbl-row.desktop > div:nth-of-type(1),
.playbook-scouting-report > .comparative-table-body > .comparative-tbl-row.desktop > div:nth-of-type(5) {
    width: 30%;
}

.playbook-scouting-report > .comparative-table-body > .comparative-tbl-row.desktop > div:nth-of-type(2),
.playbook-scouting-report > .comparative-table-body > .comparative-tbl-row.desktop > div:nth-of-type(4) {
    width: 15%;
}

.playbook-scouting-report > .comparative-table-body > .comparative-tbl-row.desktop > div:nth-of-type(3) {
    width: 10%;
}

.playbook-scouting-report-link-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
    white-space: nowrap;
}

.playbook-scouting-report-link-wrapper > a {
    position: absolute;
    /* top: 50%; */
    left: 50%;
    /* transform: translate(-50%, -50%); */
    transform: translateX(-50%);
    bottom: 0px;
    
    /* To make the clickable area a bit bigger than just the text portion */
    padding: 8px 16px;

    color: var(--color-ghost-button-fig);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.playbook-scouting-report-link-wrapper > a > img {
    filter: var(--color-fig-accent-filter);
}

/* Combined selector */
.playbook-nfl-scouting-type-filter.filter-button {
    top: 136px;
}

/* Siblings selector, table after the nfl filter presence */
.playbook-nfl-scouting-type-filter ~ .comparative-table-body {
    margin-top: 136px;
}

ol li {
    /* padding-bottom: 4px; */
}

.comparative-table-body {
    width: 100%;
    text-align: center;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    margin-top: 3%;
    margin-bottom: 3%;
    position: relative;
}

.comparative-tbl-header{
    display: flex;
    height: 56px;
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}

.tbl-header-home {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.tbl-header-away {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: var(--color-surface-subtle);
    border-right: 1px solid var(--color-border-default);
    border-bottom: 1px solid var(--color-border-default);
}

.tbl-header-home img, .tbl-header-away img {
    margin: 0 8px;
}

.comparative-tbl-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    height: 48px;
}

.comparative-tbl-row > div:nth-child(2) {
    color: var(--color-fig-subtle);
}

.advanced-stats-tbl-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    height: 48px;
}

.comparative-tbl-row:nth-child(odd) {
    background-color: var(--color-surface-subtle);
}

.trends-container {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 100%;
    vertical-align: middle;
    margin-top: 24px;
}

.trends {
    padding: 1.5% 2%;
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    border-radius: 8px;
    /* margin-bottom: 2%; */

    display: block;
    width: 100%;
}

.trends:first-of-type {
    margin-bottom: 24px;
}

.trends img {
    vertical-align: middle;
    margin-bottom: 3px;
}

.trends ol {
    margin-bottom: 0;
}

.playbook-section-wrapper > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.playbook-picker-wrapper {
    width: 248px;
    height: 40px;
}

.playbook-season-card-wrapper > div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    gap: 16px;
}

.edge-finder-card {
    width: 100%;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 3%;
    margin-bottom: 3%;
}

.edge-finder-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    height: 72px;
    padding-left: 5%;
    padding-right: 5%;
}

.edge-finder-row:nth-child(odd) {
    background-color: var(--color-surface-subtle);
}

.league-rankings-card {
    width: 100%;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 2.5%;
}

.lr-header {
    display: flex;
    height: 56px;
    background-color: var(--color-surface-subtle);
}
.lr-home {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 33.33%;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.lr-home > img {
    margin-right: 8px;
}

.lr-away {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 33.33%;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.lr-away > img {
    margin-left: 8px;
}

.lr-swap{
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
}

.lr-swap > div {
    width: 32px;
    height: 32px;   
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--color-border-default);
}

.insights {
    padding: 1.5% 2%;
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 2%;
}

.insights img {
    filter: var(--color-fig-subtle-filter)
}

.team-records {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.match-result {
    height: 24px;
    background-color: var(--color-surface-subtle);
}

.record-away-team, .record-home-team {
    width: 100%;
}

.record-away-team {
    border-right: 1px solid var(--color-border-default);
}

.win-loss {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    height: 24px;
    background-color: #F9FAFB;
}

.win-loss div {
    width: 50%;
}

.box-score {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    height: 48px;
}

.box-score div {
    width: 33%;
}
.away-box-score {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #333333;
}

.away, .home,.score {
    width: 75px;
}


.anchor {
    scroll-margin-top: 180px;
}

.matchup-card {
    border-radius: 8px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 2.5%;
    margin-top: 80px;
}
#scouting-report {
    /* width: 70%;
    display: inline-block; */
}
.player-group {
    position: relative;
    display: inline-block;
    width: 29%;
}

.player-group-badge {
    background: url(./internal-assets/badge-bg.svg);
}

.primary-badge {
    position: relative;
}

.secondary-badge {
    position: absolute;
    left: 50px;
    border-radius: 50%;
    border: 5px solid var(--color-border-default);
    overflow: hidden;
}

.terciary-badge {
    position: absolute;
    left: 100px;
    border-radius: 50%;
    border: 5px solid var(--color-border-default);
}

.matchup-card .matchup-header {
    height: 52px;
    text-align: center;
    background-color: #F9FAFB;
    border-bottom: 1px solid var(--color-border-default);
    border-radius: 8px 8px 0 0;
}
.matchup-card .matchup-sub-header {
    height: 24px;
    text-align: center;
    background-color: #F9FAFB;
}

.category-leaders {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
}

.home-leaders, .away-leaders {
    width: 50%;
}
.away-leaders {
    border-right: 1px solid var(--color-border-default);
}

.primary-leader {
    height: 112px;
    padding: 4.5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
.secondary-leader {
    height: 56px;
    padding: 4.5%;
}

.cat-leader-name {
    width: 80%;
    padding-left: 2.5%;
    padding-bottom: 4%;
}

.cat-leader-totals {
    width: 20%;
    text-align: right;
    padding-bottom: 5%;
}

.scoring-total {
    float: right;
}

.cat-leader-card{
    height: 80px;
    width: 80px;
    position: relative;
}

.player-badge {
    position: relative;
    background: url(./internal-assets/badge-bg.svg);
}
.team-badge {
    position: absolute;
    top: 50px;
    left: 50px;
}

.secondary-leader:nth-child(even) {
    background-color: #F9FAFB;
}

.simple-table {
    width: 100%;
    border-radius: 8px;
    /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1); */
    margin-bottom: 3%;
    overflow: hidden;
    margin-top: 3%;
    border: 1px solid var(--color-border-default);
}

.simple-tbl-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    border-radius: 8px 8px 0 0;
    height: 56px;
    padding: 1.75% 0 1.75% 3%;
    color: var(--color-fig-default) !important;
}

.simple-tbl-header img {
    margin: 0 8px;
}


.tbl-columns {
    height: 40px;
    background-color: #F9FAFB;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid var(--color-border-default);
}

.tbl-columns div {
    width: 25%;
}

.goalie-stats {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.goalie-stats div {
    width: 25%;
}

.g-name {
    text-align: left;
    padding-left: 3%;
}

.g-stat {
    text-align: center;
}

.goalie-stats:nth-child(even) {
    background-color: #F9FAFB;
}
.player {
    padding-left: 3%;
}

.stat-range {
    text-align: center;
}
.injury-report-card {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 3%
}

.injury-card-header {
    width: 100%;
    border-radius: 8px 8px 0 0;
    height: 56px;
    padding: 1.75% 0 1.75% 3%;
}
.home-team-bg{
    background-color: var(--color-surface-subtle);
}
.away-team-bg {
    background-color: var(--color-surface-subtle);
}

.injury-tbl-columns {
    height: 40px;
    background-color: var(--color-surface-subtle);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid var(--color-border-default);
}

.player-info {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


.injured-player, .p-name {
    width: 30%;
    padding-left: 24px;
}

.injury-details, .injury-type {
    text-align: center;
    width: 35%;
}

.news-feed {
    height: 519px;
    margin-bottom: 80px;
}

.short-text {
    display: none;
}

.summary-graphs {
    width: 50%;
    display: inline-block;
    height: 350px;
    /* margin-bottom: 40px; */
}

.playbook-summary-pie, .playbook-summary-radar {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
}

.playbook-summary-radar {
    height: 75%;
    margin-top: 48px;
    display: inline;
}

.playbook-summary-radar > p {
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 43.75px;
}

/* Very hacky way of overriding the text color on a graph, need to find the full path to the text item. Important needed because it assigns the color on inline styles */
.playbook-season-graph-wrapper > div > div > svg > g > g:last-of-type > text {
    fill: black !important;
}

.playbook-rf-offence, .playbook-rf-defense {
    height: 350px;
    width: 100%;
    margin-bottom: 25px;
}

.playbook-rf-offence > div, .playbook-rf-defense > div {
    height: 100%;
    width: 47.5%;
    margin-right: 2.5%;
    display: inline-block;
}

.playbook-betting-trends {
    padding: 8px;
    position: relative;
}

.playbook-betting-trends > p:nth-of-type(1) {
    display: block;
    margin-top: 16px;
    margin-bottom: 24px;
}

.playbook-betting-trends > .filter-button {
    top: 16px;
}

.playbook-betting-trends > .comparative-table-body {
    margin-top: 0;
}

.playbook-betting-trend-wrapper {
    height: 40px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.playbook-betting-trend-trend-name {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 12.5%;
    height: 100%;
    text-align: left;
    padding-top: 8px;
}

.playbook-betting-trend-percentage-bar-wrapper {
    position: relative;
    display: inline-block;
    width: 77.5%;
}

.playbook-betting-trend-trend-percentage {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 10%;
    height: 100%;
    text-align: right;
    padding-top: 8px;
}


/* Start edge finder show more/less button */
.playbook-edge-finder-show-button-gradient {
    height: 28px;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 42px;

    background: linear-gradient(0deg, var(--color-surface-default) -46.43%, var(--color-surface-default) -46.41%, rgba(255, 255, 255, 0) 141.07%);
}

.playbook-edge-finder-show-button, .playbook-edge-finder-show-button-expanded {
    height: 42px;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 2;
    margin-left: 0px;
    bottom: 0px;

    background: var(--color-surface-default);
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.playbook-edge-finder-show-button-expanded {
    bottom: -58px;
}

.playbook-edge-finder-show-button:hover, .playbook-edge-finder-show-button-expanded:hover {
    cursor: pointer;
}

.playbook-edge-finder-show-button > p, .playbook-edge-finder-show-button-expanded > p {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding-top: 10px;
}
/* End edge finder show more/less button */

/* Start betting trend show more/less button */
.playbook-betting-trend-show-button-gradient,
.playbook-scouting-report-stats-button-gradient {
    height: 20px;
    width: calc(100% - 48px);
    display: block;
    position: absolute;
    z-index: 2;
    /* margin-left: -6px; */
    bottom: 48px;

    background: linear-gradient(0deg, var(--color-surface-default) -67.5%, var(--color-surface-default) -67.48%, rgba(255, 255, 255, 0) 112.5%);
}

.playbook-betting-trend-show-button, 
.playbook-betting-trend-show-button-expanded,
.playbook-scouting-report-stats-button,
.playbook-scouting-report-stats-button-expanded {
    height: 36px;
    width: calc(100% - 48px);
    display: block;
    position: absolute;
    z-index: 2;
    /* margin-left: -6px; */
    bottom: 12px;

    background: var(--color-surface-default);
    border: 1px solid var(--color-border-default);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.playbook-betting-trend-show-button-expanded {
    bottom: 0px;
}

.playbook-scouting-report-stats-button-gradient {
    bottom: calc(72px + 36px);
}

.playbook-scouting-report-stats-button {
    padding-top: 8px;
    bottom: 72px;
}

.playbook-scouting-report-stats-button-expanded {
    padding-top: 8px;
    bottom: 84px;
}

.playbook-scouting-report-stats-button-expanded ~ .comparative-table-body {
    margin-bottom: 44px;
}

.playbook-betting-trend-show-button:hover, .playbook-betting-trend-show-button-expanded:hover {
    cursor: pointer;
}

.playbook-betting-trend-show-button > p, .playbook-betting-trend-show-button-expanded > p {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding-top: 7px;
}
/* End betting trend show more/less button */

.playbook-picker {
    width: 300px;
    height: 40px; 
    padding: 2px; 
    position: absolute;
    right: 0px; 
    top: 0px;
}

.playbook-picker .picker-inactive p:hover {
    color: var(--color-fig-default);
}

.playbook-stackable-dropdown, .playbook-stackable-dropdown-swapped {
    position: absolute;
    width: 300px;
    right: 0;
    top: 56px;
    margin-right: 0;
}

.playbook-single-dropdown {
    position: absolute;
    width: 300px;
    right: 0;
    top: 0;
    margin-right: 0;
}

.game-by-game {
    height: 448px;
}

.game-by-game > div:first-of-type,
.game-by-game > div:first-of-type > div,
.playbook-betting-trends > div:first-of-type,
.playbook-betting-trends > div:first-of-type > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.playbook-betting-trends > div:first-of-type > div > p,
.playbook-betting-trends > div:first-of-type > div > p {
    margin-right: 16px;
}

.game-by-game-filter-wrapper {
    height: 28px;
    width: 204px;
}

#rf-offence, #rf-defense {
    display: block;
    position: relative;
    width: 100%;
    height: 675px;
}

#season-offence, #season-defense {
    display: block;
    position: relative;
    width: 100%;
    height: 544px;
}

@media only screen and (min-width: 1200px) {

    .side-nav {
        width: 20%;
        position: sticky;
        position: -webkit-sticky;
        top: 184px;
        display: inline-block;
        margin-bottom: 80px;
    }

    .side-nav .main-section {
        color: var(--color-fig-default);
        padding-left: 5%;
    }

    .side-nav .subsection {
        color: var(--color-fig-subtle);
        padding-left: 7%;
    }
    
    .side-nav a:hover {
        /* background-color: #E4F0EC; */
        border-radius: 4px;
        width: 176px;
        height: 36px;
        padding: 8px;
    }

    .side-nav a:focus {
        /* background-color: #BADAD0; */
        border-radius: 4px;
        width: 176px;
        height: 36px;
        padding: 8px;
    }

    .side-nav > ul {
        list-style: none;
        text-decoration: none;
        margin: 0;
        padding: 0;
    }

    .side-nav > ul > li {
        list-style: none;
        /* padding-bottom: 7%; */
        padding-top: 8px;
        padding-bottom: 8px;
        border-left: 2px solid var(--color-border-default);
        transition: all .2s ease;
    }

    .side-nav a {
        text-decoration: none;
        color: inherit;
    }

    .active {
        color: var(--color-fig-accent) !important;
    }

    .active-marker {
        background: var(--color-fig-accent);
        top: 0px;
        display: block;
        height: 35px;
        position: absolute;
        left: 0px;
        transition: all .2s cubic-bezier(.25, .25, .25, 1.25);
        width: 2px;
    }

    .mobile-navbar {
        display: none;
    }
    .comparative-tbl-row div {
        width: 33.33%;
    }

    .comparative-tbl-row div:nth-of-type(2) {
        width: 11%;
    }

    .advanced-stats-tbl-row div {
        width: 33.33%;
    }

    .advanced-stats-tbl-row:nth-child(even) {
        background-color: var(--color-surface-subtle);
    }

    .advanced-stats-sub-header {
        display: flex;
        align-items: center;
        align-content: center;
        height: 24px;
        background-color: var(--color-surface-subtle);
    }

    .advanced-stats-sub-header div {
        width: 33.33%;
    }

    .special-teams-tbl-row {
        display: flex;
        align-content: center;
        align-items: center;
        height: 48px;
    }

    .special-teams-tbl-row div {
        width: 33.33%;
    }

    .special-teams-cat {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .simple-table-mobile {
        display: none;
    }

    
}

.horizontal-scroll-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 16px;
    overflow: auto;
    width:100%;
    min-height: 50px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.horizontal-scroll-container::-webkit-scrollbar {
    display: none;
}

.spoortsbook-container {
    width: 32%;
    min-width: 280px;                                          
    flex-shrink: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #D1D5DB;
    border-radius: 8px;
    padding: 16px;
}

.sportsbook-shadow {
    position: absolute;
    display: block;
    z-index: 4;
    height: 100%;
    width: 40px;
    background: linear-gradient(90deg, rgba(249, 250, 251, 0) 0%, #F9FAFB 100%);
    right: 0;
    top: 0px;
}

.sportsbook-scrool-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.sportsbook-bottom-link {
    display: none;
}

@media only screen and (min-width:768px) and (max-width:1200px) {
    .mobile-navbar {
        display: flex;
        align-items: center;
        position: sticky;
        top: 124px;
        height: 52px;
        background-color: #F9FAFB;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border-bottom: 1px solid var(--color-border-default);
        z-index: 99;
    }
    
    .mobile-navbar::-webkit-scrollbar {
        display: none;
    }
    .mobile-navbar div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 102px;
        flex-basis: 0;
        flex-grow: 1;
    }

    .mobile-navbar div a {
        width: 102px;
        font-size: 12px;
        font-family: Inter;
        line-height: 16px;
        font-weight: 500;
        text-decoration: none;
        color: #111827;
        text-align: center;
    }

    .active {
        border-bottom: 2px solid #047857 !important;
    }

    .full-text {
        display: none;
    }

    .short-text {
        display: inline-block;
    }

    .current-matchup {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
        height: 80px;
        /* margin-top: 68px; */
        /* background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 128.67%), url(./internal-assets/current-matchup-bg.png); */
        background-repeat: round;
    }

    .away-team{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
    }
    
    .match-details {
        text-align: center;
        margin-right: 0px;
    }
    
    .match-date{
        display: none;
        margin-bottom: 4px;
    }
    
    .match-start-time {
        display: none;
        margin-bottom: 4px;
    }
    
    .home-team {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .home-team img, .away-team img {
        width: 32px;
    }
    
    .away-city {
        margin-right: 16px;
    }
    .home-city {
        margin-left: 16px;
    }

    .playbook-content {
        margin-top: 16px;
        margin-left: 0%;
        margin-right: 0%;
    }

    .side-nav{
        display: none;
    }

    .playbook-card-group {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }

    .playbook-card {
        border-radius: 0px;
    }

    .home-leaders, .away-leaders {
        width: 100%;
    }
    .home-leaders {
        border-top: 1px solid #F9FAFB;
    }

    .category-leaders {
        flex-direction: column;
    }
    .comparative-tbl-row div {
        width: 33%;
    }

    .player-group {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .advanced-stats-tbl-row div {
        width: 33.33%;
    }

    .advanced-stats-tbl-row:nth-child(even) {
        background-color: #F9FAFB;
    }

    .advanced-stats-sub-header {
        display: flex;
        align-items: center;
        align-content: center;
        height: 24px;
        background-color: #F9FAFB;
    }

    .advanced-stats-sub-header div {
        width: 33.33%;
    }

    .special-teams-tbl-row {
        display: none;
    }

    .special-teams-tbl-row div {
        width: 33.33%;
    }

    .special-teams-cat {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .tbl-columns div, .goalie-stats div {
        width: 33%;
    }
    .g-name, .player {
        padding-left: 16px;
    }

    .injured-player, .p-name {
        width: 50%;
        padding-left: 24px;
    }
    
    .injury-details, .injury-type {
        text-align: center;
        width: 50%;
    }

    .box-score .away span, .box-score .home span {
        display: none;
    }
    
    .simple-table-mobile {
        display: none;
    }
    
}

@media only screen and (min-width: 360px) and (max-width: 767px) {

    .playbook-section-wrapper > div:first-of-type {
        flex-wrap: wrap;
    }
    
    .playbook-picker-wrapper {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .game-by-game {
        height: 448px;
    }

    .game-by-game > div:first-of-type,
    .playbook-betting-trends > div:first-of-type {
        flex-wrap: wrap;
        gap: 24px;
    }

    .game-by-game > div:first-of-type > div,
    .playbook-betting-trends > div:first-of-type > div {
        width: 100%;
        justify-content: unset;
    }

    .game-by-game-filter-wrapper,
    .playbook-scouting-report > div:nth-of-type(2) > div {
        flex-grow: 1;
        height: 40px;
    }

    .playbook-season-card-wrapper > div:nth-of-type(2) {
        margin-top: 0px;
    }

    .playbook-scouting-report > div:first-of-type,
    .playbook-scouting-report > div:nth-of-type(2) {
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .playbook-scouting-report > div:nth-of-type(2) > div:first-of-type {
        /* This text can just fuck off, can't be bothered to re-arange the html to have one for mobile and one for desktop */
        display: none;
    }

    .mobile-navbar {
        display: flex;
        align-items: center;
        position: sticky;
        top: 107px;
        height: 52px;
        background-color: var(--color-surface-default);
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border-bottom: 1px solid var(--color-border-default);
        border-top: 1px solid var(--color-border-default);
        z-index: 99;
    }
    
    .mobile-navbar::-webkit-scrollbar {
        display: none;
    }
    .mobile-navbar div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 102px;
        flex-basis: 0;
        flex-grow: 1;
    }

    .mobile-navbar div a {
        width: 102px;
        font-size: 12px;
        font-family: Inter;
        line-height: 16px;
        font-weight: 500;
        text-decoration: none;
        color: var(--color-fig-subtle);
        text-align: center;
    }

    .active {
        border-bottom: 2px solid var(--color-fig-accent) !important;
    }

    .full-text {
        display: none;
    }

    .short-text {
        display: inline-block;
    }

    .current-matchup {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
        height: 80px;
        /* margin-top: 68px; */
        /* background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 128.67%), url(./internal-assets/current-matchup-bg.png); */
        background-repeat: round;
    }

    .away-team{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
    }
    
    .match-details {
        text-align: center;
        margin-right: 0px;
    }
    
    .match-date{
        display: none;
        margin-bottom: 4px;
    }
    
    .match-start-time {
        display: none;
        margin-bottom: 4px;
    }
    
    .home-team {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .home-team img, .away-team img {
        width: 32px;
    }
    
    .away-city {
        margin-right: 16px;
    }
    .home-city {
        margin-left: 16px;
    }

    .playbook-content {
        margin-top: 16px;
        margin-left: 0%;
        margin-right: 0%;
    }

    .side-nav{
        display: none;
    }

    .playbook-card-group {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }

    .playbook-card {
        border-radius: 0px;
    }

    .home-leaders, .away-leaders {
        width: 100%;
    }
    .home-leaders {
        border-top: 1px solid #F9FAFB;
    }

    .category-leaders {
        flex-direction: column;
    }
    .comparative-tbl-row div {
        width: 33%;
    }

    .player-group {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .advanced-stats-tbl-row div {
        width: 33.33%;
    }

    .advanced-stats-tbl-row:nth-child(even) {
        background-color: var(--color-surface-subtle);
    }

    .advanced-stats-sub-header {
        display: flex;
        align-items: center;
        align-content: center;
        height: 24px;
        background-color: var(--color-surface-subtle);
    }

    .advanced-stats-sub-header div {
        width: 33.33%;
    }

    .special-teams-tbl-row {
        display: none;
    }

    .special-teams-tbl-row div {
        width: 33.33%;
    }

    .special-teams-cat {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .tbl-columns div, .goalie-stats div {
        width: 33%;
    }
    .g-name, .player {
        padding-left: 16px;
    }

    .injured-player, .p-name {
        width: 50%;
        padding-left: 24px;
    }
    
    .injury-details, .injury-type {
        text-align: center;
        width: 50%;
    }

    .box-score .away span, .box-score .home span {
        display: none;
    }

    .simple-table {
        display: none;
    }

    .simple-table-mobile {
        width: 100%;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        border-radius: 8px;
        margin-bottom: 24px;
        /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.1); */
        border: 1px solid var(--color-border-default);
    }
    .simple-table-mobile tr th {
        height: 56px;
        font-family: Inter;
        font-weight: 600;
        color: white;
        font-size: 20px;
        border-radius: 8px 8px 0 0;
        padding-left: 24px;
    }
    .column-headers {
        height: 40px;
        background-color: var(--color-surface-subtle);
        border-bottom: 1px solid var(--color-border-default);
    }

    .column-headers td {
        min-width: 115px;
    }

    .row-data td {
        min-width: 115px;
        height: 56px;
    }

    .column-headers td:first-child, .row-data td:first-child {
        padding-left: 24px;
        text-align: left;
    }

    .column-headers td:nth-child(n +2), .row-data td:nth-child(n+2) {
        text-align: center;
    }

    .row-data:nth-child(even) {
        background-color: var(--color-surface-subtle);
    }

    .injury-report-row td:first-child {
        padding-right: 80px;
    }

    .summary-graphs {
        /* height: 700px; */
        /* height: 350px; */
        width: 100%;
        height: 85vw;
    }

    .trends-container {
        margin-top: 48px;
        width: 100%;
    }
    
    .playbook-summary-pie, .playbook-summary-radar {
        position: relative;
        display: inline-block;
        height: 50%;
        width: 100%;
    }

    .playbook-summary-radar {
        height: 100%;
        margin-top: 24px;
    }

    .playbook-summary-radar > p {
        width: 100%;
        text-align: center;
        display: block;
        margin-top: 20px;
    }

    .playbook-rf-offence, .playbook-rf-defense {
        height: 700px;
        width: 100%;
        margin-bottom: 50px;
    }

    .playbook-rf-offence > div, .playbook-rf-defense > div {
        height: 50%;
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
    }

    .playbook-betting-trends {
        padding: 0px;
    }
    .playbook-betting-trends > p:nth-of-type(2) {
        margin-top: 8px;
        margin-bottom: 0px;
    }

    .playbook-betting-trends > .filter-button {
        top: 84px;
    }

    .playbook-betting-trends > .comparative-table-body {
        /* margin-top: 84px; */
    }

    /* For the trend name and percentage bar might want to look into another threshold within < 768 to avoid a big gap between the two at first */
    .playbook-betting-trend-trend-name {
        width: 22.5%;
    }

    .playbook-betting-trend-percentage-bar-wrapper {
        width: 65%;
    }

    .playbook-betting-trend-trend-percentage{
        width: 12.5%;
    }

    .playbook-betting-trend-show-button-gradient {
        margin-left: -6px;
        bottom: 60px;
        width: 102.5%;
    }
    
    .playbook-betting-trend-show-button, .playbook-betting-trend-show-button-expanded {
        margin-left: -6px;
        bottom: 24px;
        width: 102.5%;
    }
    
    .playbook-betting-trend-show-button-expanded {
        bottom: 0px;
    }

    
    .playbook-scouting-report-stats-button-gradient {
        height: 28px;
        bottom: calc(78px + 36px);
    }

    .playbook-scouting-report-stats-button {
        bottom: 78px;
    }

    .playbook-picker {
        width: 100%;
        position: relative;
        /* margin-top: 18px; */
    }

    .playbook-stackable-dropdown, .playbook-stackable-dropdown-swapped {
        position: absolute;
        width: 100%;
        right: 0;
        top: 108px;
        margin-right: 0;
    }

    .playbook-stackable-dropdown-swapped {
        top: 84px;
    }

    .playbook-single-dropdown {
        position: absolute;
        width: 100%;
        right: 0;
        top: 48px;
        margin-right: 0;
    }

    .playbook-scouting-report .picker {
        top: 0px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .playbook-scouting-report .filter-button {
        top: 150px;
        left: 0;
        right: 0;
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
    }

    .playbook-scouting-report .comparative-table-body .desktop {
        display: none;
    }

    .playbook-scouting-report .comparative-table-body .mobile {
        display: block;
    }

    .playbook-scouting-report .comparative-table-body {
        /* margin-top: 82px; */
    }

    /* Combined selector */
    .playbook-nfl-scouting-type-filter.filter-button {
        top: 206px;
    }

    /* Siblings selector, table after the nfl filter presence */
    .playbook-nfl-scouting-type-filter ~ .comparative-table-body {
        margin-top: 132px;
    }

    .scouting-report-mobile-table-row {
        display: block;
        position: relative;
        width: 100%;
    }

    .scouting-report-mobile-table-row-header {
        display: block;
        position: relative;
        height: 24px;
        width: 100%;
        background: var(--color-surface-subtle);
        border-bottom: 1px solid var(--color-border-default);
        border-top: 1px solid var(--color-border-default);
        border-radius: 2px;
    }

    .scouting-report-mobile-table-row-header > p {
        display: block;
        margin-top: 3px;
    }

    .scouting-report-mobile-table-row-column {
        display: inline-block;
        position: relative;
        height: 96px;
        width: 50%;
    }

    .scouting-report-mobile-table-row-column > p {
        display: block;
        position: absolute;
    }

    .scouting-report-mobile-table-row-column > p:first-of-type {
        left: 50%;
        top: 20%;
        transform: translate(-50%, -20%);
    }

    .scouting-report-mobile-table-row-column > p:nth-of-type(2) {
        left: 50%;
        top: 80%;
        transform: translate(-50%, -80%);
    }

    #game-by-game {
        height: 480px;
    }

    #rf-offence, #rf-defense {
        height: 725px;
    }

    #season-offence, #season-defense {
        display: block;
        position: relative;
        width: 100%;
        height: 632px;
    }

    .sportsbook-shadow {
        display: none;
    }

    .sportsbook-scrool-buttons{
        display: none;
    }

    .sportsbook-all-link {
        display: none;
    }

    .sportsbook-bottom-link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 26px;
    }
}