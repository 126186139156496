.profile-modal-overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7); /* Black background with opacity */
    z-index: 11; /* Specify a stack order in case you're using a different order for other elements */
     /* Add a pointer on hover */
    /* cursor: pointer; */
}

/* TODO: should separate styling for modal for all the pages into a common stylesheet */
@media only screen and (min-width:769px) {
    .profile-modal-modal-container {
        width: 760px;
        height: 500px;
        position: fixed;
        top: 108px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        z-index: 12;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    .profile-modal-modal-header {
        height: 64px;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .profile-modal-modal-content {
        height: 368px;
        border-top: 1px solid #D1D5DB;
        padding: 24px;
        /* display: flex; */
        flex-direction: row;
        justify-content: space-between;
    }
    .profile-modal-team-logo {
        margin-right: 24px;
        position: relative;
    }
    
    .profile-modal-team-profile-card, .profile-modal-player-profile-card {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .profile-modal-spacer {
        color: #D1D5DB;
    }
    .profile-modal-team-info, .profile-modal-player-info {
        display: flex;
        flex-direction: column;
        margin-top: 28px;
    }
    
    .profile-modal-team-info div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /* width: 50%; */
        min-width: 200px;
    }

    .profile-modal-team-info, .profile-modal-player-info-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .profile-modal-player-info-row div:first-child {
        width: 30%;
        min-width: 115px;
    }

    .profile-modal-player-info-row div:last-child {
        width: 70%;
        min-width: 175px;
    }
    
    
    .profile-modal-stats {
        margin-top: 24px;
    }
    
    .profile-modal-stats-header {
        padding-bottom: 8px;
        border-bottom: 1px solid #D1D5DB;
        box-sizing: border-box;
    }
    
    .profile-modal-stats-section {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
    }
    
    .profile-modal-category-spacing {
        margin-right: 40px;
    }
    
    .profile-modal-stats-section .profile-modal-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
    }
    
    .profile-modal-category {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 50%;
    }

    .profile-modal-graph, .profile-modal-info {
        width: 50%;
        height: 100%;

        display: inline-block;
        vertical-align: bottom;
    }

    .profile-modal-modal-footer {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background-color: #F9FAFB;
        padding: 12px;
        box-sizing: border-box;
    }
    
    .profile-modal-btn-action-secondary {
        height: 36px;
        margin-right: 24px;
    }
    
    .profile-modal-btn-action-primary {
        height: 36px;
        margin-right: 12px;
    }
}

@media only screen and (min-width:360px) and (max-width:768px) {
    .profile-modal-modal-container {
        width: 100%;
        height: 470px;
        /* position: relative; */
        position: fixed;
        top: 150px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        z-index: 12;
        box-sizing: border-box;
        overflow: hidden;
        /* Commenting this out */
        /* overflow-y: auto; */
    }

    .profile-modal-modal-header {
        height: 64px;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profile-modal-modal-content {
        border-top: 1px solid #D1D5DB;
        padding: 12px;
        /* display: flex; */
        flex-direction: column;
        justify-content: space-between;

        /* Adding this */
        overflow-y: auto;
    }

    .profile-modal-team-logo {
        margin-right: 24px;
        position: relative;
    }
    
    .profile-modal-team-profile-card, .profile-modal-player-profile-card {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .profile-modal-spacer {
        color: #D1D5DB;
    }

    .profile-modal-team-info, .profile-modal-player-info {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    
    .profile-modal-team-info div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /* width: 50%; */
        min-width: 200px;
    }

    .profile-modal-team-info, .profile-modal-player-info-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .profile-modal-player-info-row div:first-child {
        width: 30%;
        min-width: 115px;
    }

    .profile-modal-player-info-row div:last-child {
        width: 70%;
        min-width: 175px;
    }
    
    
    .profile-modal-stats {
        margin-top: 24px;
    }
    
    .profile-modal-stats-header {
        padding-bottom: 8px;
        border-bottom: 1px solid #D1D5DB;
        box-sizing: border-box;
    }
    
    .profile-modal-stats-section {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
    }
    
    .profile-modal-category-spacing {
        margin-right: 40px;
    }
    
    .profile-modal-stats-section .profile-modal-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
    }
    
    .profile-modal-category {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 50%;
    }

    .profile-modal-graph {
        height: 320px;
    }

    .profile-modal-modal-footer {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        background-color: #F9FAFB;
        padding: 12px;
        box-sizing: border-box;
    }
    
    .profile-modal-btn-action-secondary {
        height: 36px;
        margin-right: 24px;
    }
    
    .profile-modal-btn-action-primary {
        height: 36px;
        margin-right: 12px;
    }
}
