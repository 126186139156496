.table-component {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  border: 1px solid var(--color-border-default);
  width: 100%;
  table-layout: fixed;
}

.table-component tr th:first-child {
    border-top-left-radius: 8px;
}

.table-component tr th:last-child {
    border-top-right-radius: 8px;
}

.table-component tr th {
    height: 40px;
    background-color: var(--color-surface-subtle);
    border-bottom: 1px solid var(--color-border-default);
    color: var(--color-fig-subtle);
    text-align: center;
    vertical-align: middle;
}

.table-component tr td {
    height: 56px;
    background-color: var(--color-surface-subtle);
    color: var(--color-fig-default);
    text-align: center;
    vertical-align: middle;
}

.table-component tr:nth-child(odd) td {
    background-color: var(--color-surface-default);
}

.table-component tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.table-component tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

@media only screen and (max-width: 1439px) {

    .table-component-wrapper {
        overflow: scroll;
    }

    .table-component tr th {
        width: 90px;
    }

}