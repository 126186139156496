.home-page-container {
    flex-grow: 1;
    flex-direction: row;
    background-color: var(--color-surface-default);

    display: grid;
    grid-template-columns:  minmax(0, 1fr) minmax(1080px, 1200px) 1fr;
    grid-template-areas: "placeholder content sidebar";
    justify-content: space-between;
}

.home-page-content {
    // max-width: 1200px;
    width: 1200px;
    padding: 0 0 32px 0;
    border-left: solid 1px var(--color-border-default);
    border-right: solid 1px var(--color-border-default);
}

.home-page-padding {
    padding: 32px;
}

.home-page-find-your-bet > p, 
.home-page-hottest-trends > p ,
.home-page-parlay-trends > p,
.home-page-odds-trends > p {
    margin-bottom: 24px;
}

.home-page-find-your-bet-shortcuts,
.home-page-hottest-trends-shortcuts,
.home-page-parlay-trends-shortcuts,
.home-page-odds-trends-shortcuts {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.home-page-hottest-trends-shortcuts {
    flex-wrap: wrap;
}

.home-page-find-your-bet-shortcut {
    width: calc(100%/var(--shortcuts));
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
}

.home-page-hottest-trends-shortcut,
.home-page-parlay-trends-shortcut,
.home-page-odds-trends-shortcut  {
    width: calc(100%/var(--shortcuts));
    height: 88px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    padding: 0px 16px;
    overflow: hidden;
}

.home-page-hottest-trends-shortcut {
    // Divided by 3 for each element in the row
    // The removed value is the 16px gap that you have for the number of elements - 1, times the number of elements
    width: calc(calc(100% / 3) - calc(16px / 3 * 2));
}

.home-page-odds-trends-shortcut-dummy {
    width: calc(100%/var(--shortcuts));
}

.home-page-hottest-trends-shortcut-illustration,
.home-page-parlay-trends-shortcut-illustration,
.home-page-odds-trends-shortcut-illustration,
.home-page-leaders-illustration {
    position: absolute;
    top: -8px;
    right: -8px;
    height: 56px;
    width: 56px;
    border-radius: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-page-leaders-illustration {
    background-color: var(--color-surface-subtle);
    top: -4px;
    right: -4px;
    height: 40px;
    width: 40px;
}

.home-page-leaders-illustration > img {
    filter: var(--color-fig-subtle-filter);
}

.home-page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.home-page-title-text-desktop {
    display: inherit;
    color: var(--color-fig-default);
    margin-right: 12px;
}

.home-page-title-date-desktop {
    display: inherit;
    color: var(--color-fig-accent);
}

.home-page-title-text-mobile {
    display: none;
}

.home-page-title-date-mobile {
    display: none;
}

.home-page-matchup-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.home-page-discover-title {
    margin-top: 32px;
    margin-bottom: 24px;
    padding: 0px 32px;
}

.home-page-discover-title-desktop {
    display: inherit;
    color: var(--color-fig-default);
}

.home-page-discover-title-mobile {
    display: none;
}

.home-page-discover-trends-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    padding: 0px 32px;
    margin-bottom: 32px;
}

.home-page-analyze-props {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 168px;
    margin-top: 32px;
    padding: 32px 0px;
    border-top: solid 1px var(--color-border-default);
    border-bottom: solid 1px var(--color-border-default);
    background-color: var(--color-surface-subtle);
}

.home-page-analyze-props > p {
    color: var(--color-fig-default);
}

.home-page-analyze-props > p.text-style-h-2-semibold {
    display: inherit;
}

.home-page-analyze-props > p.text-style-h-4-semibold {
    display: none;
}

.home-page-analyze-props-button {
    height: 40px;
    width: 216px;
}

.home-page-analyze-props > img {
    position: absolute;
    width: 328px;
    height: 328px;
    right: -64px;
    top: -84px;
    filter: var(--color-fig-disabled-filter);
}

.home-page-linemate-plus-ad-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 208px;
    margin-top: 32px;
    padding: 32px 40px;
    background-image: url(../internal-assets/ad-background.png);
    background-size: cover;
    background-position: center;
}

.home-page-linemate-plus-ad-desktop-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.home-page-linemate-plus-ad-desktop-left > p {
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.8899999856948853px;
    text-align: left;

    background: linear-gradient(153.91deg, #FFFFFF 10.93%, rgba(255, 255, 255, 0) 146.81%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

    width: 486px;
}

.home-page-linemate-plus-ad-desktop-left > div {
    height: 40px;
    width: 171px;
}

.home-page-linemate-plus-ad-desktop-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.home-page-linemate-plus-ad-desktop-right > img {
    height: 92px;
    width: auto;
}

.home-page-linemate-plus-ad-mobile {
    display: none;
}

.home-page-leaders {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 16px;
    margin-bottom: 120px;
}

.home-page-leaders-header, 
.home-page-leaders-groups {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-page-leaders-header {
    justify-content: space-between;
}

.home-page-leaders-groups {
    gap: 16px;
}

.home-page-leaders-group {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 84px;
    width: calc(100% / var(--groups-count));
    border-radius: 8px;
    border: 1px solid var(--color-border-default);
    cursor: pointer;
    overflow: hidden;
    padding: 20px 16px;
    gap: 8px;
}

.home-page-affiliates-sidebar-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.home-page-affiliates-sidebar-column > div {
    width: 360px;
}

@media only screen and (max-width: 1579px) {
    .home-page-content {
        width: auto;
    }
}


// Does it make sense to put affiliates down already at this size?
// We could bring it down to like 1024 if we can shift the matchup cards to 50% earlier
//  but that doesn't solve the problem of the trend titles and subtitles getting too close
// Realistically we could bring it down to like ~1400 without having to change anything but at that point maybe not even worth it
@media only screen and (max-width: 1439px) {

    .home-page-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .home-page-content {
        width: 100%;
        border: none;
    }

    .home-page-affiliates-sidebar-column {
        border-top: 1px solid var(--color-border-default);
    }

    .home-page-affiliates-sidebar-column > div {
        width: 100%;
    }

}

@media only screen and (max-width: 1024px) {
    .home-page-content {
        border: none;
    }

    .home-page-analyze-props > img {
        display: none;
    }

    .home-page-linemate-plus-ad-desktop {
        display: none;
    }

    .home-page-linemate-plus-ad-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 284px;
        margin-top: 32px;
        padding: 32px 0px;
        background-image: url(../internal-assets/ad-background.png);
        background-size: cover;
        background-position: center;
    }

    .home-page-linemate-plus-ad-mobile > img {
        height: 64px;
        width: auto;
    }

    .home-page-linemate-plus-ad-mobile > p {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 33.89px;
        letter-spacing: -0.8899999856948853px;
        text-align: center;
    
        background: linear-gradient(153.91deg, #FFFFFF 10.93%, rgba(255, 255, 255, 0) 146.81%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;

        max-width: 343px;
    }

    .home-page-linemate-plus-ad-mobile > div {
        height: 40px;
        width: 171px;
    }

    .home-page-find-your-bet-shortcuts {
        flex-wrap: wrap;
    }

    .home-page-find-your-bet-shortcut {
        width: calc(50% - 8px)
    }

    .home-page-find-your-bet-shortcuts,
    .home-page-hottest-trends-shortcuts,
    .home-page-parlay-trends-shortcuts,
    .home-page-odds-trends-shortcuts {
        flex-wrap: wrap;
    }

    .home-page-hottest-trends-shortcut,
    .home-page-parlay-trends-shortcut,
    .home-page-odds-trends-shortcut,
    .home-page-odds-trends-shortcut-dummy  {
        width: calc(50% - 8px);
    }
}

@media only screen and (max-width: 768px) {
    .home-page-padding {
        padding-left: 16px;
        padding-right: 16px;
    }

    .home-page-title-text-desktop {
        display: none;
    }
    
    .home-page-title-date-desktop {
        display: none;
    }

    .home-page-title-text-mobile {
        display: inherit;
        color: var(--color-fig-default);
        margin-right: 8px;
    }
    
    .home-page-title-date-mobile {
        display: inherit;
        color: var(--color-fig-accent);
    }

    .home-page-discover-title-desktop {
        display: none;
    }
    
    .home-page-discover-title-mobile {
        display: inherit;
        color: var(--color-fig-default);
    }

    .home-page-analyze-props {
        height: 154px;
    }

    .home-page-analyze-props > p.text-style-h-2-semibold {
        display: none;
    }
    
    .home-page-analyze-props > p.text-style-h-4-semibold {
        display: inherit;
    }

    .home-page-leaders {
        padding: 32px 0px;
    }

    .home-page-leaders-header {
        padding: 0px 16px;
    }

    .home-page-leaders-groups {
        padding: 0px 16px;
        flex-wrap: wrap;
    }

    .home-page-leaders-group {
        width: calc(50% - 8px);
    }

    .home-page-discover-trends-wrapper {
        gap: 32px;
        padding: 0px 16px;
    }
}

@media only screen and (max-width: 400px) {
    .home-page-find-your-bet-shortcut {
        width: 100%;
    }

    .home-page-hottest-trends-shortcut,
    .home-page-parlay-trends-shortcut,
    .home-page-odds-trends-shortcut,
    .home-page-odds-trends-shortcut-dummy  {
        width: 100%;
    }

    .home-page-leaders-group {
        width: 100%;
    }
}