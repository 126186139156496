.footer-container {
    padding: 64px 32px;
    background-color: var(--color-surface-default);
    border-top: 1px solid var(--color-border-default);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer-wordmark > img {
    filter: var(--color-fig-default-filter);
    height: 20px;
    width: 104px;
}

.footer-copyright {
    margin-top: 16px;
    color: var(--color-fig-subtle);
}

.footer-socials {
    display: flex;
    flex-direction: row;
    margin-top: 44px;
    gap: 12px;
}

.footer-socials > a {
    display: flex;
    width: 16px;
    height: 16px;
}

.footer-socials img {
    width: 16px;
    height: 16px;
    filter: var(--color-fig-subtle-filter);
}

.footer-information,
.footer-product, 
.footer-company, 
.footer-support {
    width: 25%;
    display: flex;
    flex-direction: column;
}

.footer-product > div, 
.footer-company > div, 
.footer-support > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 12px;
}

.footer-product > p, 
.footer-company > p, 
.footer-support > p {
    color: var(--color-fig-default);
}

.footer-product > div > a, 
.footer-company > div > a, 
.footer-support > div > a {
    color: var(--color-fig-subtle);
}

.footer-support img {
    filter: var(--color-fig-subtle-filter);
}

// Mobile threshold for the footer
@media only screen and (max-width: 768px) {

    .footer-container {
        padding: 40px 16px;
        flex-wrap: wrap;
    }

    .footer-information {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .footer-socials {
        margin-top: 0;
    }

    .footer-product, 
    .footer-company, 
    .footer-support {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
    }

}